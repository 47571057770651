import { useContext, useEffect, useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import { Panel, PanelBody, PanelFooter } from "../../components/panel/panel";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import RoomDetailInfo from "./fragment/room-detail-info";
import RoomDetailChat from "./fragment/room-detail-chat";
import RoomDetailMember from "./fragment/room-detail-member";
import RoomDetailTime from "./fragment/room-detail-time";

const RoomDetail = ({ room_id, history }) => {

  const context = useContext(PageSettings);

  const [activeTab, setActiveTab] = useState('1');

  const [tagList, setTagList] = useState([]);

  useEffect(() => {
    if (room_id > 0) {
      getTagList()
    }
  }, [room_id]);

  const getTagList = () => {
    context.get(
      '/planner/tag/list/' + room_id,
      {},
      response => {
        setTagList(response.list);
      }
    );
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const onSave = () => {
    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'planner/tag/save',
        {
          tag: tagList[Number(activeTab) - 1]
        },
        () => {
          getTagList()
          context.addNotification(strings.modal.alert, strings.msg.saved);
        }
      );
    });
  }

  return (
    <div className="row">
      <div className="col-xl-6">
        <RoomDetailInfo room_id={room_id} onSave={() => {
          if (room_id < 1) {
            history.goBack();
          }
        }} />
      </div>
      {
        room_id > 0 &&
        <div className="col-xl-6">
          <Panel>
            <Nav tabs className="nav-tabs-inverse">
              <NavItem>
                <NavLink className={classnames({ active: activeTab == 1 })} onClick={() => {
                  toggle('1');
                }}>
                  <span className="d-none d-xs-inline">{strings.room.chat_log}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={classnames({ active: activeTab == 2 })} onClick={() => {
                  toggle('2');
                }}>
                  <span className="d-none d-xs-inline">{strings.room.room_member}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={classnames({ active: activeTab == 3 })} onClick={() => {
                  toggle('3');
                }}>
                  <span className="d-none d-xs-inline">{strings.room.room_time}</span>
                </NavLink>
              </NavItem>
            </Nav>
            <PanelBody>
              {activeTab === "1" && (<RoomDetailChat room_id={room_id} />)}
              {activeTab === "2" && (<RoomDetailMember room_id={room_id} />)}
              {activeTab === "3" && (<RoomDetailTime room_id={room_id} />)}
            </PanelBody>
            <PanelFooter className={"text-right"}>
              <div className="d-flex w-100">
                <div className="ml-auto">
                  <button type='button' className='btn btn-success btn-sm m-2' onClick={() => {
                    onSave()
                  }}>{strings.common.save}</button>
                </div>
              </div>
            </PanelFooter>
          </Panel>
        </div>
      }
    </div>
  );
};

export default withRouter(inject('rootStore')(RoomDetail));
