import React, { useContext, useEffect, useRef, useState } from "react";
import $ from "jquery";

import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import DataTable from "../../components/control/data-table";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import Container from "../../components/control/container";

const ClinicChatList = ({ history }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.member;

  const [type, setType] = useState(1)

  useEffect(() => {
    refreshTable(true)
  }, [type])

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }
  const onCell = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  };
  const onChatDelete = chat_id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'clinic/chat/' + chat_id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  return (
    <Container title={strings.menu.clinic_chat_all}>
      <div className='row'>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [
                {
                  targets: [columnNo++], data: 'id', orderable: false,
                },
                {
                  targets: [columnNo++], data: 'user_name', orderable: false, width: 150,
                  createdCell: (td, value, item) => {
                    if (item?.user_name) {
                      $(td).nickname_label(item?.user_name, item?.user_gender, () => onCell(item.user));
                    } else {

                    }

                  }
                },
                {
                  targets: [columnNo++], data: 'expert_name', orderable: false, width: 150,
                  createdCell: (td, value, item) => {
                    if (item?.expert_name) {
                      $(td).nickname_label(item.expert_name, item.expert_gender, () => onCell(item.expert));
                    } else {

                    }

                  }
                },
                {
                  targets: [columnNo++], data: 'type', orderable: false,
                  render: value => value == 1 ? strings.room.chat_text : strings.room.chat_image
                },
                {
                  targets: [columnNo++], data: 'content', orderable: false, width: "40%",
                },
                {
                  targets: [columnNo++], data: 'create_date', orderable: false,
                },
                {
                  targets: [columnNo++], data: 'read', orderable: false,
                  render: value => value == 1 ? strings.room.chat_confirm : strings.room.chat_not_confirm
                },
                {
                  targets: [columnNo++], data: 'id', orderable: false,
                  createdCell: function (td, value, item) {
                    $(td).html('');
                    $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.common.delete}</button`)
                      .find('button:last')
                      .on('click', function () {
                        onChatDelete(item.id)
                      });
                  }
                }
              ],
              order: [[1, 'DESC']],
              ajax: {
                url: 'clinic/chatList',
                data: function (req) {
                  req.columns = null;
                }
              },
              drawCallback: function () {
              },
              fnCreatedRow: function (row, data, dataInd) {
              }
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>&nbsp;&nbsp;{strings.room.sender}&nbsp;&nbsp;</th>
              <th>{strings.room.receiver}</th>
              <th>{strings.room.chat_type}</th>
              <th>{strings.room.content}</th>
              <th>{strings.common.create_date}</th>
              <th>{strings.room.read}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
    </Container>
  );

}

export default withRouter(inject('rootStore')(ClinicChatList));
