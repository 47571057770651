import { useContext, useRef } from "react";
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import DataTable from "../../../components/control/data-table";
import $ from "jquery"

const RoomDetailChat = ({ room_id }) => {

  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const onCell = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  };

  const onChatDelete = chat_id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'room/chat/' + chat_id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  return (
    <div>
      <div className='row'>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [
                {
                  targets: [columnNo++], data: 'id', orderable: false,
                  render: function (value) {
                    return value;
                  }
                },
                {
                  targets: [columnNo++], data: 'sender_name', orderable: false,
                  createdCell: (td, value, item) => {
                    $(td).nickname_label(item.sender_name, item.sender_gender, () => onCell(item.sender));
                  }
                },
                {
                  targets: [columnNo++], data: 'receiver_name', orderable: false,
                  createdCell: (td, value, item) => {
                    $(td).nickname_label(item.receiver_name, item.receiver_gender, () => onCell(item.receiver));
                  }
                },
                {
                  targets: [columnNo++], data: 'type', orderable: false,
                  render: value => value == 1 ? strings.room.chat_text : strings.room.chat_image
                },
                {
                  targets: [columnNo++], data: 'content', orderable: false,
                },
                {
                  targets: [columnNo++], data: 'create_date', orderable: false,
                },
                {
                  targets: [columnNo++], data: 'read', orderable: false,
                  render: value => value == 1 ? strings.room.chat_confirm : strings.room.chat_not_confirm
                },
                {
                  targets: [columnNo++], data: 'id', orderable: false,
                  createdCell: function (td, value, item) {
                    $(td).html('');
                    $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.common.delete}</button`)
                      .find('button:last')
                      .on('click', function () {
                        onChatDelete(item.id)
                      });
                  }
                }
              ],
              order: [[1, 'DESC']],
              ajax: {
                url: 'room/chatList',
                data: function (req) {
                  req.columns = null;
                  req.room_id = room_id;
                }
              },
              drawCallback: function () {
              },
              fnCreatedRow: function (row, data, dataInd) {
              }
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.room.sender}</th>
              <th>{strings.room.receiver}</th>
              <th>{strings.room.chat_type}</th>
              <th>{strings.room.content}</th>
              <th>{strings.common.create_date}</th>
              <th>{strings.room.read}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default RoomDetailChat;
