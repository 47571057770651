import { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import strings from "../../../lang/strings";
import Container from "../../../components/control/container";

const MenuStaFragment = () => {

  const context = useContext(PageSettings);

  const [data, setData] = useState(null);
  useEffect(() => {
    context.get(
      'dashboard/menu_sta',
      {},
      response => setData(response.menu_sta)
    );
  }, []);

  const sortObject = (object) => {
    let sortable = [];
    for (const entry in object) {
      sortable.push([entry, object[entry]]);
    }

    sortable.sort(function (a, b) {
      return a[1] - b[1];
    });

    return sortable
  }

  return (
    <Container title={strings.dashboard.most_used_menu}>
      <div>
        <div className="d-flex w-100  align-items-center">
          <div>{strings.dashboard.today} {strings.dashboard.most_use_function}</div>
          <div className="ml-auto d-flex">
            {
              data ? sortObject(data.today).slice(0, 3).map((it, idx) =>
                <div
                  key={idx}
                  style={{ color: "black", width: 100, fontSize: 12 }}
                >{strings.dashboard.menu_list.find(item => item.menu == it[0])?.name || ""}</div>
              ) : ""
            }
          </div>
        </div>
        <div className="d-flex w-100  align-items-center">
          <div>{strings.dashboard.this_week} {strings.dashboard.most_use_function}</div>
          <div className="ml-auto d-flex">
            {
              data ? sortObject(data.thisWeek).slice(0, 3).map((it, idx) =>
                <div
                  key={idx}
                  style={{ color: "black", width: 100, fontSize: 12 }}
                >{strings.dashboard.menu_list.find(item => item.menu == it[0])?.name || ""}</div>
              ) : ""
            }
          </div>
        </div>
        <div className="d-flex w-100  align-items-center">
          <div>{strings.dashboard.this_month} {strings.dashboard.most_use_function}</div>
          <div className="ml-auto d-flex">
            {
              data ? sortObject(data.thisMonth).slice(0, 3).map((it, idx) =>
                <div
                  key={idx}
                  style={{ color: "black", width: 100, fontSize: 12 }}
                >{strings.dashboard.menu_list.find(item => item.menu == it[0])?.name || ""}</div>
              ) : ""
            }
          </div>
        </div>
        <div className="d-flex w-100  align-items-center">
          <div>{strings.dashboard.this_year} {strings.dashboard.most_use_function}</div>
          <div className="ml-auto d-flex">
            {
              data ? sortObject(data.thisYear).slice(0, 3).map((it, idx) =>
                <div
                  key={idx}
                  style={{ color: "black", width: 100, fontSize: 12 }}
                >{strings.dashboard.menu_list.find(item => item.menu == it[0])?.name || ""}</div>
              ) : ""
            }
          </div>
        </div>
      </div>
    </Container>
  );
}

export default MenuStaFragment;
