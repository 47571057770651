import { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import strings from '../../../lang/strings';

const SignupStaFragment = () => {

  const context = useContext(PageSettings);

  const [data, setData] = useState(null);
  useEffect(() => {
    context.get(
      'dashboard/signup_sta',
      {},
      response => setData(response.signup_sta)
    );
  }, []);

  return (
    <div>
      <div className="widget widget-stats bg-blue height-150">
        <div className="d-flex align-items-center text-white m-t-5">
          <div className="d-flex w-100">
            <div>{strings.dashboard.today} {strings.dashboard.signup_count}</div>
            <div className="ml-auto">
              {data ? `${strings.dashboard.total + ' : ' + data.today_approved?.currencyFormat() + '명 (' + strings.dashboard.expert + ' : ' + data.expert_today_approved?.currencyFormat()}명)` : `-`}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white m-t-5">
          <div className="d-flex w-100">
            <div>{strings.dashboard.this_week} {strings.dashboard.signup_count}</div>
            <div className="ml-auto">
              {data ? `${strings.dashboard.total + ' : ' + data.this_week_approved?.currencyFormat() + '명 (' + strings.dashboard.expert + ' : ' + data.expert_this_week_approved?.currencyFormat()}명)` : `-`}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white m-t-5">
          <div className="d-flex w-100">
            <div>{strings.dashboard.this_month} {strings.dashboard.signup_count}</div>
            <div className="ml-auto">
              {data ? `${strings.dashboard.total + ' : ' + data.this_month_approved?.currencyFormat() + '명 (' + strings.dashboard.expert + ' : ' + data.expert_this_month_approved?.currencyFormat()}명)` : `-`}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white m-t-5">
          <div className="d-flex w-100">
            <div>{strings.dashboard.this_year} {strings.dashboard.signup_count}</div>
            <div className="ml-auto">
              {data ? `${strings.dashboard.total + ' : ' + data.this_year_approved?.currencyFormat() + '명 (' + strings.dashboard.expert + ' : ' + data.expert_this_year_approved?.currencyFormat()}명)` : `-`}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white m-t-5">
          <div className="d-flex w-100">
            <div>{strings.common.total} {strings.dashboard.signup_count}</div>
            <div className="ml-auto">
              {data ? `${strings.dashboard.total + ' : ' + data.total_approved?.currencyFormat() + '명 (' + strings.dashboard.expert + ' : ' + data.expert_total_approved?.currencyFormat()}명)` : `-`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupStaFragment;
