import React, { useContext, useRef, useState } from "react";
import $ from "jquery";

import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import C from "../../config/const";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import Container from "../../components/control/container";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import MaterialCreatePopup from "./popup/material-create-popup";
import MaterialDetailPopup from "./popup/material-detail-popup";

const MaterialList = ({ history }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.study_material;

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const [popup, setPopup] = useState({
    isOpen: false,
    material_id: 0,
  });

  const showPopup = (material_id) => setPopup(prev => {
    return { ...prev, isOpen: true, material_id };
  });

  const hidePopup = () => setPopup(prev => {
    return { ...prev, isOpen: false };
  });

  const [detailPopup, setDetailPopup] = useState({
    isOpen: false,
    material: {},
  });

  const showDetailPopup = (material) => setDetailPopup(prev => {
    return { ...prev, isOpen: true, material };
  });

  const hideDetailPopup = () => setDetailPopup(prev => {
    return { ...prev, isOpen: false };
  });

  const onDelete = material_id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'support/material/' + material_id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  return (
    <Container title={strings.menu.mission}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={() => refreshTable(true)} />
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2'
                    onClick={() => refreshTable(true)}>{strings.common.search}</button>

            <button type='button' className='btn btn-yellow btn-sm ml-md-2'
                    onClick={() => showPopup(null)}>{strings.support.add_material}</button>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'name', orderable: false,
                createdCell: (td, value, item) => {
                  $(td)
                    .html(`<a href="javascript:;">${value}</a>`)
                    .find('a')
                    .on('click', () => showDetailPopup(item));
                }
              }, {
                targets: [columnNo++], data: 'type', orderable: false,
                render: value => C.MATERIAL_TYPE.find(item => item.code == value).name
              }, {
                targets: [columnNo++], data: 'create_date', orderable: false,
              }, {
                targets: [columnNo++], data: 'name', orderable: false,
                createdCell: (td, value, item) => {
                  $(td)
                    .html(`<a href="${String(item.url).getFullUrl()}">
                        <button type='button' class='btn btn-green btn-sm m-2'>
                        ${strings.common.view}</button></a>`)
                }
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html('');
                  $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.common.db_delete}</button`)
                    .find('button:last')
                    .on('click', function () {
                      onDelete(item.id);
                    });
                }
              }],
              order: [[1, 'DESC']],
              ajax: {
                url: 'support/materialList',
                data: (req) => {
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                }
              },
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.support.file_name}</th>
              <th>{strings.support.material_type}</th>
              <th>{strings.common.create_date}</th>
              <th>{strings.common.view}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
        <MaterialCreatePopup {...popup} onClose={() => {
          hidePopup();
        }} callback={() => {
          refreshTable(true)
          hidePopup();
        }} />
        <MaterialDetailPopup {...detailPopup} onClose={() => {
          hideDetailPopup();
        }} callback={() => {
          refreshTable(false)
          hideDetailPopup();
        }} />
      </div>
    </Container>
  );

}

export default withRouter(inject('rootStore')(MaterialList));
