import React, { useContext, useRef } from "react";
import $ from "jquery";

import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import Container from "../../components/control/container";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";

const PlannerList = ({ history }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.planner;

  const showPlannerDetail = (id) => {
    history.push(`/planner/detail/${id}`);
  }

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const onDelete = planner_id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'planner/' + planner_id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  return (
    <Container title={strings.menu.planner_all}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={() => refreshTable(true)} />
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2'
                    onClick={() => refreshTable(true)}>{strings.common.search}</button>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'planner_date', orderable: false,
                createdCell: (td, value, item) => {
                  $(td)
                    .html(`<a href="javascript:;">${value}</a>`)
                    .find('a')
                    .on('click', () => showPlannerDetail(item.id));
                }
              }, {
                targets: [columnNo++], data: 'name', orderable: false,
              }, {
                targets: [columnNo++], data: 'tag_count', orderable: false,
                render: (value) => value + " " + strings.planner.tags
              }, {
                targets: [columnNo++], data: 'comment', orderable: false,
              }, {
                targets: [columnNo++], data: 'memo', orderable: false,
              }, {
                targets: [columnNo++], data: 'feedback', orderable: false,
              }, {
                targets: [columnNo++], data: 'create_date', orderable: false,
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`<button type='button' class='btn btn-outline-danger btn-sm m-2 text-nowrap'>${strings.common.db_delete}</button>`)
                    .find('button:eq(0)')
                    .on('click', () => onDelete(item.id));
                }
              }],
              order: [[1, 'DESC']],
              ajax: {
                url: 'planner/list',
                data: (req) => {
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                }
              },
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.planner.planner_date}</th>
              <th>{strings.planner.user_name}</th>
              <th>{strings.planner.tag}</th>
              <th>{strings.planner.comment}</th>
              <th>{strings.planner.memo}</th>
              <th>{strings.planner.feedback}</th>
              <th>{strings.common.create_date}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
    </Container>
  );

}

export default withRouter(inject('rootStore')(PlannerList));
