import React, { useContext, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";

const NoticePopup = ({ isOpen, id, onClose }) => {

  const context = useContext(PageSettings);

  const [info, setInfo] = useState(null);
  const getInfo = () => {
    if (id < 1) {
      setInfo(null);
      return;
    }

    context.get(
      `setting/notice/${id}`,
      {},
      res => {
        setInfo(res);
      }
    );
  }

  const onSave = e => {
    e.preventDefault();

    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'setting/notice',
        {
          id: info?.id || 0,
          title: info?.title,
          content: info?.content,
          category: info?.category ?? 1,
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          onClose(true);
        }
      );
    });
  }

  return (
    <Modal size={'lg'} isOpen={isOpen} toggle={onClose} onOpened={getInfo}>
      <form onSubmit={onSave}>
        <ModalHeader className="popup-nickname" toggle={onClose}>
          {strings.notice.detail}
        </ModalHeader>
        <ModalBody>
          <div className='row'>
            <label className='col-xs-2 col-form-label'>{strings.common.title} *</label>
            <div className="col-xs-9">
              <input type='text' autoComplete='off' className='form-control'
                     value={info?.title || ''} required
                     onChange={e => setInfo({ ...info, title: e.target.value })} />
            </div>
          </div>
          <div className='row mt-2'>
            <label className='col-xs-2 col-form-label'>{strings.common.type} *</label>
            <div className="col-xs-9">
              <select id='category' className='col-xl-4 form-control m-r-10' value={info?.category || ''} required
                      onChange={e => setInfo({ ...info, category: e.target.value })}>
                <option value="" />
                <option value={1}>{strings.notice.category_notice}</option>
                <option value={2}>{strings.notice.category_event}</option>
              </select>
            </div>
          </div>
          <div className='row mt-2'>
            <label className='col-xs-2 col-form-label'>{strings.common.content} *</label>
            <div className='col-xs-9'>
              <textarea
                className='form-control'
                rows={20} required
                value={info?.content || ''}
                onChange={e => setInfo({ ...info, content: e.target.value })} />
            </div>
          </div>
          {
            info?.create_date ?
              <div className='row mt-2'>
                <label className='col-xs-2 col-form-label'>{strings.common.create_date}</label>
                <label className='col-xs-9 col-form-label'>{info?.create_date || '-'}</label>
              </div>
              :
              <div />
          }

        </ModalBody>
        <ModalFooter>
          <button className="btn btn-default" type='button' onClick={onClose}>{strings.modal.close}</button>
          <button className="btn btn-success" type='submit'>{strings.common.save}</button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default NoticePopup;
