import { useContext, useEffect, useRef, useState } from "react";
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import { API_URL } from "../../../config/const";

const QuestionAnswerDetail = ({ answer_info, getAnswerList }) => {

  const context = useContext(PageSettings);
  const fileRef = useRef();

  const [files, setFiles] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [answer, setAnswer] = useState({});

  useEffect(() => {
    setAnswer(answer_info)
    setPhotos(answer_info.file.split('#').map(it => {
      return {
        url: it.getFullUrl().getThumbnailUrl(),
        origin: it.getFullUrl().getThumbnailUrl(),
        file: null
      };
    }));
  }, [answer_info]);

  const onFileChange = (e, idx) => {

    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      setPhotos(prev => {
        return prev.map((it, index) => {
          if (index == idx) {
            it.url = reader.result;
            it.file = file;
          }
          return it;
        })
      });
    };
    reader.readAsDataURL(file);
  };

  const onFileDelete = (index) => {
    setPhotos(prev => {
      return prev.map((it, idx) => {
        if (idx == index) {
          if (it.file != null) {
            it.url = it.origin;
            it.file = null;
          } else {
            it.url = '';
            it.origin = '';
          }
        }
        return it;
      });
    });
  };

  const uploadFile = () => {
    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      if (photos.filter(it => it.file != null).length > 0) {
        let formData = new FormData();
        photos.filter(it => it.file != null).map(it => it.file).forEach(file => {
          formData.append('file', file, file.name);
        });

        context.post(
          'upload/files/question',
          formData,
          response => {
            onSave(response.file);
          }
        );
        return;
      }

      onSave();
    });
  }

  const onSave = (upload_files = []) => {
    const photo = photos.map(it => {
      if (it.file != null && upload_files.length > 0) {
        return upload_files.shift();
      }
      return it.url.replace(API_URL + '/', '');
    }).filter(it => it != '').join('#');

    answer.file = photo

    context.post(
      'support/answer/save',
      {
        answer
      },
      () => {
        getAnswerList()
        context.addNotification(strings.modal.alert, strings.msg.saved);
      }
    );
  }

  const onDelete = () => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'support/answer/' + answer_info.id,
        {},
        () => {
          getAnswerList()
          context.addNotification(strings.modal.alert, strings.msg.deleted);
        }
      );
    });
  }

  const onAnswerChange = e => {
    setAnswer(prev => {
      return {
        ...prev, [e.target.id]: e.target.value
      }
    })
  }

  return (
    <div>
      <div className='row'>
        <label className='col-xl-2 col-form-label'>UID</label>
        <label className='col-xl-10 col-form-label'>{answer?.id}</label>
      </div>
      <div className='row m-t-5'>
        <label className='col-xl-2 col-form-label'>{strings.support.answer_content}</label>
        <div className="col-xl-10">
          <textarea autoComplete='off' className='form-control' id="content" rows={8}
                    value={answer?.content || ''} onChange={onAnswerChange} />
        </div>
      </div>
      <div className='row m-t-5'>
        <label className='col-xl-2 col-form-label'>{strings.support.file}</label>
        <div className="col-xl-10">
          <div className='row'>
            {photos?.filter(it => it.url).map((image, index) => (
              <div className='col-xs-2' key={index}>
                <div style={{ height: 100, width: 100, position: "relative" }}>
                  <a onClick={() => {
                    context.showGallery(photos?.map(it => it.url.getFullUrl()), index)
                  }}>
                    <img src={image?.url?.getThumbnailUrl()}
                         style={{ height: 100, width: 100 }} />
                  </a>
                  <button className="btn btn-danger btn-icon btn-sm"
                          type='button'
                          style={{ position: "absolute", top: "2px", right: "0px" }}
                          onClick={(e) => {
                            e.preventDefault();
                            onFileDelete(index)
                          }}>
                    <i className="fa fa-times" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='row m-t-5'>
        <label className='col-xl-2 col-form-label'>{strings.common.create_date}</label>
        <label className='col-xl-10 col-form-label'>{answer?.create_date || ''}</label>
      </div>
      <div className="row m-t-30 m-b-10">
        <div className='col-xl-2'>
          <button type='button' className='btn btn-dark btn-sm m-2' onClick={() => {
            onDelete()
          }}>{strings.support.delete_answer}</button>
        </div>
        <div className='col-xl-2 m-l-auto'>
          <button type='button' className='btn btn-success btn-sm m-r-20' onClick={() => {
            uploadFile()
          }}>{strings.support.save_answer}</button>
        </div>
      </div>
    </div>
  );
};

export default QuestionAnswerDetail;
