import React, { useContext, useEffect, useRef, useState } from "react";
import $ from "jquery";

import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import DataTable from "../../components/control/data-table";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import Container from "../../components/control/container";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";

const ChatList = ({ history }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.chat_room;

  const [type, setType] = useState(1)

  useEffect(() => {
    refreshTable(true)
  }, [type])

  const showRoomDetail = (id) => {
    history.push(`/room/detail/${id}`);
  }

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }
  const onCell = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  };
  const onChatDelete = chat_id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'room/chat/' + chat_id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  return (
    <Container title={strings.menu.chat_all}>
      <div className='row'>
        <div className='col-md-12  m-t-20'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={() => refreshTable(true)} />
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2'
                    onClick={() => refreshTable(true)}>{strings.common.search}</button>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [
                {
                  targets: [columnNo++], data: 'id', orderable: false,
                },
                {
                  targets: [columnNo++], data: 'room', orderable: false, width: 100,
                  createdCell: (td, value, item) => {
                    $(td)
                      .html(`<a href="javascript:;">${value}</a>`)
                      .find('a')
                      .on('click', () => showRoomDetail(item.room));
                  }
                },
                {
                  targets: [columnNo++], data: 'sender_name', orderable: false, width: 100,
                  createdCell: (td, value, item) => {
                    $(td).nickname_label(item.sender_name, item.sender_gender, () => onCell(item.sender));
                  }
                },
                {
                  targets: [columnNo++], data: 'receiver_name', orderable: false, width: 100,
                  createdCell: (td, value, item) => {
                    $(td).nickname_label(item.receiver_name, item.receiver_gender, () => onCell(item.receiver));
                  }
                },
                {
                  targets: [columnNo++], data: 'type', orderable: false,
                  render: value => value == 1 ? strings.room.chat_text : strings.room.chat_image
                },
                {
                  targets: [columnNo++], data: 'content', orderable: false, width: "30%"
                },
                {
                  targets: [columnNo++], data: 'create_date', orderable: false,
                },
                {
                  targets: [columnNo++], data: 'read', orderable: false,
                  render: value => value == 1 ? strings.room.chat_confirm : strings.room.chat_not_confirm
                },
                {
                  targets: [columnNo++], data: 'id', orderable: false,
                  createdCell: function (td, value, item) {
                    $(td).html('');
                    $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.common.delete}</button`)
                      .find('button:last')
                      .on('click', function () {
                        onChatDelete(item.id)
                      });
                  }
                }
              ],
              order: [[1, 'DESC']],
              ajax: {
                url: 'room/chatList',
                data: function (req) {
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                }
              },
              drawCallback: function () {
              },
              fnCreatedRow: function (row, data, dataInd) {
              }
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.room.room_id}</th>
              <th>{strings.room.sender}</th>
              <th>{strings.room.receiver}</th>
              <th>{strings.room.chat_type}</th>
              <th>{strings.room.content}</th>
              <th>{strings.common.create_date}</th>
              <th>{strings.room.read}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
    </Container>
  );

}

export default withRouter(inject('rootStore')(ChatList));
