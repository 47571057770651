import { useContext, useEffect, useState } from "react";

import { Panel, PanelBody, PanelFooter, PanelHeader } from '../../../components/panel/panel.jsx';
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import { Link } from "react-router-dom";
import { API_URL } from "../../../config/const";

const QuestionDetailInfo = ({ question_id }) => {

  const context = useContext(PageSettings);

  const [question, setQuestion] = useState({});
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    if (question_id > 0) {
      getQuestionInfo()
    }
  }, [question_id]);

  const getQuestionInfo = () => {
    context.get(
      'support/question/detail/' + question_id,
      {},
      response => {
        setQuestion(response.question);
        setPhotos(response.question?.file?.split('#').map(it => {
          return {
            url: it.getFullUrl().getThumbnailUrl(),
            origin: it.getFullUrl().getThumbnailUrl(),
            file: null
          };
        }));
      }
    );
  };

  const onQuestionChange = e => {
    setQuestion(prev => {
      return {
        ...prev,
        [e.target.id]: e.target.value
      }
    });
  }

  const onFileChange = (e, idx) => {

    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      setPhotos(prev => {
        if (idx == photos.length) {
          return prev.concat(
            {
              url: reader.result,
              file
            }
          )
        } else {
          return prev.map((it, index) => {
            if (index == idx) {
              it.url = reader.result;
              it.file = file;
            }
            return it;
          })
        }
      });
    };
    reader.readAsDataURL(file);
  };

  const onFileDelete = (index) => {
    setPhotos(prev => {
      return prev.map((it, idx) => {
        if (idx == index) {
          if (it.file != null) {
            it.url = it.origin;
            it.file = null;
          } else {
            it.url = '';
            it.origin = '';
          }
        }
        return it;
      });
    });
  };

  const uploadFile = () => {
    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      if (photos.filter(it => it.file != null).length > 0) {
        let formData = new FormData();
        photos.filter(it => it.file != null).map(it => it.file).forEach(file => {
          formData.append('file', file, file.name);
        });

        context.post(
          'upload/files/question',
          formData,
          response => {
            onSave(response.file);
          }
        );
        return;
      }

      onSave();
    });
  }

  const onSave = (upload_files = []) => {
    const photo = photos.map(it => {
      if (it.file != null && upload_files.length > 0) {
        return upload_files.shift();
      }
      return it.url.replace(API_URL + '/', '');
    }).filter(it => it != '').join('#');

    question.file = photo
    context.post(
      'support/question/save',
      {
        question
      },
      () => {
        context.addNotification(strings.modal.alert, strings.msg.saved);
        getQuestionInfo()
      }
    );
  }

  return (
    <div>
      <Panel>
        <PanelHeader noButton={true}>{strings.support.question_detail}</PanelHeader>
        <PanelBody>
          <div className="row">
            <div className="col-xs-12">
              <div className='row'>
                <label className='col-xl-4 col-form-label'>UID</label>
                <label className='col-xl-8 col-form-label'>{question?.id}</label>
              </div>
              <div className='row'>
                <label className='col-xl-4 col-form-label'>{strings.support.content}</label>
                <div className="col-xl-8">
                  <textarea className='form-control' id="content" value={question?.content || ''} required
                            rows={8} onChange={onQuestionChange} />
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-xl-4 col-form-label'>{strings.support.file}</label>
                <div className="col-xl-8">
                  <div className='row'>
                    {photos?.filter(it => it.url).map((image, index) => (
                      <div className='col-xl-3' key={index}>
                        <div style={{ height: 100, width: 100, position: "relative" }}>
                          <a onClick={() => {
                               context.showGallery(photos?.map(it => it.url.getFullUrl()), index)
                             }}>
                            <img src={image?.url?.getThumbnailUrl()}
                                 style={{ height: 100, width: 100 }} />
                          </a>
                          <button className="btn btn-danger btn-icon btn-sm"
                                  type='button'
                                  style={{ position: "absolute", top: "2px", right: "0px" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onFileDelete(index)
                                  }}>
                            <i className="fa fa-times" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-xl-4 col-form-label'>{strings.support.subject}</label>
                <div className="col-xl-8">
                  <input type={"text"} className='form-control' id="subject" value={question?.subject || ''} required
                         onChange={onQuestionChange} />
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-xl-4 col-form-label'>{strings.support.grade}</label>
                <div className="col-xl-8">
                  <input type={"text"} className='form-control' id="grade" value={question?.grade || ''} required
                         onChange={onQuestionChange} />
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-xl-4 col-form-label'>{strings.common.create_date}</label>
                <label className='col-xl-8 col-form-label'>{question?.create_date || ''}</label>
              </div>
            </div>
          </div>
        </PanelBody>
        <PanelFooter className={"text-right"}>
          <div className="d-flex w-100">
            <div>
              <Link to="/support/qna">
                <button type='button' className='btn btn-dark btn-sm m-2'>{strings.common.list}</button>
              </Link>
            </div>
            <div className="ml-auto">
              <button type='button' className='btn btn-success btn-sm m-2' onClick={() => {
                uploadFile()
              }}>{strings.common.save}</button>
            </div>
          </div>
        </PanelFooter>
      </Panel>
    </div>
  );
};

export default QuestionDetailInfo;
