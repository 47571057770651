import { useContext, useEffect, useState } from "react";

import { Panel, PanelBody, PanelFooter, PanelHeader } from '../../../components/panel/panel.jsx';
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import DateTime from "react-datetime";
import moment from "moment";
import C from "../../../config/const";
import { Link } from "react-router-dom";

const PlannerDetailInfo = ({ planner_id }) => {

  const context = useContext(PageSettings);

  const [planner, setPlanner] = useState({});             // 사용자정보(승인된 정보)

  useEffect(() => {
    if (planner_id > 0) {
      getPlannerInfo()
    }
  }, [planner_id]);

  const getPlannerInfo = () => {
    context.get(
      '/planner/detail/' + planner_id,
      {},
      response => {
        setPlanner(response.planner);
      }
    );
  };

  const onPlannerChange = e => {
    setPlanner(prev => {
      return {
        ...prev,
        [e.target.id]: e.target.value
      }
    });
  }

  const onSave = () => {
    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'planner/save',
        {
          planner
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
        }
      );
    });
  }

  return (
    <div>
      <Panel>
        <PanelHeader noButton={true}>{strings.planner.detail}</PanelHeader>
        <PanelBody>
          <div className="row">
            <div className="col-xs-12">
              <div className='row'>
                <label className='col-xl-4 col-form-label'>UID</label>
                <label className='col-xl-8 col-form-label'>{planner?.id}</label>
              </div>
              <div className='row'>
                <label className='col-xl-4 col-form-label'>{strings.planner.planner_date}</label>
                <div className="col-xs-8" style={{ zIndex: 1000 }}>
                  <DateTime
                    value={moment(planner?.planner_date)}
                    dateFormat={C.FORMAT.DATE}
                    timeFormat={false}
                    inputProps={{ placeholder: strings.planner.planner_date }}
                    closeOnSelect={true}
                    required
                    onChange={(e) => {
                      onPlannerChange({
                        target: {
                          id: "planner_date",
                          value: typeof e === 'string' ? e : e.format(C.FORMAT.DATE)
                        }
                      })
                    }} />
                </div>
              </div>

              <div className='row'>
                <label className='col-xl-4 col-form-label'>{strings.planner.comment}</label>
                <div className="col-xl-8">
                  <textarea className='form-control' id="comment" value={planner?.comment || ''} required
                            rows={8} onChange={onPlannerChange} />
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-xl-4 col-form-label'>{strings.planner.memo}</label>
                <div className="col-xl-8">
                  <textarea className='form-control' id="memo" value={planner?.memo || ''} required
                            rows={8} onChange={onPlannerChange} />
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-xl-4 col-form-label'>{strings.planner.feedback}</label>
                <div className="col-xl-8">
                  <textarea className='form-control' id="feedback" value={planner?.feedback || ''} required
                            rows={8} onChange={onPlannerChange} />
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-xl-4 col-form-label'>{strings.common.create_date}</label>
                <label className='col-xl-8 col-form-label'>{planner?.create_date || ''}</label>
              </div>
            </div>
          </div>
        </PanelBody>
        <PanelFooter className={"text-right"}>
          <div className="d-flex w-100">
            <div>
              <Link to="/planner/list">
                <button type='button' className='btn btn-dark btn-sm m-2'>{strings.common.list}</button>
              </Link>
            </div>
            <div className="ml-auto">
              <button type='button' className='btn btn-success btn-sm m-2' onClick={() => {
                onSave()
              }}>{strings.common.save}</button>
            </div>
          </div>
        </PanelFooter>
      </Panel>
    </div>
  );
};

export default PlannerDetailInfo;
