import { useContext, useRef } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import $ from 'jquery'

import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import C from "../../../config/const";

const MaterialCreatePopup = ({ isOpen, material_id, onClose, callback }) => {

  const context = useContext(PageSettings);
  const fileRef = useRef();
  let files = [];
  let url = "";
  let fileName = "";

  const toggleModal = result => {
    onClose?.(result);
  }

  const uploadFile = () => {
    if (files.length == 0) {
      return;
    }

    let formData = new FormData();

    files.forEach((entry) => {
      formData.append("file", entry, entry.name)
    })

    fileName = files.map(item => item.name).join("#")
    context.post(
      files.length > 1 ? 'upload/files/material' : 'upload/file/material',
      formData,
      function (response) {
        url = files.length > 1 ? response.file.join("#") : response.file
        onSave();
      },
      true
    );
  }

  const onSave = () => {
    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'support/material/save',
        {
          id: material_id,
          url,
          name: fileName,
          type: $('#type').val(),
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          files = [];
          url = "";
          fileName = "";
          callback()
        }
      );
    });
  }

  const onFileChange = (e) => {

    if (e.target.files.length < 1) {
      return;
    }

    for (let entry in e.target.files) {
      if (typeof e.target.files[entry] == "object") {
        files.push(e.target.files[entry])
      }
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} size={'xs'}>
      <ModalHeader className="popup-title" toggle={toggleModal}>
        {material_id ? strings.support.material_detail : strings.support.add_material}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-xs-12">
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.support.file}</label>
              <div className="col-xl-8 form-inline">
                <input type={"file"} className='hidden' ref={fileRef} id={"file"}
                       multiple accept={"*/*"} onChange={onFileChange} />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.support.material_type}</label>
              <div className="col-xl-8">
                <select id='type' className='form-control col-xl-8'>
                  {
                    C.MATERIAL_TYPE.map((item, idx) =>
                      <option key={idx} value={item.code}>{item.name}</option>
                    )
                  }
                </select>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
        <button className="btn btn-success" onClick={uploadFile}>{strings.modal.save}</button>
      </ModalFooter>
    </Modal>
  );
};

export default MaterialCreatePopup;
