export default {
    admin: 'Chewing 관리자',
    menu: {
        dashboard: '대시보드',
        member: '회원관리',
        member_all: '회원목록',
        student_all: '학생',
        export_list: '전문가',
        room: '룸 관리',
        room_study: '스터디 룸',
        room_all: '룸 목록',
        chat_all: '채팅 목록',
        room_challenge: '챌린지 룸',
        room_detail: '룸 상세',
        question_detail: '문의 상세',
        planner: '플래너 관리',
        planner_all: '플래너 목록',
        tag_all: '태그 목록',
        todo_all: '투두 리스트',
        shop: '츄잉 샵',
        product_list: '상품 목록',
        product_ad_list: '샵 광고 관리',
        purchase_list: '구매 내역',
        clinic: '클리닉 관리',
        clinic_all: '클리닉',
        clinic_chat_all: '컨설팅 내역',
        cabinet: '캐비닛',
        cloud: '클라우드 관리',
        cloud_all: '클라우드',
        ai: 'AI 튜터 관리',
        ai_subsection: '소단원 관리',
        support: '지원',
        support_qna: '멘토질문',
        support_lecture: '문항',
        support_material: '학습자료',
        mission: '미션',
        sta: "통계",
        sta_login: "로그인",
        sta_signup: "회원가입",
        sta_hourly: "접속",
        setting: "설정",
        setting_const: "설정",
        setting_term: "약관",
        setting_notice: "공지사항",
        manager: '관리자 관리',
        change_password: '비밀번호 변경',
        log_out: '로그아웃',
    },
    modal: {
        alert: '알림',
        confirm: '확인',
        ok: '예',
        no: '아니요',
        cancel: '취소',
        close: '닫기',
        apply: '적용',
        save_confirm: '저장하시겠습니까?',
        delete_confirm: '삭제하시겠습니까?',
        input_reason: 'input_reason',
        input_match_desc: 'input_match_desc',
        vip_confirm: 'vip_confirm',
        vip_release_confirm: 'vip_release_confirm',
        save: '저장',
        convert: '전환',
        block_confirm: 'block confirm?',
        unblock_confirm: 'unblock confirm?',
        room_block_confirm: '룸을 폐쇄하시겠습니까?',
        room_unblock_confirm: '룸을 복구하시겠습니까?',
        convert_expert_confirm: '전문가로 전환하시겠습니까?',
    },
    msg: {
        applied: '적용되었습니다.',
        saved: '저장되었습니다.',
        deleted: '삭제되었습니다.'
    },
    common: {
        save: '저장',
        delete: '삭제',
        edit: '수정',
        add: '추가',
        search: '검색',
        nickname: '닉네임',
        gender: '성별',
        male: '남자',
        female: '여자',
        manage: '관리',
        title: '제목',
        content: '내용',
        image: '이미지',
        status: '상태',
        create_date: '생성일',
        update_date: '수정일',
        download: '다운로드',
        view: '보기',
        show: 'show',
        hide: 'hide',
        list: '목록',
        type: 'type',
        total: '전체',
        year: '년',
        month: '월',
        date: '일',
        unknown: 'unknown',
        accept: 'accept',
        accept_cancel: 'accept_cancel',
        accepted: 'accepted',
        reject: 'reject',
        reject_cancel: 'reject_cancel',
        rejected: 'rejected',
        normal: 'normal',
        deleted: 'deleted',
        completed: 'completed',
        db_delete: 'DB에서 삭제',
        detail: '상세보기',
        mr: 'mr',
        on: 'ON',
        off: 'OFF',
    },
    search_option: {
        member: [
            { value: 'nickname', name: '닉네임' },
            { value: 'email', name: '이메일' },
            { value: 'id', name: '아이디' },
            { value: 'name', name: '이름' },
            { value: 'phone', name: '전화번호' },
        ],
        room: [
            { value: 'id', name: '아이디' },
            { value: 'title', name: '방이름' },
            { value: 'tag', name: '태그' },
            { value: 'intro', name: '소개글' },
        ],
        chat_room: [
            { value: 'id', name: '아이디' },
            { value: 'content', name: '내용' },
        ],
        cloud: [
            { value: 'id', name: '아이디' },
            { value: 'title', name: '파일명' },
        ],
        planner: [
            { value: 'name', name: '작성자' },
            { value: 'comment', name: 'COMMENT' },
            { value: 'memo', name: 'MEMO' },
            { value: 'feedback', name: '피드백' },
        ],
        tag: [
            { value: 'title', name: '내용' },
            { value: 'color', name: '색상' },
        ],
        todo: [
            { value: 'title', name: '내용' },
        ],
        question_list: [
            { value: 'id', name: '아이디' },
            { value: 'content', name: '내용' },
            { value: 'file', name: '파일' },
            { value: 'subject', name: '과목' },
            { value: 'grade', name: '학년' },
        ],
        study_item: [
            { value: 'id', name: '아이디' },
            { value: 'title', name: '제목' },
            { value: 'url', name: '링크' },
            { value: 'company', name: '제작사' },
            { value: 'code', name: '코드' },
        ],
        study_material: [
            { value: 'id', name: '아이디' },
            { value: 'name', name: '이름' },
            { value: 'url', name: '링크' },
            { value: 'type', name: '유형' },
        ],
        mission: [
            { value: 'id', name: '아이디' },
            { value: 'title', name: '제목' },
            { value: 'type', name: '유형' },
        ],
        notice: [
            { value: 'title', name: '제목' },
            { value: 'content', name: '내용' },
        ],
        product: [
            { value: 'id', name: '아이디' },
            { value: 'title', name: '상품명' },
            { value: 'category', name: '상품유형' },
            { value: 'content', name: '상품설명' },
        ],
        purchase: [
            { value: 'id', name: '아이디' },
            { value: 'title', name: '상품명' },
            { value: 'name', name: '구매자' },
        ],
        product_ad: [
            { value: 'id', name: '아이디' },
        ],
    },
    login: {
        id: '아이디',
        password: '비밀번호',
        login: '로그인',
    },
    change_password: {
        current_password: '현재 비밀번호',
        new_password: '새 비밀번호',
        confirm_password: '비밀번호 확인',
        change: '변경',
        password_not_correct: '새 비밀번호와 비밀번호 확인이 동일하지 않습니다.',
    },
    member: {
        detail: '회원 상세',
        id: '아이디',
        email: '이메일',
        name: '이름',
        gender: '성별',
        birthday: '생년월일',
        profile: '프로필',
        school: '학교',
        department: '소속',
        title: '홍보제목',
        introduction: '소개',
        tendency: '성향',
        user_type: '유형',
        student: '학생',
        expert: '전문가',
        time: '상담가능 시간',
        expert_english_name: '영어이름',
        expert_resume: '이력',
        expert_career: '경력',
        expert_curriculum: '커리큘럼',
        expert_refund_policy: '환불정책',
        hope_school: '희망학교',
        hope_career: '희망진로',
        goal: '목표 달성',
        goal_date: 'goal date',
        point: '포인트',
        score: '점수',
        total_study_time: '누적 공부 시간',
        min: ' 분',
        review_count: '후기 개수',
        like_count: '즐겨찾기 개수',
        total_star_count: '누적 평가수',
        average_star_count: '후기 평점',
        signup_date: '가입일',
        login_date: 'login date',
        login_ip: 'login ip',
        video: '영상노출',
        speaker: '음성출력',
        mirror: '전/후면',
        expert_time_format: '상담가능 시간을 입력하세요',
        tendency_format: '성향을 #로 구분하여 입력해주세요',
        status: '상태',
        status_date: '상태변경일',
        alarm_web: 'alarm web',
        alarm_market: 'alarm market',
        block: '차단',
        normal: '정상',
        unblock: '차단해제',
        convert_expert: '전문가로 전환',
        convert_student: '학생으로 전환',
        db_delete: 'DB에서 삭제',
        main_info: '기본정보',
        mission_info: '미션 정보',
        planner_info: '플래너 정보',
        tag_info: '태그 정보',
        todo_info: '투두리스트 정보',
        purchase_log: '구매내역',
        pay_log: '결제로그',
        key_log: '키변경로그',
        sleep_log: '휴면로그',
        invite_user: '친구초대',
        app_info: '앱 버전',
        mission_title: '미션명',
        mission_type: '미션 유형',
        mission_start_date: '미션 시작일',
        mission_end_date: '미션 종료일',
        mission_complete_date: '미션 완료일',
    },
    room: {
        id: "아이디",
        title: "룸 제목",
        name: "개설자",
        public: "공개 여부",
        room_mode: "룸 모드",
        open: "오픈",
        secret: "시크릿",
        type: "Type",
        image: "커버 이미지",
        tag: "태그",
        category: "카테고리",
        open_date: "기간",
        intro: "소개글",
        chat_count: "채팅수",
        member_count: "입장인원수",
        total_time: "전체 시간",
        min: " 분",
        challenge: "챌린지",
        detail: "룸 상세",
        main_info: "기본 정보",
        study_with_me: "스터디 윗미",
        challenge_room: "챌린지 룸",
        chat_log: "룸 채팅 기록",
        room_member: "룸 가입자",
        room_time: "가입자별 공부시간",
        room_id: "룸 아이디",
        sender: "송신자",
        receiver: "수신자",
        chat_type: "유형",
        content: "내용",
        read: "읽음 상태",
        member_name: "가입자명",
        study_start_time: "공부 시작 시간",
        join_start_time: "룸 가입일",
        study_end_time: "공부 종료 시간",
        leave_time: "룸 나간날짜",
        current_status: "현재 상태",
        joining: "접속중",
        leave: "나감",
        chat_text: "문자",
        chat_image: "이미지",
        chat_confirm: "확인",
        chat_not_confirm: "미확인",
        block: "폐쇄",
        unblock: "복구",
    },
    planner: {
        id: "ID",
        planner_date: "날짜",
        user_name: "작성자",
        tag: "태그수",
        tags: "태그",
        comment: "COMMENT",
        memo: "MEMO",
        feedback: "Feedback",
        main_info: "기본 정보",
        detail: "플래너 상세",
        tag_title: "태그 제목",
        todo_title: "투두 제목",
        todo_tag: "투두 태그",
        todo_status: "진행상태",
        color: "태그 색상",
        todo_list: "투두 리스트",
        todo_done: "완료",
        todo_doing: "진행중",
        select_todo_status: "투두 상태 선택",
        todo_detail: "투두 상태",
        add_todo: "투두 추가",
        new_todo: "신규 투두 제목",
        add_tag: "태그 추가",
        delete_tag: "태그 삭제",
        delete_todo: "투두 삭제",
        tag_planner: "플래너 아이디",
        tag_color: "태그 색상",
        todo_count: "투두 개수",
        tag_detail: "태그 상세",
        status_date: "상태 변경일",
        tag_id: "태그 아이디",
    },
    iap: {
        total_log: 'total_log',
        normal_log: 'normal_log',
        refund_log: 'refund_log',
        date: 'date',
        method: 'method',
        money: 'money',
        heart: 'heart',
        status: 'status',
        complete: 'complete',
        refund: 'refund',
        sta_01: 'sta_01',
        sta_02: 'sta_02',
        sta_03: 'sta_03',
        sta_04: 'sta_04',
        sta_05: 'sta_05',
        sta_06: 'sta_06',
        sta_07: 'sta_07',
        sta_08: 'sta_08',
        won: 'won',
    },
    dashboard: {
        today: '오늘',
        yesterday: '어제',
        this_week: '이번주',
        this_month: '이번달',
        this_year: '올해',
        sales: 'sales',
        signup_count: '가입자 수',
        signup_ratio: '가입율',
        iap_chart_title: 'iap_chart_title',
        iap_chart_money: 'iap_chart_money',
        iap_chart_count: 'iap_chart_count',
        login_chart_title: '로그인',
        signup_chart_title: '회원가입',
        grade_ratio: '학년별 회원수',
        just_go: '바로가기',
        grade1: '고1',
        grade2: '고2',
        grade3: '고3',
        most_use_function: '자주쓰는 기능순',
        efficiency: '공부 효율도 변화추이',
        age_group_ratio: '연령대별 회원수',
        ten_group: '10대',
        twenty_group: '20대',
        thirty_group: '30대',
        forty_group: '40대',
        fifty_group: '50대',
        sixty_group: '60대',
        seventy_group: '70대',
        room_open_ratio: '총회원 캠스터디 개설율',
        expert_student_matching_ratio: '전문가 학생 매칭율',
        planner_success_ratio: '플래너 진행율',
        expert: '전문가',
        total: '전체',
        study_efficiency: '공부 효율도',
        most_used_menu: '가장 많이 사용한 기능',
        real_time: "리얼타임",
        study_time: "공부시간",
        study: "스터디 룸",
        _y: "년",
        _m: "월",
        _d: "일",
        weekList: [
            "1주차",
            "2주차",
            "3주차",
            "4주차"
        ],
        weekList5: [
            "1주차",
            "2주차",
            "3주차",
            "4주차",
            "5주차"
        ],
        report_period: [
            { code: 0, name: "일별" },
            { code: 1, name: "주별" },
            { code: 2, name: "월별" },
        ],
        grade_list: [
            { code: 0, name: "고1" },
            { code: 1, name: "고2" },
            { code: 2, name: "고3/N수" },
        ],
        gender_list: [
            { code: 'M', name: "남자" },
            { code: "F", name: "여자" },
        ],
        goal_achievement: "목표달성",
        tag_order: "태그",
        past_list: [
            "어제",
            "저번주",
            "저번달"
        ],
        present_list: [
            "오늘",
            "이번주",
            "이번달"
        ],
        up_top: "상위 TOP 3",
        down_top: "하위 TOP 3",
        mission_accomplished: "미션 달성",
        attendance: "출석",
        _times: "회",
        comparison: "비교 수치",
        realTime: "리얼타임",
        _h: "H",
        coming_soon: "준비중 입니다",
        _Times: "회",
        total: "Total",
        curriculum: "맞춤형 커리큘럼 ",
        check_out: "확인하기",
        goal_ach: "목표달성",
        predicted_growth: "예측 성장률",
        efficiency: "공부효율",
        mission: "미션",
        menu_list: [
            { menu: "lounge", name: "라운지" },
            { menu: "study_room", name: "스터디 룸" },
            { menu: "conference_room", name: "컨퍼런스 룸" },
            { menu: "clinic", name: "클리닉" },
            { menu: "cloud", name: "클라우드" },
            { menu: "planner", name: "플래너" },
            { menu: "report", name: "데이터랩" },
            { menu: "shop", name: "츄잉샵" },
            { menu: "ranking", name: "랭킹" },
        ]
    },
    sta: {
        daily: 'daily',
        monthly: 'monthly',
        select_start_time: 'Select Start Time',
        select_end_time: 'Select End Time',
        login_total: 'Total Login',
        login_unique: 'Unique Login',
        date: 'Date',
        sales_email: 'Email',
        sales_google: 'Google',
        sales_appstore: 'Appstore',
        sales_onestore: 'Onestore',
        referrer_signup: 'referrer signup',
        hour_format: '{0}시',
        hour_avg: 'average',
        week_list: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    },
    cloud: {
        member: "회원명",
        title: "파일이름",
        type: "파일종류",
        size: "크기",
        add_files: "파일 추가",
        detail_cloud_file: "클라우드 상세",
    },
    support: {
        room_id: "룸 아이디",
        member_name: "회원이름",
        answer_count: "답변 횟수",
        file_count: "파일수",
        files: "파일",
        answers: "답변",
        content: "질문 내용",
        file: "파일",
        read: "읽음",
        subject: "과목",
        grade: "학년",
        add_answer: "답변 등록",
        answer_content: "답변 내용",
        save_answer: "답변 저장",
        question_detail: "질문 상세",
        question_content: "질문",
        delete_answer: "답변 삭제",
        file_name: "파일명",
        title: "제목",
        add_material: "자료 추가",
        add_item: "문항 추가",
        add_mission: "미션 추가",
        material_detail: "학습자료 상세",
        item_detail: "문항 상세",
        material_type: "학습자료 유형",
        video: "영상",
        company: "제작사",
        item_file: "문항 영상",
        mission_type: "미션 유형",
        mission_type_study: "스터디 카페",
        mission_type_planner: "Planner",
        mission_type_cabinet: "캐비닛",
        mission_type_clinic: "클리닉",
        mission_title: "미션 제목",
        mission_detail: "미션 내용",
        mission_create: "미션 생성",
        start_date: "시작일",
        end_date: "종료일",
        code: "문항코드",
        loader_name: "Loader Name",
        item_subject: "과목",
        item_speaker: "강사",
        item_grade: "학년",
    },
    shop: {
        category: "상품유형",
        title: "상품명",
        brand: "브랜드명",
        image: "상품 이미지",
        brand_image: "브랜드 이미지",
        point: "포인트",
        content: "상품 설명",
        status: "상태",
        sell: "판매중",
        not_sell: "판매불가",
        add_product: "상품 등록",
        add_product_ad: "샵 광고 등록",
        product_detail: "상품 상세",
        product: "상품",
        purchaser: "구매자",
        image_url: "이미지",
        show: "노출",
        hide: "비노출",
    },
    ai: {
        code: '코드',
        name: '단원명',
        link: '단원영상 유튜브 링크',
        file: '문제파일',
        add_subsection: '단원 등록',
        subsection_detail: '단원 상세',
    },
    reject_reason: {
        signup: []
    },
    term: {
        personal_info: '개인정보 수집 및 이용',
        terms_service: '서비스 이용약관',
        preview: '미리보기'
    },
    notice: {
        detail: '공지사항 상세',
        content: "내용",
        category_notice: "공지사항",
        category_event: "이벤트",
    },
    toast: {
        select_start_date: "시작일을 선택하세요",
        select_end_date: "종료일을 선택하세요",
    }
}
