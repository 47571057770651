import React, { useContext, useEffect, useRef, useState } from "react";
import $ from "jquery";

import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import Container from "../../components/control/container";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import TodoDetailPopup from "./popup/todo-detail-popup";

const TodoList = ({ history }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.tag;

  const [plannerDate, setPlannerDate] = useState('');

  useEffect(() => {
    refreshTable(true);
  }, [plannerDate]);

  const [popup, setPopup] = useState({
    isOpen: false,
    id: 0
  });

  const showPopup = id => setPopup(prev => {
    return { ...prev, isOpen: true, id };
  });

  const hidePopup = () => setPopup(prev => {
    return { ...prev, isOpen: false };
  });

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const showTagDetail = (id) => {
    history.push(`/tag/detail/${id}`);
  }

  const onDelete = tag_id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'planner/todo/' + tag_id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  return (
    <Container title={strings.menu.todo_all}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={() => refreshTable(true)} />
            <SearchSelect
              id='searchStatus'
              value={""}
              data={[
                { value: "", name: strings.planner.select_todo_status },
                { value: "N", name: strings.planner.todo_doing },
                { value: "Y", name: strings.planner.todo_done },
              ]}
              onChange={() => refreshTable(true)} />
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2'
                    onClick={() => refreshTable(true)}>{strings.common.search}</button>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'title', orderable: false,
                createdCell: (td, value, item) => {
                  $(td)
                    .html(`<a href="javascript:;">${value}</a>`)
                    .find('a')
                    .on('click', () => showPopup(item.id));
                }
              }, {
                targets: [columnNo++], data: 'tag', orderable: false,
                createdCell: (td, value, item) => {
                  $(td)
                    .html(`<a href="javascript:;">${value}</a>`)
                    .find('a')
                    .on('click', () => showTagDetail(item.tag_id));
                }
              }, {
                targets: [columnNo++], data: 'tag_title', orderable: false,
              }, {
                targets: [columnNo++], data: 'status', orderable: false,
                render: value => value == "Y" ? strings.planner.todo_done : strings.planner.todo_doing
              }, {
                targets: [columnNo++], data: 'status_date', orderable: false,
              }, {
                targets: [columnNo++], data: 'create_date', orderable: false,
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`<button type='button' class='btn btn-outline-danger btn-sm m-2 text-nowrap'>${strings.common.db_delete}</button>`)
                    .find('button:eq(0)')
                    .on('click', () => onDelete(item.id));
                }
              }],
              order: [[1, 'DESC']],
              ajax: {
                url: 'planner/todo/list',
                data: (req) => {
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                  req.status = $('#searchStatus').val();
                }
              },
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.planner.todo_title}</th>
              <th>{strings.planner.tag_id}</th>
              <th>{strings.planner.tag_title}</th>
              <th>{strings.planner.todo_status}</th>
              <th>{strings.planner.status_date}</th>
              <th>{strings.common.create_date}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
      <TodoDetailPopup {...popup} onClose={() => {
        hidePopup();
        refreshTable(false);
      }} />
    </Container>
  );

}

export default withRouter(inject('rootStore')(TodoList));
