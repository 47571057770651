import { useContext, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import $ from 'jquery'

import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";

const ConvertExpertPopup = ({ isOpen, user_id, onClose, callback }) => {
  const context = useContext(PageSettings);

  const toggleModal = result => {
    onClose?.(result);
  }

  const onSave = () => {
    if (!$('#member_title').val()) {
      $('#member_title').focus()
      return
    }
    if (!$('#member_department').val()) {
      $('#member_department').focus()
      return
    }
    if (!$('#member_intro').val()) {
      $('#member_intro').focus()
      return
    }
    if (!$('#member_tendency').val()) {
      $('#member_tendency').focus()
      return
    }
    if (!$('#member_time').val()) {
      $('#member_time').focus()
      return
    }
    if (!$('#member_english_name').val()) {
      $('#member_english_name').focus()
      return
    }
    if (!$('#member_resume').val()) {
      $('#member_resume').focus()
      return
    }
    if (!$('#member_career').val()) {
      $('#member_career').focus()
      return
    }
    if (!$('#member_curriculum').val()) {
      $('#member_curriculum').focus()
      return
    }
    if (!$('#member_refund_policy').val()) {
      $('#member_refund_policy').focus()
      return
    }
    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'member/convert',
        {
          id: user_id,
          title: $('#member_title').val(),
          department: $('#member_department').val(),
          intro: $('#member_intro').val(),
          tendency: $('#member_tendency').val(),
          time: $('#member_time').val(),
          english_name: $('#member_english_name').val(),
          resume: $('#member_resume').val(),
          career: $('#member_career').val(),
          curriculum: $('#member_curriculum').val(),
          refund_policy: $('#member_refund_policy').val(),
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          callback()
        }
      );
    });
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} size={'md'}>
      <ModalHeader className="popup-title" toggle={toggleModal}>
        {strings.member.convert_expert}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-xs-12">
            <div className='row'>
              <label className='col-xl-4 col-form-label'>{strings.member.title}</label>
              <div className="col-xl-8">
                <input type='text' autoComplete='off' className='form-control' id="member_title"
                       required={true} />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.member.department}</label>
              <div className="col-xl-8">
                <input type='text' autoComplete='off' className='form-control' id="member_department" required={true} />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.member.introduction}</label>
              <div className="col-xl-8">
                <input type='text' autoComplete='off' className='form-control' id="member_intro" required={true} />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.member.tendency}</label>
              <div className="col-xl-8">
                <input type='text' autoComplete='off' className='form-control' id="member_tendency" required={true}
                       placeholder={strings.member.tendency_format} />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.member.time}</label>
              <div className="col-xl-8">
                <input type='text' autoComplete='off' className='form-control' id="member_time" required={true}
                       placeholder={strings.member.expert_time_format} />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.member.expert_english_name}</label>
              <div className="col-xl-8">
                <input type='text' autoComplete='off' className='form-control' id="member_english_name"
                       required={true} />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.member.expert_resume}</label>
              <div className="col-xl-8">
                <textarea
                  id="member_resume"
                  className="form-control"
                  rows={5} />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.member.expert_career}</label>
              <div className="col-xl-8">
                <textarea
                  id="member_career"
                  className="form-control"
                  rows={5} />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.member.expert_curriculum}</label>
              <div className="col-xl-8">
                <textarea
                  id="member_curriculum"
                  className="form-control"
                  rows={5} />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.member.expert_refund_policy}</label>
              <div className="col-xl-8">
                <textarea
                  id="member_refund_policy"
                  className="form-control"
                  rows={5} />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
        <button className="btn btn-success" onClick={onSave}>{strings.modal.convert}</button>
      </ModalFooter>
    </Modal>
  );
};

export default ConvertExpertPopup;
