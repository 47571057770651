import { useContext, useEffect, useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import { Panel, PanelBody, PanelFooter } from "../../components/panel/panel";
import $ from "jquery"
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import TagDetailInfo from "./fragment/tag-detail-info";
import TagTodoDetail from "./fragment/tag-todo-detail";

const TagDetail = ({ tag_id, history }) => {

  const context = useContext(PageSettings);

  const [activeTab, setActiveTab] = useState('1');

  const [todoList, setTodoList] = useState([]);

  useEffect(() => {
    if (tag_id > 0) {
      getTodoList()
    }
  }, [tag_id]);

  const getTodoList = () => {
    context.get(
      '/planner/todo/list/' + tag_id,
      {},
      response => {
        setTodoList(response.list);
      }
    );
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const onTodoChange = e => {
    const { id, value } = e.target;
    setTodoList(prev => prev.map((it, idx) => {
      if (idx == Number(activeTab) - 1) {
        it[id] = value;
      }
      return it;
    }))
  }

  const onSave = (addFlag = false) => {
    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'planner/todo/save',
        addFlag ? { title: $('#todo-title').val(), tag: tag_id } : { ...todoList[Number(activeTab) - 1] },
        () => {
          getTodoList()
          context.addNotification(strings.modal.alert, strings.msg.saved);
        }
      );
    });
  }

  return (
    <div className="row">
      <div className="col-xl-6">
        <TagDetailInfo tag_id={tag_id} onSave={() => {
          if (tag_id < 1) {
            history.goBack();
          }
        }} />
      </div>
      {
        tag_id > 0 &&
        <div className="col-xl-6">
          <Panel>
            <Nav tabs className="nav-tabs-inverse">
              {
                todoList.filter(it => it.title != "").length > 0 ? todoList.filter(it => it.title != "").map((item, idx) =>
                    <NavItem key={idx}>
                      <NavLink className={classnames({ active: activeTab == idx + 1 })} onClick={() => {
                        toggle(idx + 1);
                      }}>
                        <span className="d-none d-xs-inline">{item.title}</span>
                      </NavLink>
                    </NavItem>
                  )
                  :
                  null
              }
            </Nav>
            <PanelBody>
              {
                todoList[Number(activeTab) - 1] &&
                <TagTodoDetail todo_info={todoList[Number(activeTab) - 1]} onChange={onTodoChange}
                               getTodoList={getTodoList} />
              }
            </PanelBody>
            <PanelFooter className={"text-right"}>
              <div className="d-flex w-100">
                <label className='col-xl-2 col-form-label'>{strings.planner.new_todo}:</label>
                <input type='text' autoComplete='off' className='form-control col-xl-7' id="todo-title" />
                <button type='button' className='btn btn-yellow form-control col-xl-2 m-l-5' onClick={() => {
                  onSave(true)
                }}>{strings.planner.add_todo}</button>
                {
                  todoList[Number(activeTab) - 1] &&
                  <div className="ml-auto">
                    <button type='button form-control' className='btn btn-success btn-sm form-control '
                            onClick={() => {
                              onSave()
                            }}>{strings.common.save}</button>
                  </div>
                }
              </div>
            </PanelFooter>
          </Panel>
        </div>
      }
    </div>
  );
};

export default withRouter(inject('rootStore')(TagDetail));
