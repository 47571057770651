import { useContext } from "react";

import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import $ from "jquery";
import C from "../../../config/const";

const PlannerTagDetail = ({ tag_info, onChange, getTagList }) => {

  const context = useContext(PageSettings);

  const onTodoSave = () => {
    if (!$('#new_todo').val()) {
      return;
    }

    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'planner/todo/save',
        {
          title: $('#new_todo').val(),
          tag: tag_info.id
        },
        () => {
          getTagList()
          context.addNotification(strings.modal.alert, strings.msg.saved);
        }
      );
    });
  }

  const onDelete = () => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'planner/tag/' + tag_info.id,
        {},
        () => {
          getTagList()
          context.addNotification(strings.modal.alert, strings.msg.deleted);
        }
      );
    });
  }

  const onTodoDelete = (id) => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'planner/todo/' + id,
        {},
        () => {
          getTagList()
          context.addNotification(strings.modal.alert, strings.msg.deleted);
        }
      );
    });
  }

  return (
    <div>
      <div>
        <button type='button' className='btn btn-dark btn-sm m-2' onClick={() => {
          onDelete()
        }}>{strings.planner.delete_tag}</button>
      </div>
      <div className='row'>
        <label className='col-xl-2 col-form-label'>UID</label>
        <label className='col-xl-10 col-form-label'>{tag_info?.id}</label>
      </div>
      <div className='row'>
        <label className='col-xl-2 col-form-label'>{strings.planner.tag_title}</label>
        <div className="col-xl-10">
          <input type='text' autoComplete='off' className='form-control' id="title"
                 value={tag_info?.title || ''} onChange={onChange} />
        </div>
      </div>
      <div className='row'>
        <label className='col-xl-2 col-form-label'>{strings.planner.color}</label>
        <div className="col-xl-10 form-inline">
          <select id='color' className='form-control col-xl-6 m-r-15' value={tag_info?.color || ''} onChange={onChange}>
            <option value="" />
            {
              C.colorList.map((item, idx) =>
                <option key={idx} value={item}>{item}</option>
              )
            }
          </select>
          <div
            style={{
              backgroundColor: tag_info?.color,
              height: 30,
              width: 70,
              display: "flex",
              alignItems: "center",
              justifyContent: 'center'
            }}>{tag_info?.color}</div>
        </div>
      </div>
      <div className='row m-t-5'>
        <label className='col-xl-2 col-form-label'>{strings.common.create_date}</label>
        <label className='col-xl-10 col-form-label'>{tag_info?.create_date || ''}</label>
      </div>
      <div className='row'>
        <label className='col-xl-2 col-form-label'>{strings.planner.todo_list}</label>
        <div className="col-xl-10">
          {
            tag_info?.todo_list.length > 0 ? tag_info?.todo_list?.map((item, idx) =>
                <div className='row m-b-5' key={idx}>
                  <label className='col-xl-1 col-form-label'>{(idx + 1) + ". "}</label>
                  <input type='text' autoComplete='off' className='form-control col-xl-6 m-r-20'
                         value={item.title || ''}
                         onChange={(e) => onChange({
                           target: {
                             todo: "Y",
                             todo_id: item.id,
                             id: "title",
                             value: e.target.value
                           }
                         })} />
                  <button className="btn btn-sm btn-primary m-r-5 col-xl-2" onClick={() => {
                    onChange({
                      target: {
                        todo: "Y",
                        todo_id: item.id,
                        id: "status",
                        value: item.status == "Y" ? "N" : "Y"
                      }
                    })
                  }}>
                    {item.status == "Y" ? strings.planner.todo_done : strings.planner.todo_doing}
                  </button>
                  <button className="btn btn-sm btn-dark m-r-5 col-xl-1" onClick={() => {
                    onTodoDelete(item.id)
                  }}>
                    {strings.common.delete}
                  </button>
                </div>
              )
              :
              null
          }
        </div>
        <div className='row m-t-10 '>
          <label className='col-xl-2 col-form-label'>{strings.planner.new_todo}</label>
          <input type='text' autoComplete='off' className='form-control col-xl-6 m-r-20 m-l-10' id="new_todo" />
          <button className="btn btn-sm btn-yellow m-r-5 col-xl-2" onClick={onTodoSave}>
            {strings.planner.add_todo}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlannerTagDetail;
