import React from "react";
import strings from "../lang/strings";
import C from "./const";

import Login from "../pages/auth/login";
import Dashboard from "../pages/dashboard/dashboard";
import StudentList from "../pages/member/student-list";
import ExpertList from "../pages/member/expert-list";
import RoomList from "../pages/room/room-list";
import PlannerList from "../pages/planner/planner-list";
import ChangePassword from "../pages/manager/change_password";
import PlannerDetail from "../pages/planner/planner-detail";
import TagList from "../pages/planner/tag-list";
import TagDetail from "../pages/planner/tag-detail";
import TodoList from "../pages/planner/todo-list";
import RoomDetail from "../pages/room/room-detail";
import ChatList from "../pages/room/chat-list";
import StaHourly from "../pages/sta/hourly";
import StaSignup from "../pages/sta/signup";
import StaLogin from "../pages/sta/login";
import QuestionList from "../pages/support/question-list";
import QuestionDetail from "../pages/support/question-detail";
import Term from "../pages/settting/term";
import Notice from "../pages/settting/notice";
import MaterialList from "../pages/support/material-list";
import ClinicChatList from "../pages/clinic/chat-list";
import ItemList from "../pages/support/item-list";
import MissionList from "../pages/support/mission-list";
import CloudList from "../pages/cloud/cloud-list";
import ProductList from "../pages/shop/product-list";
import PurchaseList from "../pages/shop/purchase-list";
import ProductAdList from "../pages/shop/product-ad-list";
import SubsectionList from "../pages/ai/subsection-list";

export const Extra = [
  {
    path: '/login', title: strings.login.login, exact: true, private: false,
    component: () => <Login />
  },
  {
    path: '/manager/change_pwd', title: strings.menu.change_password, exact: true, private: true,
    component: () => <ChangePassword />
  },
  {
    path: '/planner/detail/:plannerId', title: strings.menu.change_password, exact: true, private: true,
    component: (props) => <PlannerDetail planner_id={props.match.params.plannerId} />
  },
  {
    path: '/tag/detail/:tagId', title: strings.menu.change_password, exact: true, private: true,
    component: (props) => <TagDetail tag_id={props.match.params.tagId} />
  },
  {
    path: '/room/detail/:roomId', title: strings.menu.room_detail, exact: true, private: true,
    component: (props) => <RoomDetail room_id={props.match.params.roomId} />
  },
  {
    path: '/question/detail/:questionId', title: strings.menu.question_detail, exact: true, private: true,
    component: (props) => <QuestionDetail question_id={props.match.params.questionId} />
  },
];

const Menu = [
  {
    path: '/dashboard', icon: 'fa fa-th', title: strings.menu.dashboard, exact: true, private: true,
    component: () => <Dashboard />
  },
  {
    path: '/member', icon: 'fa fa-user', title: strings.menu.member,
    children: [
      {
        path: '/member/list', title: strings.menu.student_all, exact: true, private: true,
        component: () => <StudentList />
      },
      {
        path: '/member/expert/list', title: strings.menu.export_list, exact: true, private: true,
        component: () => <ExpertList />
      },
    ]
  },
  {
    path: '/room', icon: 'fa fa-coffee', title: strings.menu.room,
    children: [
      {
        path: '/room/list', title: strings.menu.room_all, exact: true, private: true,
        component: () => <RoomList/>
      },
      {
        path: '/room/chat/list', title: strings.menu.chat_all, exact: true, private: true,
        component: () => <ChatList/>
      },
    ]
  },
  {
    path: '/planner', icon: 'fa fa-table', title: strings.menu.planner,
    children: [
      {
        path: '/planner/list', title: strings.menu.planner_all, exact: true, private: true,
        component: () => <PlannerList />
      },
      {
        path: '/planner/tag/list', title: strings.menu.tag_all, exact: true, private: true,
        component: () => <TagList />
      },
      {
        path: '/planner/todo/list', title: strings.menu.todo_all, exact: true, private: true,
        component: () => <TodoList />
      },
    ]
  },
  {
    path: '/clinic', icon: 'fa fa-audio-description', title: strings.menu.clinic,
    children: [
      {
        path: '/clinic/chat/all', title: strings.menu.clinic_chat_all, exact: true, private: true,
        component: () => <ClinicChatList />
      },
    ]
  },
  {
    path: '/cloud', icon: 'fa fa-cloud', title: strings.menu.cloud,
    children: [
      {
        path: '/cloud/all', title: strings.menu.cloud_all, exact: true, private: true,
        component: () => <CloudList />
      },
    ]
  },
  {
    path: '/ai', icon: 'fa fa-cloud', title: strings.menu.ai,
    children: [
      {
        path: '/ai/subsection', title: strings.menu.ai_subsection, exact: true, private: true,
        component: () => <SubsectionList />
      },
    ]
  },
  {
    path: '/support', icon: 'fa fa-question', title: strings.menu.support,
    children: [
      {
        path: '/support/qna', title: strings.menu.support_qna, exact: true, private: true,
        component: () => <QuestionList />
      },
      {
        path: '/support/lecture', title: strings.menu.support_lecture, exact: true, private: true,
        component: () => <ItemList />
      },
      {
        path: '/support/study_material', title: strings.menu.support_material, exact: true, private: true,
        component: () => <MaterialList />
      },
      {
        path: '/support/mission', title: strings.menu.mission, exact: true, private: true,
        component: () => <MissionList />
      },
    ],
  },
  {
    path: '/shop', icon: 'fa fa-shopping-cart', title: strings.menu.shop,
    children: [
      {
        path: '/shop/product/all', title: strings.menu.product_list, exact: true, private: true,
        component: () => <ProductList />
      },
      {
        path: '/shop/ad/all', title: strings.menu.product_ad_list, exact: true, private: true,
        component: () => <ProductAdList />
      },
      {
        path: '/shop/purchase/all', title: strings.menu.purchase_list, exact: true, private: true,
        component: () => <PurchaseList />
      },
    ]
  },
  // {
  //   path: '/sta', icon: 'fa fa-chart-line', title: strings.menu.sta,
  //   children: [
  //     {
  //       path: '/sta/login', title: strings.menu.sta_login, exact: true, private: true,
  //       component: () => <StaLogin />
  //     },
  //     {
  //       path: '/sta/signup', title: strings.menu.sta_signup, exact: true, private: true,
  //       component: () => <StaSignup />
  //     },
  //     {
  //       path: '/sta/hourly', title: strings.menu.sta_hourly, exact: true, private: true,
  //       component: () => <StaHourly />
  //     },
  //   ],
  // },
  {
    path: '/setting', icon: 'fa fa-cog', title: strings.menu.setting,
    children: [
      {
        path: '/setting/term', title: strings.menu.setting_term, exact: true, private: true,
        component: () => <Term />
      },
      {
        path: '/setting/notice', title: strings.menu.setting_notice, exact: false, private: true,
        component: () => <Notice />
      },
    ],
  },
  {
    path: '/manager', icon: 'fa fa-user-secret', title: strings.menu.manager,
    children: [
      {
        path: '/manager/change_password', title: strings.menu.change_password, exact: true, private: true,
        component: () => <ChangePassword />
      },
    ],
    middle_manage: false,
  },
]

export default Menu;
