import { useContext, useEffect, useState } from "react";

import { Panel, PanelBody, PanelFooter, PanelHeader } from '../../../components/panel/panel.jsx';
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import { Link } from "react-router-dom";
import C from "../../../config/const";

const TagDetailInfo = ({ tag_id }) => {

  const context = useContext(PageSettings);

  const [tag, setTag] = useState({});             // 사용자정보(승인된 정보)
  const [todos, setTodos] = useState([]);             // 사용자정보(승인된 정보)

  useEffect(() => {
    if (tag_id > 0) {
      getTagInfo()
    }
  }, [tag_id]);

  const getTagInfo = () => {
    context.get(
      '/planner/tag/detail/' + tag_id,
      {},
      response => {
        setTag(response.tag);
        setTodos(response.todos)
      }
    );
  };

  const onTagChange = e => {
    setTag(prev => {
      return {
        ...prev,
        [e.target.id]: e.target.value
      }
    });
  }

  const onSave = () => {
    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      tag.todo_list = todos
      context.post(
        'planner/tag/save',
        {
          tag
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
        }
      );
    });
  }

  return (
    <div>
      <Panel>
        <PanelHeader noButton={true}>{strings.planner.tag_detail}</PanelHeader>
        <PanelBody>
          <div className="row">
            <div className="col-xs-12">
              <div className='row'>
                <label className='col-xl-4 col-form-label'>UID</label>
                <label className='col-xl-8 col-form-label'>{tag?.id}</label>
              </div>
              <div className='row'>
                <label className='col-xl-4 col-form-label'>{strings.planner.tag_title}</label>
                <div className="col-xl-8">
                  <input className='form-control' id="title" value={tag?.title || ''} required
                         onChange={onTagChange} />
                </div>
              </div>
              <div className='row'>
                <label className='col-xl-4 col-form-label'>{strings.planner.tag_color}</label>
                <div className="col-xl-8 form-inline">
                  <select id='color' className='col-xl-4 form-control m-r-10' value={tag?.color || ''}
                          onChange={onTagChange}>
                    <option value="" />
                    {
                      C.colorList.map((item, idx) =>
                        <option key={idx} value={item}>{item}</option>
                      )
                    }
                  </select>
                  <div
                    style={{
                      backgroundColor: tag.color,
                      height: 30,
                      width: 70,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: 'center'
                    }}>{tag.color}</div>
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-xl-4 col-form-label'>{strings.common.create_date}</label>
                <label className='col-xl-8 col-form-label'>{tag?.create_date || ''}</label>
              </div>
            </div>
          </div>
        </PanelBody>
        <PanelFooter className={"text-right"}>
          <div className="d-flex w-100">
            <div>
              <Link to="/planner/tag/list">
                <button type='button' className='btn btn-dark btn-sm m-2'>{strings.common.list}</button>
              </Link>
            </div>
            <div className="ml-auto">
              <button type='button' className='btn btn-success btn-sm m-2' onClick={() => {
                onSave()
              }}>{strings.common.save}</button>
            </div>
          </div>
        </PanelFooter>
      </Panel>
    </div>
  );
};

export default TagDetailInfo;
