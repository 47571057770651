import strings from "../../../lang/strings";
import { useContext, useRef } from "react";
import { PageSettings } from "../../../config/page-settings";
import DataTable from "../../../components/control/data-table";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";

const MemberDetailPopupPlanner = ({ user, onClose, history }) => {
  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();

  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const showPlannerDetail = (id) => {
    onClose()
    history.push(`/planner/detail/${id}`);
  }

  const onDelete = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'planner/' + id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  }

  return (
    <div className='row'>
      <div className='col-md-12'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'id', orderable: false,
            }, {
              targets: [columnNo++], data: 'planner_date', orderable: false,
              createdCell: (td, value, item) => {
                $(td)
                  .html(`<a href="javascript:;">${value}</a>`)
                  .find('a')
                  .on('click', () => showPlannerDetail(item.id));
              }
            }, {
              targets: [columnNo++], data: 'tag_count', orderable: false,
              render: (value) => value + " " + strings.planner.tags
            }, {
              targets: [columnNo++], data: 'comment', orderable: false,
            }, {
              targets: [columnNo++], data: 'memo', orderable: false,
            }, {
              targets: [columnNo++], data: 'feedback', orderable: false,
            }, {
              targets: [columnNo++], data: 'create_date', orderable: false,
            }, {
              targets: [columnNo++], data: 'id', orderable: false,
              createdCell: (td, value, item) => {
                $(td).html(`<button type='button' class='btn btn-outline-danger btn-sm m-2 text-nowrap'>${strings.common.db_delete}</button>`)
                  .find('button:eq(0)')
                  .on('click', () => onDelete(item.id));
              }
            }],
            order: [[1, 'DESC']],
            ajax: {
              url: 'planner/list/' + user.id,
              data: (req) => {
                req.column = '';
                req.keyword = '';
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.planner.planner_date}</th>
            <th>{strings.planner.tag}</th>
            <th>{strings.planner.comment}</th>
            <th>{strings.planner.memo}</th>
            <th>{strings.planner.feedback}</th>
            <th>{strings.common.create_date}</th>
            <th>{strings.common.manage}</th>
          </tr>
          </thead>
          <tbody />
        </DataTable>
      </div>
    </div>
  );
};

export default withRouter(inject('rootStore')(MemberDetailPopupPlanner));
