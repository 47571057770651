import { useContext, useEffect, useState } from 'react';
import strings from '../../../lang/strings';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Doughnut, Line } from 'react-chartjs-2';
import { PageSettings } from "../../../config/page-settings";
import moment from "moment";
import Container from '../../../components/control/container';
import ReportProgressBar from "../../../components/control/report_progress_bar";

const EfficiencyChartFragment = ({ type = 1 }) => {

  const context = useContext(PageSettings);
  const [periodOpen, setPeriodOpen] = useState(false);
  const [period, setPeriod] = useState(-1)
  const [gradeOpen, setGradeOpen] = useState(false);
  const [grade, setGrade] = useState(-1);
  const [genderOpen, setGenderOpen] = useState(false);
  const [gender, setGender] = useState('');
  const [yearSelectOpen, setYearSelectOpen] = useState(false);
  const [monthSelectOpen, setMonthSelectOpen] = useState(false);
  const [daySelectOpen, setDaySelectOpen] = useState(false);
  const [yearList, setYearList] = useState([]);
  const [year, setYear] = useState(-1);
  const [monthList, setMonthList] = useState([]);
  const [month, setMonth] = useState(-1);
  const [dayList, setDayList] = useState([]);
  const [day, setDay] = useState(-1);
  const [currentPeriod, setCurrentPeriod] = useState("");
  const [seriesTotal, setSeriesTotal] = useState([]);
  const [seriesReal, setSeriesReal] = useState([]);
  const [efficiency, setEfficiency] = useState(0);
  const [statusId, setStatusId] = useState(0);
  const timeLine = ["0", "1", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
  const monthLine = ["0", "1", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
  const [weekLine, setWeekLine] = useState([]);

  const [goalAchieve, setGoalAchieve] = useState(0);
  const [goalDone, setGoalDone] = useState(0);
  const [goalTotal, setGoalTotal] = useState(0);

  const [up3, setUp3] = useState([]);
  const [attendance, setAttendance] = useState(0);
  const [down3, setDown3] = useState([]);
  const [studyCafe, setStudyCafe] = useState(0);
  const [cabinet, setCabinet] = useState(0);
  const [nothing, setNothing] = useState(0)
  const [realTimeYesterday, setRealTimeYesterday] = useState(0);
  const [totalTimeYesterday, setTotalTimeYesterday] = useState(0);
  const [realTimeToday, setRealTimeToday] = useState(0);
  const [totalTimeToday, setTotalTimeToday] = useState(0);
  const [goalAchYesterday, setGoalAchYesterday] = useState(0);
  const [goalAchToday, setGoalAchToday] = useState(0);
  const [efficiencyYesterday, setEfficiencyYesterday] = useState(0);
  const [efficiencyToday, setEfficiencyToday] = useState(0);

  const [missionYesterday, setMissionYesterday] = useState(0);
  const [missionTotalYesterday, setMissionTotalYesterday] = useState(0);
  const [missionToday, setMissionToday] = useState(0);
  const [missionTotalToday, setMissionTotalToday] = useState(0);

  const getTimeFromSecond = (seconds) => {
    let second = seconds % 3600;
    let hour = (seconds - second) / 3600;
    let sec = second % 60;
    let min = (second - sec) / 60;
    let hour_str = "";
    let min_str = "";
    let sec_str = "";
    if (hour < 10) {
      hour_str = "0" + hour.toString();
    } else {
      hour_str = hour.toString();
    }
    if (min < 10) {
      min_str = "0" + min.toString();
    } else {
      min_str = min.toString();
    }
    if (sec < 10) {
      sec_str = "0" + sec.toString();
    } else {
      sec_str = sec.toString();
    }

    return hour_str + ":" + min_str + ":" + sec_str
  }

  const dateForm = (date) => {
    let date_str = ""
    if (date < 10) {
      date_str = "0" + date.toString();
    } else {
      date_str = date.toString();
    }
    return date_str
  }

  const getDaysFromStartEnd = (start, end, momentObj) => {
    let list = [];
    let endDate = moment(momentObj).daysInMonth();
    if (start > end) {
      for (let i = start; i <= endDate; i++) {
        list.push(dateForm(i) + strings.dashboard._d);
      }
      for (let i = 1; i <= end; i++) {
        list.push(dateForm(i) + strings.dashboard._d);
      }
    } else {
      for (let i = start; i <= end; i++) {
        list.push(dateForm(i) + strings.dashboard._d);
      }
    }
    return list
  }

  const lineChart = {
    data: {
      labels: period == 2 ? monthLine : (period == 1 ? weekLine : timeLine),
      datasets: [{
        label: strings.dashboard.real_time,
        borderColor: '#0f84f4',
        backgroundColor: '#0f84f4',
        pointRadius: 1,
        data: seriesTotal,
        pointHoverBackgroundColor: "rgba(231,243,254,0.3)",
        pointHoverRadius: 10,
        fill: false
      }, {
        label: strings.dashboard.study_time,
        borderColor: '#04c886',
        backgroundColor: '#04c886',
        pointRadius: 1,
        data: seriesReal,
        pointHoverBackgroundColor: "rgba(230,250,243,0.3)",
        pointHoverRadius: 10,
        fill: false

      }]
    },
    options: {
      plugins: {
        legend: {
          display: true,
          labels: {
            font: {
              size: 15
            },
            boxWidth: 11,
            boxHeight: 2,
            textAlign: "center",
          }
        },
        tooltip: {
          enabled: true,
          position: 'nearest',
          callbacks: {
            label: function (context) {
              let data = context.dataset.data;
              let label = context.dataset.label;
              let index = context.dataIndex;
              return label + " " + getTimeFromSecond(data[index]);
            }
          }
        }
      },
      interaction: {
        mode: "nearest",
        intersect: false,
        axis: "x"
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          min: 0,
          // max: (period == 0 ||period == -1) ? 24 : undefined,
          grid: {
            display: false
          },
          ticks: {
            callback: function (value, index, values) {
              return getTimeFromSecond(value);
            }
          }
        },
        x: {
          grid: {
            display: false
          }
        }
      }
    }
  }

  const doughnutChart = {
    data: {
      labels: [strings.dashboard.study, strings.menu.clinic, strings.menu.cabinet],
      datasets: [{
        data: [attendance, studyCafe, cabinet, nothing],
        backgroundColor: ['#0f84f4', '#04c886', '#ffeb36', '#dadada'],
        borderColor: ['#0f84f4', '#04c886', '#ffeb36', '#dadada'],
        borderWidth: 2,
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: true,
      cutout: "90%",
      plugins: {
        legend: {
          display: true
        },
      }
    }
  }
  const goalChart = {
    data: {
      datasets: [{
        data: [goalAchieve, 100 - goalAchieve],
        backgroundColor: ['#1d1f40', '#dadada'],
        borderColor: ['#1d1f40', '#dadada'],
        borderWidth: 2,
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: true,
      cutout: "70%",
      rotation: 270,
      circumference: 180,
      plugins: {
        legend: {
          display: true
        },
      }
    }

  }

  useEffect(() => {
    getYearList();
    getMonthList();
    getDayList(moment(new Date()).daysInMonth());
    getPlannerData(new Date, new Date);
    getMissionData(new Date, new Date);
    getCompareData("HOUR")
  }, []);

  useEffect(() => {
    if (period == 0) {
      getCompareData("HOUR")
      getPlannerData(new Date, new Date);
      getMissionData(new Date, new Date);
    } else if (period == 1) {
      getCompareData("DAY");
      getPlannerData(moment(new Date).startOf("week").toDate(), new Date)
      getMissionData(moment(new Date).startOf("week").toDate(), new Date)
    } else if (period == 2) {
      getCompareData("MONTH")
      getPlannerData(moment(new Date).startOf("month").toDate(), new Date)
      getMissionData(moment(new Date).startOf("month").toDate(), new Date)
    }
  }, [period]);

  useEffect(() => {
    if (efficiency < 30) {
      setStatusId(0)
    } else if (efficiency <= 50) {
      setStatusId(1)
    } else if (efficiency <= 70) {
      setStatusId(2)
    } else if (efficiency <= 90) {
      setStatusId(3)
    } else if (efficiency <= 100) {
      setStatusId(4)
    }

  }, [efficiency])

  const getToday = () => {
    let date = new Date()
    setCurrentPeriod(moment(date).year().toString() + strings.dashboard._y + " " + dateForm(moment(date).month() + 1) + strings.dashboard._m + " " + dateForm(moment(date).date()) + strings.dashboard._d);
  }

  const getChartData = (mode, from, to) => {
    context.get(
      "dashboard/getChartData",
      {
        mode: mode,
        from: moment(from).format("YYYY-MM-DD"),
        to: moment(to).format("YYYY-MM-DD"),
        gender,
        grade
      },
      response => {
        let total = [];
        let real = [];
        let totalSum = 0;
        let realSum = 0;
        response.list.forEach(item => {
          total.push(parseInt(item.total_diff));
          totalSum += parseInt(item.total_diff);
          real.push(parseInt(item.real_diff));
          realSum += parseInt(item.real_diff);
        });
        setSeriesTotal(total);
        setSeriesReal(real);
        setEfficiency(totalSum != 0 ? (realSum / totalSum) * 100 : 0);
      }
    );
  }

  const getPlannerData = (from, to) => {
    context.get(
      "dashboard/getPlannerData",
      {
        from: moment(from).format("YYYY-MM-DD"),
        to: moment(to).format("YYYY-MM-DD")
      },
      response => {
        setGoalDone(response.todo.complete_count);
        setGoalTotal(response.todo.total_count);
        if (response.todo.total_count != 0) {
          setGoalAchieve(parseInt(response.todo.complete_count) / parseInt(response.todo.total_count) * 100)
        } else {
          setGoalAchieve(0)
        }
        setUp3(response.tag_top)
        setDown3(response.tag_low)
      }
    );
  }

  const getMissionData = (from, to) => {
    context.get(
      "dashboard/getMissionData",
      {
        from: moment(from).format("YYYY-MM-DD"),
        to: moment(to).format("YYYY-MM-DD")
      },
      response => {
        if (response.mission_count == 0) {
          setNothing(1);
        } else {
          setNothing(0);
          let typeList = [];
          response.mission.forEach(item => {
            typeList.push(item.type)
            if (item.type == "STUDY") {
              setAttendance(item.total_count)
            } else if (item.type == "CABINET") {
              setCabinet(item.total_count)
            } else if (item.type == "CLINIC") {
              setStudyCafe(item.total_count)
            }
          });
          if (typeList.indexOf("STUDY") == -1) {
            setAttendance(0)
          }
          if (typeList.indexOf("CABINET") == -1) {
            setCabinet(0)
          }
          if (typeList.indexOf("CLINIC") == -1) {
            setStudyCafe(0)
          }
        }
      }
    );
  }

  const getCompareData = (mode) => {
    context.get(
      "dashboard/getCompareData",
      {
        mode: mode
      },
      response => {
        setRealTimeToday(parseInt(response.today.study_real_time) / 3600);
        setTotalTimeToday(parseInt(response.today.study_total_time) / 3600);
        setRealTimeYesterday(parseInt(response.yesterday.study_real_time) / 3600);
        setTotalTimeYesterday(parseInt(response.yesterday.study_total_time) / 3600);
        setGoalAchToday(response.today.todo_total_count != 0 ? response.today.todo_complete_count / response.today.todo_total_count * 100 : 0);
        setGoalAchYesterday(response.yesterday.todo_total_count != 0 ? response.yesterday.todo_complete_count / response.yesterday.todo_total_count * 100 : 0);
        setEfficiencyToday(parseInt(response.today.study_total_time) != 0 ? parseInt(response.today.study_real_time) / parseInt(response.today.study_total_time) * 100 : 0)
        setEfficiencyYesterday(parseInt(response.yesterday.study_total_time) != 0 ? parseInt(response.yesterday.study_real_time) / parseInt(response.yesterday.study_total_time) * 100 : 0)
        setMissionToday(parseInt(response.today.mission_complete_count));
        setMissionTotalToday(parseInt(response.today.mission_total_count));
        setMissionYesterday(parseInt(response.yesterday.mission_complete_count));
        setMissionTotalYesterday(parseInt(response.yesterday.mission_total_count));
      }
    );
  }

  useEffect(() => {
    if (period == 2) {
      // setCurrentPeriod(yearList[year] + " " + monthList[month])
      setCurrentPeriod(yearList[year])
      getChartData("MONTH", yearList[year].split("Y")[0] + "-" + "01" + "-" + "01", yearList[year].split("Y")[0] + "-" + "12" + "-" + "31")
    } else if (period == 1) {
      if (day > dayList.length - 1) {
        setDay(0);
      }
      if (year != -1) {
        getDayFromWeek(yearList[year].split("Y")[0] + "." + monthList[month].split("M")[0]);
      }
      setCurrentPeriod(yearList[year] + " " + monthList[month] + " " + dayList[day])
    } else if (period == 0) {
      if (dayList.length < 10) {
        getDayList(moment(yearList[year].split("Y")[0] + "." + monthList[month].split("M")[0] + ".01").daysInMonth())
      }
      setCurrentPeriod(yearList[year] + " " + monthList[month] + " " + dayList[day]);
      getChartData("HOUR", yearList[year].split("Y")[0] + "-" + monthList[month].split("M")[0] + "-" + dayList[day].split("D")[0], yearList[year].split("Y")[0] + "-" + monthList[month].split("M")[0] + "-" + dayList[day].split("D")[0])
    } else {
      getToday();
      getChartData("HOUR", new Date, new Date);
    }
  }, [period, year, month, day, dayList, gender, grade])

  useEffect(() => {
    if (period == 1) {
      setDayList(strings.dashboard.weekList);
      return
    }
    if (year != -1 && month != -1) {
      getDayList(moment(yearList[year].split("Y")[0] + "." + monthList[month].split("M")[0] + ".01").daysInMonth())
      // setDay(0)
    }
  }, [year, month]);

  const getYearList = () => {
    let now = moment(new Date()).year();
    let list = [];
    for (let i = now - 10; i < now + 1; i++) {
      list.push(i.toString() + strings.dashboard._y)
    }
    setYearList(list)
    setYear(yearList.length - 1)
  }

  const getMonthList = () => {
    let list = [];
    let month = ""
    for (let i = 1; i < 13; i++) {
      month = dateForm(i) + strings.dashboard._m
      list.push(month)
    }
    setMonthList(list)
  }

  const getDayList = (days) => {
    let list = [];
    let day = "";
    for (let i = 1; i <= days; i++) {
      day = dateForm(i) + strings.dashboard._d
      list.push(day)
    }
    setDayList(list)
  }

  const getDayFromWeek = (momentObj) => {
    let obj = moment(momentObj), first, last, weekCount, weekStart, weekEnd;
    first = obj.startOf("month").week();
    last = obj.endOf("month").week();
    if (last < first) {
      weekCount = 52 + first - last;
    } else {
      weekCount = last - first + 1;
    }
    if (weekCount < 5) {
      setDayList(strings.dashboard.weekList);
    } else {
      setDayList(strings.dashboard.weekList5);
    }
    weekStart = moment(moment(yearList[year].split("Y")[0] + "." + (first + day).toString(), "YYYY.ww").startOf("week").toDate()).format("YYYY-MM-DD");
    weekEnd = moment(moment(yearList[year].split("Y")[0] + "." + (first + day).toString(), "YYYY.ww").endOf("week").toDate()).format("YYYY-MM-DD");
    setWeekLine(getDaysFromStartEnd(moment(weekStart).date(), moment(weekEnd).date(), weekStart))
    getChartData("DAY", weekStart, weekEnd)
  }

  const togglePeriod = () => {
    setPeriodOpen(!periodOpen);
  }

  const toggleGrade = () => {
    setGradeOpen(!gradeOpen);
  }

  const toggleGender = () => {
    setGenderOpen(!genderOpen);
  }

  const toggleYear = () => {
    setYearSelectOpen(!yearSelectOpen);
  }

  const toggleMonth = () => {
    setMonthSelectOpen(!monthSelectOpen);
  }

  const toggleDay = () => {
    setDaySelectOpen(!daySelectOpen);
  }

  const periodFix = () => {
    if (period == -1) {
      setPeriod(0);
    }
    if (year == -1) {
      setYear(yearList.indexOf(moment(new Date()).year().toString() + strings.dashboard._y));
    }
    if (month == -1) {
      let mm = moment(new Date()).month() + 1;
      mm = dateForm(mm) + strings.dashboard._m
      setMonth(monthList.indexOf(mm))
    }
    if (day == -1) {
      setDay(dayList.indexOf(dateForm(moment(new Date()).date()) + strings.dashboard._d))
    }
  }

  const getDecimal = (num_str) => {
    let num = num_str.split(".")[0]
    let decimal = num_str.split(".")[1]
    if (decimal !== ("" || undefined)) {
      return num + "." + decimal.substr(0, 1);
    } else {
      return num
    }
  }

  return (
    <Container title={strings.dashboard.study_efficiency} className="flex-fill">
      <div className="d-flex m-b-10">
        <Dropdown isOpen={periodOpen} toggle={() => togglePeriod()}
                  className="dropdown navbar-user d-flex align-items-center justify-content-center height-40 width-80"
                  tag="li">
          <DropdownToggle tag="a"
                          className="display-flex-important align-item-center form-control  justify-content-center height-40 width-80">
            <div
              className="flex-grow-1">{period != -1 ? strings.dashboard.report_period[period].name : strings.dashboard.report_period[0].name}</div>
            <b className="caret" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">
            {
              strings.dashboard.report_period.map((item, idx) =>
                <DropdownItem key={idx}
                              className="dropdown-header display-flex-important justify-content-center f-s-15"
                              onClick={() => {
                                periodFix()
                                setPeriod(item.code);
                              }}
                >
                  {item.name}
                </DropdownItem>
              )
            }
          </DropdownMenu>
        </Dropdown>

        {/*grade*/}
        <Dropdown isOpen={gradeOpen} toggle={() => toggleGrade()}
                  className="dropdown navbar-user d-flex  m-l-10 align-items-center justify-content-center height-40 width-80"
                  tag="li">
          <DropdownToggle tag="a"
                          className="display-flex-important align-item-center form-control  justify-content-center height-40 width-80">
            <div
              className="flex-grow-1">{grade != -1 ? strings.dashboard.grade_list[grade].name : strings.dashboard.grade_list[0].name}</div>
            <b className="caret" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">
            {
              strings.dashboard.grade_list.map((item, idx) =>
                <DropdownItem key={idx}
                              className="dropdown-header display-flex-important justify-content-center f-s-15"
                              onClick={() => {
                                setGrade(item.code);
                              }}
                >
                  {item.name}
                </DropdownItem>
              )
            }
          </DropdownMenu>
        </Dropdown>

        {/*gender*/}
        <Dropdown isOpen={genderOpen} toggle={() => toggleGender()}
                  className="dropdown navbar-user  m-l-10 m-r-auto d-flex align-items-center justify-content-center height-40 width-80"
                  tag="li">
          <DropdownToggle tag="a"
                          className="display-flex-important align-item-center form-control  justify-content-center height-40 width-80">
            <div
              className="flex-grow-1">{gender != '' ? strings.dashboard.gender_list.find(it => it.code == gender).name : strings.dashboard.gender_list[0].name}</div>
            <b className="caret" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">
            {
              strings.dashboard.gender_list.map((item, idx) =>
                <DropdownItem key={idx}
                              className="dropdown-header display-flex-important justify-content-center f-s-15"
                              onClick={() => {
                                setGender(item.code);
                              }}
                >
                  {item.name}
                </DropdownItem>
              )
            }
          </DropdownMenu>
        </Dropdown>

        <Dropdown isOpen={yearSelectOpen} toggle={() => toggleYear()}
                  className="dropdown navbar-user d-flex   m-l-10 align-items-center justify-content-center height-40 width-80"
                  tag="li">
          <DropdownToggle tag="a"
                          className="display-flex-important align-item-center form-control  justify-content-center height-40 width-80">
            <div
              className="flex-grow-1">{year != -1 ? yearList[year] : moment(new Date()).year().toString() + strings.dashboard._y}</div>
            <b className="caret" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">
            {
              yearList.map((item, idx) =>
                <DropdownItem key={idx}
                              className="dropdown-header display-flex-important justify-content-center f-s-15"
                              onClick={() => {
                                periodFix()
                                setYear(idx)
                              }}
                >
                  {item}
                </DropdownItem>
              )
            }
          </DropdownMenu>
        </Dropdown>

        {
          period != 2 &&
          <Dropdown isOpen={monthSelectOpen} toggle={() => toggleMonth()}
                    className="dropdown navbar-user d-flex m-l-10 align-items-center justify-content-center height-40 width-80"
                    tag="li">
            <DropdownToggle tag="a"
                            className="display-flex-important align-item-center form-control  justify-content-center height-40 width-80">
              <div
                className="flex-grow-1">{month != -1 ? monthList[month] : dateForm(moment(new Date()).month() + 1) + strings.dashboard._m}</div>
              <b className="caret" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">
              {
                monthList.map((item, idx) =>
                  <DropdownItem key={idx}
                                className="dropdown-header display-flex-important justify-content-center f-s-15"
                                onClick={() => {
                                  periodFix()
                                  setMonth(idx)
                                }}
                  >
                    {item}
                  </DropdownItem>
                )
              }
            </DropdownMenu>
          </Dropdown>
        }
        {
          period <= 1 &&
          <Dropdown isOpen={daySelectOpen} toggle={() => toggleDay()}
                    className="dropdown navbar-user d-flex m-l-10 align-items-center justify-content-center height-40 width-80"
                    tag="li">
            <DropdownToggle tag="a"
                            className="display-flex-important align-item-center form-control  justify-content-center height-40 width-80">
              <div
                className="flex-grow-1">{day != -1 ? dayList[day] : moment(new Date()).date().toString() + strings.dashboard._d}</div>
              <b className="caret" />
            </DropdownToggle>
            <DropdownMenu
              className="dropdown-menu dropdown-menu-right profile-dropdown-menu "
              tag="ul">
              <PerfectScrollbar className="report-dropdown-menu" options={{ suppressScrollX: true }}
                                style={{ maxHeight: 500, overflowY: "auto" }}>
                {
                  dayList.map((item, idx) =>
                    <DropdownItem key={idx}
                                  className="dropdown-header display-flex-important justify-content-center f-s-15"
                                  onClick={() => {
                                    periodFix()
                                    setDay(idx)
                                  }}
                    >
                      {item}
                    </DropdownItem>
                  )
                }
              </PerfectScrollbar>
            </DropdownMenu>
          </Dropdown>
        }
      </div>
      <div className="height-sm ">
        <Line data={lineChart.data} options={lineChart.options} />
      </div>
      <div className="row" style={{ marginTop: 60 }}>
        <div className="col-xl-4">
          <div className="f-s-18 f-w-600">{strings.dashboard.goal_achievement}</div>
          <Doughnut data={goalChart.data} options={goalChart.options} />
          <div className="f-s-18 f-w-600">{strings.dashboard.tag_order}</div>
          <div className="d-flex f-s-15 m-t-10 align-items-center">
            <div className="m-r-auto"  style={{ minWidth: 90 }}>{strings.dashboard.up_top}</div>
            {
              up3.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className="label label-green m-r-5"
                    style={{ background: item.color }}
                  >{item.title}</div>
                )
              })
            }

          </div>
          <div className="d-flex f-s-15 m-t-10 align-items-center">
            <div className="m-r-auto" style={{ minWidth: 90 }}>{strings.dashboard.down_top}</div>
            {
              down3.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className="label label-green m-r-5"
                    style={{ background: item.color }}
                  >{item.title}</div>
                )
              })
            }

          </div>
        </div>

        {/*mission*/}
        <div className="col-xl-4">
          <div className="f-s-18 f-w-600 m-r-auto">{strings.dashboard.mission_accomplished}</div>
          <div className="doughnut" style={{ marginTop: 60 }}>
            <Doughnut data={doughnutChart.data} options={doughnutChart.options} />
          </div>
        </div>

        <div className="col-xl-4">
          <div className="f-s-18 f-w-600 m-r-auto">{strings.dashboard.comparison}</div>
          <div className="d-flex align-item-center m-b-15" style={{ marginTop: 60 }}>
            <div
              className="flex-fill text-center">{period == -1 ? strings.dashboard.past_list[0] : strings.dashboard.past_list[period]}</div>
            <div
              className="flex-fill text-center">{period == -1 ? strings.dashboard.present_list[0] : strings.dashboard.present_list[period]}</div>
          </div>
          <ReportProgressBar
            yesterday={getDecimal(realTimeYesterday.toString()) + strings.dashboard._h}
            yesterdayPercent={totalTimeYesterday != 0 ? realTimeYesterday / totalTimeYesterday * 100 : 0}
            today={getDecimal(realTimeToday.toString()) + strings.dashboard._h}
            todayPercent={totalTimeToday != 0 ? realTimeToday / totalTimeToday * 100 : 0}
            indicator={strings.dashboard.realTime}
          />
          <ReportProgressBar
            yesterday={getDecimal(goalAchYesterday.toString()) + "%"}
            yesterdayPercent={goalAchYesterday}
            today={getDecimal(goalAchToday.toString()) + "%"}
            todayPercent={goalAchToday}
            indicator={strings.dashboard.goal_ach}
          />
          <ReportProgressBar
            yesterday={getDecimal(efficiencyYesterday.toString()) + "%"}
            yesterdayPercent={efficiencyYesterday}
            today={getDecimal(efficiencyToday.toString()) + "%"}
            todayPercent={efficiencyToday}
            indicator={strings.dashboard.efficiency}
          />
          <ReportProgressBar
            yesterday={missionYesterday.toString() + strings.dashboard._times}
            yesterdayPercent={missionTotalYesterday != 0 ? missionYesterday / missionTotalYesterday * 100 : 0}
            today={missionToday.toString() + strings.dashboard._times}
            todayPercent={missionTotalToday != 0 ? missionToday / missionTotalToday * 100 : 0}
            indicator={strings.dashboard.mission}
          />
        </div>
      </div>

    </Container>
  );
}


export default EfficiencyChartFragment;
