import strings from "../../../lang/strings";
import { useContext, useRef } from "react";
import { PageSettings } from "../../../config/page-settings";
import DataTable from "../../../components/control/data-table";
import $ from "jquery";

const MemberDetailPopupMission = ({ user }) => {
  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();

  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const onDelete = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'support/mission/member/' + id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  }

  return (
    <div className='row'>
      <div className='col-md-12'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'id', orderable: false,
            }, {
              targets: [columnNo++], data: 'title', orderable: false,
            }, {
              targets: [columnNo++], data: 'type', orderable: false,
            }, {
              targets: [columnNo++], data: 'start_date', orderable: false,
            }, {
              targets: [columnNo++], data: 'end_date', orderable: false,
            }, {
              targets: [columnNo++], data: 'create_date', orderable: false,
            }, {
              targets: [columnNo++], data: 'id', orderable: false,
              createdCell: (td, value) => {
                $(td).html(`<button type='button' class='btn btn-outline-danger btn-sm text-nowrap'>${strings.common.delete}</button>`)
                  .find('button:eq(0)')
                  .on('click', () => onDelete(value));
              }
            }],
            order: [[1, 'DESC']],
            ajax: {
              url: 'support/missionList/' + user.id,
              data: (req) => {
                req.column = '';
                req.keyword = '';
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.member.mission_title}</th>
            <th>{strings.member.mission_type}</th>
            <th>{strings.member.mission_start_date}</th>
            <th>{strings.member.mission_end_date}</th>
            <th>{strings.member.mission_complete_date}</th>
            <th>{strings.common.manage}</th>
          </tr>
          </thead>
          <tbody />
        </DataTable>
      </div>
    </div>
  );
};

export default MemberDetailPopupMission;
