import React, { useContext, useRef, useState } from "react";
import { PageSettings } from "../../config/page-settings";

import strings from "../../lang/strings";
import Container from "../../components/control/container";
import $ from "jquery";
import DataTable from "../../components/control/data-table";
import NoticePopup from "./popup/notice-popup";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";

const Notice = () => {

  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const [popup, setPopup] = useState({
    isOpen: false,
    id: 0
  });
  const hidePopup = () => setPopup(prev => {
    return {
      ...prev,
      isOpen: false
    };
  });

  const onAdd = id => {
    setPopup(prev => {
      return {
        ...prev,
        isOpen: true,
        id
      };
    });
  }

  const onDelete = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        `setting/notice/${id}`,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  }

  return (
    <Container title={strings.menu.setting_notice}>
      <div className='row'>
        <div className='col-md-10 col-xl-8 col-xxl-4'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              id='searchColumn'
              value={strings.search_option.notice[0].value}
              data={strings.search_option.notice}
              onChange={() => refreshTable(true)} />
            <SearchInput
              id='searchInput'
              className='width-250'
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-1'
                    onClick={() => refreshTable(true)}>{strings.common.search}</button>
            <button type='button' className='btn btn-primary btn-sm ml-md-2'
                    onClick={() => onAdd(0)}>{strings.common.add}</button>
          </div>
        </div>
      </div>
      <div className='row mt-2'>
        <div className='col-md-12'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'title', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`<a href="javascript:;">${value}</a>`)
                    .find('a')
                    .on('click', () => onAdd(item.id));
                },
              }, {
                targets: [columnNo++], data: 'category', orderable: false,
                render: value => value == 1 ? strings.notice.category_notice : strings.notice.category_event
              }, {
                targets: [columnNo++], data: 'content', orderable: false,
              }, {
                targets: [columnNo++], data: 'create_date', orderable: false,
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td)
                    .html(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.common.delete}</button>`)
                    .find('button:eq(0)')
                    .on('click', () => onDelete(item.id));
                }
              }],
              order: [[4, 'DESC']],
              ajax: {
                url: 'setting/notice',
                data: (req) => {
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                }
              },
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.common.title}</th>
              <th>{strings.common.type}</th>
              <th>{strings.notice.content}</th>
              <th>{strings.common.create_date}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
      <NoticePopup {...popup} onClose={res => {
        hidePopup();
        if (res === true) {
          refreshTable(false);
        }
      }} />
    </Container>
  );

}

export default Notice;
