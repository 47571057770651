import MenuStaFragment from './fragment/menu-sta-fragment';
import GenderStaFragment from './fragment/gender-sta-fragment';
import SignupStaFragment from './fragment/signup-sta-fragment';
import GradeStaFragment from "./fragment/grade-sta-fragment";
import AgeGroupStaFragment from "./fragment/age-group-sta-fragment";
import RoomMemberStaFragment from "./fragment/room-member-sta-fragment";
import PlannerStaFragment from "./fragment/planner-sta-fragment";
import ClinicStaFragment from "./fragment/clinic-sta-fragment";
import EfficiencyChartFragment from "./fragment/efficiency-chart-fragment";
import strings from "../../lang/strings";

const Dashboard = () => {
  return (
    <div>
      <h1 className="page-header">{strings.menu.dashboard}</h1>
      <div className="row">
        <div className="col-xl-3 col-md-6">
          <SignupStaFragment />
        </div>
        <div className="col-xl-3 col-md-6">
          <GenderStaFragment />
        </div>
        <div className="col-xl-3 col-md-6">
          <GradeStaFragment />
        </div>
        <div className="col-xl-3 col-md-6">
          <AgeGroupStaFragment />
        </div>
      </div>

      <div className="row">
        <div className="col-xl-8 col-md-12">
          <EfficiencyChartFragment />
        </div>
        <div className="col-xl-4 col-md-12">
          <MenuStaFragment />
          <RoomMemberStaFragment />
          <PlannerStaFragment />
          <ClinicStaFragment />
        </div>
      </div>
    </div>
  );

}

export default Dashboard;
