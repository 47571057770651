import React from "react";

const ReportProgressBar = (props) => {

  const { yesterday, today, indicator, yesterdayPercent, todayPercent, margin } = props;

  return (
    <div className={("d-flex align-item-center ") + (margin ? "m-b-10 m-t-35" : "m-t-35 m-b-15")}>
      <div className="flex-row-reverse m-r-5 d-flex width-50">{yesterday}</div>
      <div className="progress rounded-corner d-flex flex-fill flex-row-reverse">
        <div className="progress-bar" style={{
          width: `${yesterdayPercent}%`,
          backgroundColor: "#4f5a5f",
          transition: 'all .2s ease-out',
          minWidth: '0px',
        }}
        />
      </div>
      <div className="width-80 text-center">{indicator}</div>
      <div className="progress rounded-corner d-flex flex-fill">
        <div className="progress-bar" style={{
          width: `${todayPercent}%`,
          backgroundColor: "#0f84f4",
          transition: 'all .2s ease-out',
          minWidth: '0px',
        }}
        />
      </div>
      <div className="d-flex width-50 m-l-5">{today}</div>
    </div>
  );
}

export default ReportProgressBar;
