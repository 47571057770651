import { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import $ from 'jquery'

import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import C from "../../../config/const";
import moment from "moment";
import DateTime from "react-datetime";

const MissionDetailPopup = ({ isOpen, item, onClose, callback }) => {

  const context = useContext(PageSettings);

  const [itemInfo, setItemInfo] = useState(item);

  useEffect(() => {
    if (!item.id) {
      item.end_date = moment()
      item.start_date = moment()
    }
    setItemInfo(item)
  }, [item]);

  const onItemChange = e => {
    setItemInfo(prev => {
      return {
        ...prev,
        [e.target.id]: e.target.value
      }
    })
  }

  const toggleModal = result => {
    onClose?.(result);
  }

  const onSave = () => {
    if (!$('#title').val()) {
      $('#title').focus()
      return
    }
    if (!$('#type').val()) {
      $('#type').focus()
      return
    }

    if (!itemInfo.start_date) {
      context.showAlert(strings.toast.select_start_date);
      return
    }
    if (!itemInfo.end_date) {
      context.showAlert(strings.toast.select_end_date);
      return
    }
    context.post(
      'support/mission/save',
      {
        ...itemInfo
      },
      () => {
        context.addNotification(strings.modal.alert, strings.msg.saved);
        callback()
      }
    );
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} size={'xs'}>
      <ModalHeader className="popup-title" toggle={toggleModal}>
        {itemInfo.id ? strings.support.mission_detail : strings.support.mission_create}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-md-12">
            <div className='row m-t-5'>
              <label className='col-md-2 col-form-label'>{strings.support.mission_title}</label>
              <div className="col-md-10 form-inline">
                <input type="text" className='form-control col-md-10' id="title" value={itemInfo?.title || ''} required
                       autoComplete='off' onChange={onItemChange} />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-md-2 col-form-label'>{strings.support.mission_type}</label>
              <div className="col-md-10 form-inline">
                <select id='type' className='form-control col-md-10'
                        onChange={onItemChange}
                        value={itemInfo?.type || ''}>
                  <option value={''} />
                  <option value={'STUDY'}>{strings.support.mission_type_study}</option>
                  <option value={'CABINET'}>{strings.support.mission_type_cabinet}</option>
                  <option value={'CLINIC'}>{strings.support.mission_type_clinic}</option>
                </select>
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-md-2 col-form-label'>{strings.support.start_date}</label>
              <div className="col-md-10 form-inline" style={{ zIndex: 10000 }}>
                <DateTime
                  value={itemInfo?.start_date ? moment(itemInfo?.start_date) : moment()}
                  dateFormat={C.FORMAT.DATE}
                  timeFormat={false}
                  inputProps={{ placeholder: strings.support.start_date }}
                  closeOnSelect={true}
                  required
                  onChange={(e) => {
                    onItemChange({
                      target: {
                        id: "start_date",
                        value: typeof e === 'string' ? e : e.format(C.FORMAT.DATE)
                      }
                    })
                  }} />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-md-2 col-form-label'>{strings.support.end_date}</label>
              <div className="col-md-10 form-inline" style={{ zIndex: 10000 }}>
                <DateTime
                  value={itemInfo?.end_date ? moment(itemInfo?.end_date) : moment()}
                  dateFormat={C.FORMAT.DATE}
                  timeFormat={false}
                  inputProps={{ placeholder: strings.support.end_date }}
                  closeOnSelect={true}
                  required
                  onChange={(e) => {
                    onItemChange({
                      target: {
                        id: "end_date",
                        value: typeof e === 'string' ? e : e.format(C.FORMAT.DATE)
                      }
                    })
                  }} />
              </div>
            </div>
            {
              item.id &&
              <div className='row m-t-5'>
                <label className='col-md-2 col-form-label'>{strings.common.create_date}</label>
                <label className="col-md-10 col-form-label">
                  {item.create_date || ""}
                </label>
              </div>
            }
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
        <button className="btn btn-success" onClick={onSave}>{strings.modal.save}</button>
      </ModalFooter>
    </Modal>
  );
};

export default MissionDetailPopup;
