import strings from "../../../lang/strings";
import { useContext, useRef } from "react";
import { PageSettings } from "../../../config/page-settings";
import DataTable from "../../../components/control/data-table";
import $ from "jquery";
import { PRODUCT_TYPE } from "../../../config/const";

const MemberDetailPopupPurchase = ({ user }) => {
  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();

  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const onDelete = id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'support/mission/member/' + id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  }

  return (
    <div className='row'>
      <div className='col-md-12'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'purchase_id', orderable: false,
            }, {
              targets: [columnNo++], data: 'title', orderable: false,
            }, {
              targets: [columnNo++], data: 'category', orderable: false,
              render: value => PRODUCT_TYPE.find(it => it.code == value)?.name
            }, {
              targets: [columnNo++], data: 'brand', orderable: false,
            }],
            order: [[1, 'DESC']],
            ajax: {
              url: 'shop/purchaseList/' + user.id,
              data: (req) => {
                req.column = '';
                req.keyword = '';
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.shop.title}</th>
            <th>{strings.shop.category}</th>
            <th>{strings.shop.brand}</th>
          </tr>
          </thead>
          <tbody />
        </DataTable>
      </div>
    </div>
  );
};

export default MemberDetailPopupPurchase;
