import { useContext, useEffect, useState } from "react";

import { Panel, PanelBody, PanelFooter, PanelHeader } from '../../../components/panel/panel.jsx';
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import DateTime from "react-datetime";
import moment from "moment";
import C from "../../../config/const";
import { Link } from "react-router-dom";
import ReactTags from "react-tag-autocomplete";

const RoomDetailInfo = ({ room_id }) => {

  const context = useContext(PageSettings);

  const [room, setRoom] = useState({});             // 사용자정보(승인된 정보)

  useEffect(() => {
    if (room_id > 0) {
      getRoomInfo()
    }
  }, [room_id]);

  const getRoomInfo = () => {
    context.get(
      '/room/detail/' + room_id,
      {},
      response => {
        setRoom(response.room);
      }
    );
  };

  const onRoomChange = e => {
    setRoom(prev => {
      return {
        ...prev,
        [e.target.id]: e.target.value
      }
    });
  }

  const onDelete = (i) => {
    const tag_list = room.tag.split('#')
    tag_list.splice(i, 1)
    let tag = tag_list.join('#');
    onRoomChange({ target: { id: "tag", value: tag } })
  }

  const onAddition = (tag) => {
    let tag_list = [];
    if (room.tag) {
      tag_list = room.tag.split('#')
    }
    tag_list = [].concat(tag_list, tag.name)
    onRoomChange({ target: { id: 'tag', value: tag_list.join('#') } })
  }

  const onSave = () => {
    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'room/save',
        {
          room
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
        }
      );
    });
  }

  return (
    <div>
      <Panel>
        <PanelHeader noButton={true}>{strings.room.detail}</PanelHeader>
        <PanelBody>
          <div className="row">
            <div className="col-xs-12">
              <div className='row'>
                <label className='col-xl-4 col-form-label'>UID</label>
                <label className='col-xl-8 col-form-label'>{room?.id}</label>
              </div>
              <div className='row'>
                <label className='col-xs-4 col-form-label'>{strings.room.title}</label>
                <div className="col-xs-8">
                  <input type='text' autoComplete='off' className='form-control' id="title"
                         onChange={onRoomChange}
                         value={room?.title || ''} />
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-xs-4 col-form-label'>{strings.room.type}</label>
                <div className="col-xs-8">
                  <select id='type' className='form-control'
                          onChange={onRoomChange}
                          value={room?.type || 1}>
                    <option value={1}>{strings.room.study_with_me}</option>
                    <option value={2}>{strings.room.challenge_room}</option>
                  </select>
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-xs-4 col-form-label'>{strings.room.public}</label>
                <div className="col-xs-8">
                  <select id='secret' className='form-control'
                          onChange={onRoomChange}
                          value={room?.secret || 1}>
                    <option value={1}>{strings.room.open}</option>
                    <option value={2}>{strings.room.secret}</option>
                  </select>
                </div>
              </div>
              <div className='row'>
                <label className='col-xs-4 col-form-label'>{strings.room.image}</label>
                <div className='col-xs-8'>
                  {context.getSettingList('room_images')?.map(file => (
                    <img
                      key={file}
                      src={file.getFullUrl()}
                      style={{
                        width: 'auto',
                        height: 80,
                        cursor: 'pointer',
                        boxSizing: 'content-box',
                        marginRight: 10,
                        marginTop: 10,
                        borderWidth: (room?.image && file.includes(room?.image)) ? 4 : 0,
                        borderColor: 'gray',
                        borderStyle: 'solid'
                      }}
                      onClick={() => onRoomChange({ target: { id: 'image', value: file } })} />
                  ))}
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-xs-4 col-form-label'>{strings.room.tag}</label>
                <div className="col-xs-8 mb-1" style={{ zIndex: 1003 }}>
                  <ReactTags
                    tags={room.tag ? room.tag?.split('#').map((item, index) => {
                      return { id: index, name: item }
                    }) : []}
                    placeholder={'Add new tag'}
                    delimiterChars={['Enter', 'Tab', 'Space']}
                    allowNew={true}
                    onAddition={onAddition}
                    onDelete={onDelete} />
                </div>
              </div>
              <div className='row'>
                <label className='col-xs-4 col-form-label'>{strings.room.category}</label>
                <div className="col-xs-8">
                  <select id='category' className='form-control'
                          onChange={onRoomChange}
                          value={room?.category || 1}>
                    {
                      C.ROOM_CATEGORY_LIST.map((it, idx) =>
                        <option key={idx} value={it.code}>{it.name}</option>
                      )
                    }
                  </select>
                </div>
              </div>
              <div className='row'>
                <label className='col-xs-4 col-form-label'>{strings.room.open_date}</label>
                <div className="col-xs-8" style={{ zIndex: 1000 }}>
                  <DateTime
                    value={moment(room?.open_date)}
                    dateFormat={C.FORMAT.DATE}
                    timeFormat={false}
                    inputProps={{ placeholder: strings.room.open_date }}
                    closeOnSelect={true}
                    required
                    onChange={(e) => {
                      onRoomChange({
                        target: {
                          id: "open_date",
                          value: typeof e === 'string' ? e : e.format(C.FORMAT.DATE)
                        }
                      })
                    }} />
                </div>
              </div>
              <div className='row'>
                <label className='col-xs-4 col-form-label'>{strings.room.intro}</label>
                <div className="col-xs-8">
                  <textarea className='form-control' id="intro" value={room?.intro || ''} required
                            rows={8} onChange={onRoomChange} />
                </div>
              </div>
              <div className='row'>
                <label className='col-xs-4 col-form-label'>{strings.common.create_date}</label>
                <label className='col-xs-8 col-form-label'>{room?.create_date || ''}</label>
              </div>
            </div>
          </div>
        </PanelBody>
        <PanelFooter className={"text-right"}>
          <div className="d-flex w-100">
            <div>
              <Link to="/room/list">
                <button type='button' className='btn btn-dark btn-sm m-2'>{strings.common.list}</button>
              </Link>
            </div>
            <div className="ml-auto">
              <button type='button' className='btn btn-success btn-sm m-2' onClick={() => {
                onSave()
              }}>{strings.common.save}</button>
            </div>
          </div>
        </PanelFooter>
      </Panel>
    </div>
  );
};

export default RoomDetailInfo;
