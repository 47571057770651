import { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import strings from '../../../lang/strings';

const GenderStaFragment = () => {

  const context = useContext(PageSettings);

  const [data, setData] = useState(null);
  useEffect(() => {
    context.get(
      'dashboard/gender_sta',
      {},
      response => setData(response.gender_sta)
    );
  }, []);

  return (
    <div>
      <div className="widget widget-stats bg-info height-150">
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.dashboard.signup_ratio}</div>
            <div className="ml-auto" />
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.common.male}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.male_count?.currencyFormat()}명 (${data.total_count > 0 ? (data.male_count / data.total_count * 100).toFixed(0) : 0}%, 정상: ${data.male_normal_count?.currencyFormat()}명, 휴면: ${data.male_sleep_count?.currencyFormat()})`
                  : `-`
              }
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.common.female}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.female_count?.currencyFormat()}명 (${data.total_count > 0 ? (data.female_count / data.total_count * 100).toFixed(0) : 0}%, 정상: ${data.female_normal_count?.currencyFormat()}명, 휴면: ${data.female_sleep_count?.currencyFormat()})`
                  : `-`
              }
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100 m-t-10">
            <div>{strings.dashboard.expert}{" " + strings.dashboard.signup_ratio}</div>
            <div className="ml-auto" />
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.common.male}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.expert_male_count?.currencyFormat()}명 (${data.expert_total_count > 0 ? (data.expert_male_count / data.expert_total_count * 100).toFixed(0) : 0}%, 정상: ${data.expert_male_normal_count?.currencyFormat()}명, 휴면: ${data.expert_male_sleep_count?.currencyFormat()})`
                  : `-`
              }
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.common.female}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.expert_female_count?.currencyFormat()}명 (${data.expert_total_count > 0 ? (data.expert_female_count / data.expert_total_count * 100).toFixed(0) : 0}%, 정상: ${data.expert_female_normal_count?.currencyFormat()}명, 휴면: ${data.expert_female_sleep_count?.currencyFormat()})`
                  : `-`
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenderStaFragment;
