import React, { useContext, useRef, useState } from "react";
import $ from "jquery";

import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import C from "../../config/const";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import Container from "../../components/control/container";

const StudentList = () => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.member;

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const onCell = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  };

  const onBlock = user => {
    const content = user.status == C.USER_STATUS.BLOCK ? strings.modal.unblock_confirm : strings.modal.block_confirm;
    context.showConfirm(content, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'member/block',
        {
          id: user.id,
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.applied);
          refreshTable(false);
        }
      );
    });
  };

  const onDelete = user_id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'member/' + user_id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  const [popup, setPopup] = useState({
    isOpen: false,
    user: {},
  });

  const onConvertExpert = user => {
    const content = strings.modal.convert_expert_confirm;
    context.showConfirm(content, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'member/convert',
        {
          id: user.id,
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.applied);
          refreshTable(false);
        }
      );
    });
  };

  return (
    <Container title={strings.menu.export_list}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={() => refreshTable(true)} />
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2'
                    onClick={() => refreshTable(true)}>{strings.common.search}</button>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'email', orderable: false,
              }, {
                targets: [columnNo++], data: 'profile', orderable: false,
                createdCell: (td, value) => {
                  if (value) {
                    $(td).image_lightbox(value, () => {
                      context.showGallery(value.split('#').map(it => it.getFullUrl()));
                    });
                  }
                }
              }, {
                targets: [columnNo++], data: 'name', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).nickname_label(item.name, item.gender, () => onCell(item.id));
                }
              }, {
                targets: [columnNo++], data: 'gender', orderable: false,
                render: value => value?.genderStr(),
              }, {
                targets: [columnNo++], data: 'birthday', orderable: false,
              }, {
                targets: [columnNo++], data: 'title', orderable: false,
              }, {
                targets: [columnNo++], data: 'department', orderable: false,
              }, {
                targets: [columnNo++], data: 'intro', orderable: false,
              }, {
                targets: [columnNo++], data: 'tendency', orderable: false,
              }, {
                targets: [columnNo++], data: 'time', orderable: false,
              // }, {
              //   targets: [columnNo++], data: 'point', orderable: false,
              //   render: value => value ? value.currencyFormat() : '-'
              // }, {
              //   targets: [columnNo++], data: 'score', orderable: false,
              //   render: value => value ? value.currencyFormat() : '-'
              }, {
                targets: [columnNo++], data: 'review_count', orderable: false,
                render: value => value ? value.currencyFormat() : '-'
              }, {
                targets: [columnNo++], data: 'like_count', orderable: false,
                render: value => value ? value.currencyFormat() : '-'
              }, {
                targets: [columnNo++], data: 'star_count', orderable: false,
                render: value => value ? value.currencyFormat() : '-'
              }, {
                targets: [columnNo++], data: 'star_count', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`${
                    item.review_count ?
                      String(Number(item.star_count) / Number(item.review_count)).currencyFormat() : "-"}`)
                }
              }, {
                targets: [columnNo++], data: 'signup_date', orderable: false,
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html(`<button type='button' class='btn btn-primary m-r-2 btn-sm text-nowrap'>${strings.member.convert_student}</button>`)
                    .find('button:eq(0)')
                    .on('click', () => onConvertExpert(item));
                  $(td).append(`<button type='button' class='btn btn-outline-dark btn-sm text-nowrap'>${item.status == C.USER_STATUS.BLOCK ? strings.member.unblock : strings.member.block}</button>`)
                    .find('button:eq(1)')
                    .on('click', () => onBlock(item));
                  $(td).append(`<br/><button type='button' class='btn btn-outline-danger btn-sm m-2 text-nowrap'>${strings.member.db_delete}</button>`)
                    .find('button:eq(2)')
                    .on('click', () => onDelete(item.id));
                }
              }],
              order: [[1, 'DESC']],
              ajax: {
                url: 'member/list',
                data: (req) => {
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                  req.user_type = "EXPERT";
                }
              },
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.member.id}</th>
              <th>{strings.member.profile}</th>
              <th>{strings.member.name}</th>
              <th>{strings.member.gender}</th>
              <th>{strings.member.birthday}</th>
              <th>{strings.member.title}</th>
              <th>{strings.member.department}</th>
              <th>{strings.member.introduction}</th>
              <th>{strings.member.tendency}</th>
              <th>{strings.member.time}</th>
              {/* <th>{strings.member.point}</th>
              <th>{strings.member.score}</th> */}
              <th>{strings.member.review_count}</th>
              <th>{strings.member.like_count}</th>
              <th>{strings.member.total_star_count}</th>
              <th>{strings.member.average_star_count}</th>
              <th>{strings.member.signup_date}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
    </Container>
  );

}

export default StudentList;
