import strings from "../../../lang/strings";
import { useContext, useEffect, useState } from "react";
import { PageSettings } from "../../../config/page-settings";

const TermFragment = ({ type }) => {

  const context = useContext(PageSettings);

  const [content, setContent] = useState('');
  const [link, setLink] = useState('');

  useEffect(() => {
    getTermInfo();
  }, [type]);

  const getTermInfo = () => {
    context.get(
      'setting/term',
      { type },
      response => {
        setContent(response.content);
        setLink(response.url);
      }
    );
  };

  const saveTerm = () => {
    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'setting/term',
        { type, content },
        response => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          setLink(response.url);
        }
      );
    });
  }

  return (
    <div className='row'>
      <div className='col-md-12'>
        <table className="table table-bordered">
          <thead>
          <tr className="">
            <th className="text-center" style={{ width: '50%' }}>{strings.term.preview}</th>
            <th className="text-center">
              <button type="button" className="btn btn-success btn-sm" onClick={saveTerm}>
                {strings.common.save}
              </button>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <div style={{ width: '100%', height: '600px' }}>
                <iframe src={link} title='preview' width="100%" height="600" />
              </div>
            </td>
            <td>
                <textarea
                  className="form-control bg-light"
                  style={{ height: '600px' }}
                  onChange={e => setContent(e.target.value)}
                  value={content} />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TermFragment;
