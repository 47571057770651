import { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import C from "../../../config/const";

const CloudDetailPopup = ({ isOpen, item, onClose, callback }) => {

  const context = useContext(PageSettings);
  const [itemInfo, setItemInfo] = useState(item);

  useEffect(() => {
    setItemInfo(item)
  }, [item]);

  const onItemChange = e => {
    setItemInfo(prev => {
      return {
        ...prev,
        [e.target.id]: e.target.value
      }
    })
  }

  const toggleModal = result => {
    onClose?.(result);
  }
  const onSave = () => {
    context.post(
      'cloud/cloud/save',
      {
        ...itemInfo
      },
      () => {
        context.addNotification(strings.modal.alert, strings.msg.saved);
        callback()
      }
    );
  }
  return (
    <Modal isOpen={isOpen} toggle={toggleModal} size={'xs'}>
      <ModalHeader className="popup-title" toggle={toggleModal}>
        {strings.cloud.detail_cloud_file}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-xs-12">
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.cloud.member}</label>
              <label className="col-xl-8 form-inline">
                {itemInfo?.name || ''}
              </label>
            </div>
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.cloud.title}</label>
              <div className="col-xl-8 form-inline">
                <input type="text" className='form-control' id="title" value={itemInfo?.title || ''} required
                       autoComplete='off' onChange={onItemChange} />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.cloud.type}</label>
              <div className="col-xl-8 form-inline">
                <select id='type' className='form-control col-xl-8' value={itemInfo?.type || ""}
                        onChange={onItemChange}>
                  {
                    C.CLOUD_TYPE.map((item, idx) =>
                      <option key={idx} value={item.code}>{item.name}</option>
                    )
                  }
                </select>
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.cloud.size}</label>
              <label className="col-xl-8 col-form-label">
                {itemInfo?.size || ""}
              </label>
            </div>
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.common.create_date}</label>
              <label className="col-xl-8 col-form-label">
                {itemInfo?.create_date || ""}
              </label>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
        <button className="btn btn-success" onClick={onSave}>{strings.modal.save}</button>
      </ModalFooter>
    </Modal>
  );
};

export default CloudDetailPopup;
