import React, { useContext, useRef, useState } from "react";
import $ from "jquery";

import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import C from "../../config/const";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import Container from "../../components/control/container";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import ItemDetailPopup from "./popup/item-detail-popup";

const ItemList = ({ history }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.study_item;

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const [popup, setPopup] = useState({
    isOpen: false,
    item: {},
  });

  const showPopup = (item) => setPopup(prev => {
    return { ...prev, isOpen: true, item };
  });

  const hidePopup = () => setPopup(prev => {
    return { ...prev, isOpen: false };
  });

  const onDelete = item_id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'support/item/' + item_id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  return (
    <Container title={strings.menu.support_lecture}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={() => refreshTable(true)} />
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2'
                    onClick={() => refreshTable(true)}>{strings.common.search}</button>

            <button type='button' className='btn btn-yellow btn-sm ml-md-2'
                    onClick={() => showPopup({})}>{strings.support.add_item}</button>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'title', orderable: false,
                createdCell: (td, value, item) => {
                  $(td)
                    .html(`<a href="javascript:;">${value}</a>`)
                    .find('a')
                    .on('click', () => showPopup(item));
                }
              }, {
                targets: [columnNo++], data: 'url', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).video_label(value, () => {
                    showPopup(item)
                  });
                }
              }, {
                targets: [columnNo++], data: 'company', orderable: false,
              }, {
                targets: [columnNo++], data: 'code', orderable: false,
              }, {
                targets: [columnNo++], data: 'subject', orderable: false,
              }, {
                targets: [columnNo++], data: 'speaker', orderable: false,
              }, {
                targets: [columnNo++], data: 'grade', orderable: false,
                render: value => C.GRADE.find(it => it.code == value)?.name || ""
              }, {
                targets: [columnNo++], data: 'create_date', orderable: false,
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html('');
                  $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.common.db_delete}</button`)
                    .find('button:last')
                    .on('click', function () {
                      onDelete(item.id);
                    });
                }
              }],
              order: [[1, 'DESC']],
              ajax: {
                url: 'support/itemList',
                data: (req) => {
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                }
              },
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.support.title}</th>
              <th>{strings.support.item_file}</th>
              <th>{strings.support.company}</th>
              <th>{strings.support.code}</th>
              <th>{strings.support.item_subject}</th>
              <th>{strings.support.item_speaker}</th>
              <th>{strings.support.item_grade}</th>
              <th>{strings.common.create_date}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
        <ItemDetailPopup {...popup} onClose={() => {
          hidePopup();
        }} callback={() => {
          refreshTable(true)
          hidePopup();
        }} />
      </div>
    </Container>
  );

}

export default withRouter(inject('rootStore')(ItemList));
