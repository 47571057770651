import React, { useContext, useRef, useState } from "react";
import $ from "jquery";

import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import DataTable from "../../components/control/data-table";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import Container from "../../components/control/container";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import { PRODUCT_TYPE } from "../../config/const";
import ProductDetailPopup from "./popup/product-detail-popup";
import moment from "moment";

const ProductList = ({ history }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.product;

  const [popup, setPopup] = useState({
    isOpen: false,
    item: {},
  });

  const showPopup = (item) => setPopup(prev => {
    return { ...prev, isOpen: true, item };
  });

  const hidePopup = () => setPopup(prev => {
    return { ...prev, isOpen: false };
  });

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const onDelete = product_id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'shop/product/' + product_id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  return (
    <Container title={strings.menu.product_list}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={() => refreshTable(true)} />
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2'
                    onClick={() => refreshTable(true)}>{strings.common.search}</button>
            <button type='button' className='btn btn-yellow btn-sm ml-md-2'
                    onClick={() => showPopup({})}>{strings.shop.add_product}</button>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [
                {
                  targets: [columnNo++], data: 'id', orderable: false,
                },
                {
                  targets: [columnNo++], data: 'category', orderable: false,
                  render: value => PRODUCT_TYPE.find(it => it.code == value)?.name || ""
                },
                {
                  targets: [columnNo++], data: 'title', orderable: false,
                  createdCell: (td, value, item) => {
                    $(td)
                      .html(`<a href="javascript:;">${value}</a>`)
                      .find('a')
                      .on('click', () => showPopup(item));
                  }
                },
                {
                  targets: [columnNo++], data: 'brand', orderable: false,
                },
                {
                  targets: [columnNo++], data: 'image', orderable: false,
                  createdCell: (td, value) => {
                    $(td).image_lightbox(value, () => {
                      context.showGallery(value.split('#').map(it => it.getFullUrl()));
                    });
                  }
                },
                {
                  targets: [columnNo++], data: 'brand_image', orderable: false,
                  createdCell: (td, value) => {
                    $(td).image_lightbox(value, () => {
                      context.showGallery(value.split('#').map(it => it.getFullUrl()));
                    });
                  }
                },
                {
                  targets: [columnNo++], data: 'point', orderable: false,
                },
                {
                  targets: [columnNo++], data: 'content', orderable: false, width: "50%"
                },
                {
                  targets: [columnNo++], data: 'status', orderable: false,
                  render: value => value == 1 ? strings.shop.sell : strings.shop.not_sell
                },
                {
                  targets: [columnNo++], data: 'create_date', orderable: false,
                  render: value => moment(value).format("YYYY-MM-DD")
                },
                {
                  targets: [columnNo++], data: 'id', orderable: false,
                  createdCell: function (td, value, item) {
                    $(td).html('');
                    $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.common.delete}</button`)
                      .find('button:last')
                      .on('click', function () {
                        onDelete(item.id)
                      });
                  }
                }
              ],
              order: [[1, 'DESC']],
              ajax: {
                url: 'shop/productList',
                data: function (req) {
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                }
              },
              drawCallback: function () {
              },
              fnCreatedRow: function (row, data, dataInd) {
              }
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.shop.category}</th>
              <th>{strings.shop.title}</th>
              <th>{strings.shop.brand}</th>
              <th>{strings.shop.image}</th>
              <th>{strings.shop.brand_image}</th>
              <th>{strings.shop.point}</th>
              <th>{strings.shop.content}</th>
              <th>{strings.shop.status}</th>
              <th>{strings.common.create_date}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
      <ProductDetailPopup {...popup} onClose={() => {
        hidePopup();
      }} callback={() => {
        refreshTable(true)
        hidePopup();
      }} />
    </Container>
  );

}

export default withRouter(inject('rootStore')(ProductList));
