import React, { useContext, useRef, useState } from "react";
import $ from "jquery";

import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import C from "../../config/const";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import Container from "../../components/control/container";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import CloudDetailPopup from "./popup/cloud-detail-popup";

const CloudList = ({ history }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.cloud;

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const [popup, setPopup] = useState({
    isOpen: false,
    material_id: 0,
  });

  const showPopup = (material_id) => setPopup(prev => {
    return { ...prev, isOpen: true, material_id };
  });

  const hidePopup = () => setPopup(prev => {
    return { ...prev, isOpen: false };
  });

  const [detailPopup, setDetailPopup] = useState({
    isOpen: false,
    material: {},
  });

  const showDetailPopup = (item) => setDetailPopup(prev => {
    return { ...prev, isOpen: true, item };
  });

  const hideDetailPopup = () => setDetailPopup(prev => {
    return { ...prev, isOpen: false };
  });

  const onDelete = cloud_id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'cloud/cloud/' + cloud_id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  const onDownload = id => {
    context.download(
      "GET",
      '/cloud/download',
      { id },
      response => {
      }
    );
  };

  const onCell = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  };

  return (
    <Container title={strings.menu.cloud_all}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={() => refreshTable(true)} />
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2'
                    onClick={() => refreshTable(true)}>{strings.common.search}</button>

            {/* <button type='button' className='btn btn-yellow btn-sm ml-md-2'
                    onClick={() => showPopup(null)}>{strings.cloud.add_files}</button>*/}
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [{
                targets: [columnNo++], data: 'id', orderable: false,
              }, {
                targets: [columnNo++], data: 'name', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).nickname_label(item.name, item.gender, () => onCell(item.member));
                }
              }, {
                targets: [columnNo++], data: 'title', orderable: false,
                createdCell: (td, value, item) => {
                  $(td)
                    .html(`<a href="javascript:;">${value}</a>`)
                    .find('a')
                    .on('click', () => onDownload(item.id));
                }
              }, {
                targets: [columnNo++], data: 'type', orderable: false,
                render: value => C.CLOUD_TYPE.find(item => item.code == value)?.name || ""
              }, {
                targets: [columnNo++], data: 'size', orderable: false,
                render: value => (Number(value) / 1024 / 1024).toFixed(3) + "MB"
              }, {
                targets: [columnNo++], data: 'create_date', orderable: false,
              }, {
                targets: [columnNo++], data: 'id', orderable: false,
                createdCell: (td, value, item) => {
                  $(td).html('');
                  $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.common.db_delete}</button`)
                    .find('button:last')
                    .on('click', function () {
                      onDelete(item.id);
                    });
                }
              }],
              order: [[1, 'DESC']],
              ajax: {
                url: 'cloud/cloudList',
                data: (req) => {
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                }
              },
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.cloud.member}</th>
              <th>{strings.cloud.title}</th>
              <th>{strings.cloud.type}</th>
              <th>{strings.cloud.size}</th>
              <th>{strings.common.create_date}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
        <CloudDetailPopup {...detailPopup} onClose={() => {
          hideDetailPopup();
        }} callback={() => {
          refreshTable(false)
          hideDetailPopup();
        }} />
      </div>
    </Container>
  );

}

export default withRouter(inject('rootStore')(CloudList));
