import React, { useContext, useEffect, useRef, useState } from "react";
import $ from "jquery";

import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import C from "../../config/const";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import DataTable from "../../components/control/data-table";
import moment from "moment";
import { Panel } from "../../components/panel/panel";
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";

const RoomList = ({ history }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.room;

  const [type, setType] = useState(1);
  const typeRef = useRef(type);

  useEffect(() => {
    typeRef.current = type;
    refreshTable(true);
  }, [type])

  const showRoomDetail = (id) => {
    history.push(`/room/detail/${id}`);
  }

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const onCell = user_id => {
    context.showUserPopup(user_id, () => refreshTable(false));
  };

  const onDelete = room_id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'room/' + room_id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  const onBlock = room => {
    const content = room.status == C.USER_STATUS.BLOCK ? strings.modal.room_unblock_confirm : strings.modal.room_block_confirm;
    context.showConfirm(content, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'room/block',
        {
          id: room.id,
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.applied);
          refreshTable(false);
        }
      );
    });
  };

  return (
    <div className="row">
      <div className="col-md-12  mt-3">
        <Panel title={type == C.ROOM_TYPE.STUDY ? strings.menu.room_study : strings.menu.room_challenge}>
          <Nav tabs className="nav-tabs-inverse">
            <NavItem>
              <NavLink className={classnames({ active: type === C.ROOM_TYPE.STUDY })} onClick={() => {
                setType(C.ROOM_TYPE.STUDY)
              }}>
                <span className="d-none d-xs-inline">{strings.menu.room_study}</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({ active: type === C.ROOM_TYPE.CHALLENGE })} onClick={() => {
                setType(C.ROOM_TYPE.CHALLENGE)
              }}>
                <span className="d-none d-xs-inline">{strings.menu.room_challenge}</span>
              </NavLink>
            </NavItem>
          </Nav>
          <div className='col-md-12  m-t-20'>
            <div className='form-horizontal form-inline'>
              <SearchSelect
                id='searchColumn'
                value={searchOptions[0].value}
                data={searchOptions}
                onChange={() => refreshTable(true)} />
              <SearchInput
                id='searchInput'
                action={() => refreshTable(true)} />
              <button type='button' className='btn btn-success btn-sm ml-md-2'
                      onClick={() => refreshTable(true)}>{strings.common.search}</button>
            </div>
          </div>
          <div className='col-md-12 mt-3'>
            <DataTable
              ref={tableRef}
              options={{
                columnDefs: [{
                  targets: [columnNo++], data: 'id', orderable: false,
                }, {
                  targets: [columnNo++], data: 'title', orderable: false,
                  createdCell: (td, value, item) => {
                    $(td)
                      .html(`<a href="javascript:;">${value}</a>`)
                      .find('a')
                      .on('click', () => showRoomDetail(item.id));
                  }
                }, {
                  targets: [columnNo++], data: 'name', orderable: false,
                  createdCell: (td, value, item) => {
                    $(td).nickname_label(item.name, item.gender, () => onCell(item.user_id));
                  }
                }, {
                  targets: [columnNo++], data: 'secret', orderable: false,
                  render: value => value == 1 ? strings.room.open : strings.room.secret
                }, {
                  targets: [columnNo++], data: 'image', orderable: false,
                  createdCell: (td, value) => {
                    $(td).image_lightbox(value, () => {
                      context.showGallery(value.split('#').map(it => it.getFullUrl()));
                    });
                  }
                }, {
                  targets: [columnNo++], data: 'tag', orderable: false,
                }, {
                  targets: [columnNo++], data: 'category', orderable: false,
                  render: value => C.ROOM_CATEGORY_LIST.find(it => it.code == value).name
                }, {
                  targets: [columnNo++], data: 'intro', orderable: false,
                }, {
                  targets: [columnNo++], data: 'chat_count', orderable: false,
                }, {
                  targets: [columnNo++], data: 'member_count', orderable: false,
                }, {
                  targets: [columnNo++], data: 'total_time', orderable: false,
                  render: value => Number(value) + strings.room.min
                }, {
                  targets: [columnNo++], data: 'create_date', orderable: false,
                  render: value => moment(value).format(C.FORMAT.DATE)
                }, {
                  targets: [columnNo++], data: 'open_date', orderable: false,
                  render: value => moment(value).format(C.FORMAT.DATE)
                }, {
                  targets: [columnNo++], data: 'id', orderable: false,
                  createdCell: (td, value, item) => {
                    $(td).html(`<button type='button' class='btn btn-outline-danger btn-sm m-2 text-nowrap'>${strings.common.db_delete}</button>`)
                      .find('button:eq(0)')
                      .on('click', () => onDelete(item.id));
                    $(td).append(`<button type='button' class='btn btn-outline-dark btn-sm text-nowrap'>${item.status == C.USER_STATUS.BLOCK ? strings.room.unblock : strings.room.block}</button>`)
                      .find('button:eq(1)')
                      .on('click', () => onBlock(item));
                  }
                }],
                order: [[1, 'DESC']],
                ajax: {
                  url: 'room/list',
                  data: (req) => {
                    req.column = $('#searchColumn').val();
                    req.keyword = $('#searchInput').val();
                    req.type = typeRef.current;
                  }
                },
              }}>
              <thead>
              <tr>
                <th>#</th>
                <th>{strings.room.title}</th>
                <th>{strings.room.name}</th>
                <th>{strings.room.public}</th>
                <th>{strings.room.image}</th>
                <th>{strings.room.tag}</th>
                <th>{strings.room.category}</th>
                <th>{strings.room.intro}</th>
                <th>{strings.room.chat_count}</th>
                <th>{strings.room.member_count}</th>
                <th>{strings.room.total_time}</th>
                <th>{strings.common.create_date}</th>
                <th>{strings.room.open_date}</th>
                <th>{strings.common.manage}</th>
              </tr>
              </thead>
              <tbody />
            </DataTable>
          </div>
        </Panel>
      </div>
    </div>
  );

}

export default withRouter(inject('rootStore')(RoomList));
