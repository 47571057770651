import { useContext } from "react";
import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";

const TagTodoDetail = ({ todo_info, onChange, getTodoList }) => {

  const context = useContext(PageSettings);

  const onDelete = () => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'planner/todo/' + todo_info.id,
        {},
        () => {
          getTodoList()
          context.addNotification(strings.modal.alert, strings.msg.deleted);
        }
      );
    });
  }

  return (
    <div>
      <div>
        <button type='button' className='btn btn-dark btn-sm m-2' onClick={() => {
          onDelete()
        }}>{strings.planner.delete_todo}</button>
      </div>
      <div className='row m-t-5'>
        <label className='col-xl-2 col-form-label'>UID</label>
        <label className='col-xl-10 col-form-label'>{todo_info?.id}</label>
      </div>
      <div className='row m-t-5'>
        <label className='col-xl-2 col-form-label'>{strings.planner.todo_title}</label>
        <div className="col-xl-10">
          <input type='text' autoComplete='off' className='form-control' id="title"
                 value={todo_info?.title || ''} onChange={onChange} />
        </div>
      </div>
      <div className='row m-t-5'>
        <label className='col-xl-2 col-form-label'>{strings.planner.todo_status}</label>
        <div className="col-xl-10">
          <button className="btn btn-sm btn-primary m-r-5 col-xl-2" onClick={() => {
            onChange({ target: { id: "status", value: todo_info.status == "Y" ? "N" : "Y" } })
          }}>
            {todo_info.status == "Y" ? strings.planner.todo_done : strings.planner.todo_doing}
          </button>
        </div>
      </div>
      <div className='row m-t-5'>
        <label className='col-xl-2 col-form-label'>{strings.planner.status_date}</label>
        <label className='col-xl-10 col-form-label'>{todo_info?.status_date || ''}</label>
      </div>
      <div className='row m-t-5'>
        <label className='col-xl-2 col-form-label'>{strings.common.create_date}</label>
        <label className='col-xl-10 col-form-label'>{todo_info?.create_date || ''}</label>
      </div>
    </div>
  );
};

export default TagTodoDetail;
