import strings from "../../../lang/strings";
import { useContext, useRef } from "react";
import { PageSettings } from "../../../config/page-settings";
import DataTable from "../../../components/control/data-table";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";

const MemberDetailPopupTodo = ({ user, onClose, history }) => {
  const context = useContext(PageSettings);

  let columnNo = 0;
  const tableRef = useRef();

  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const showTagDetail = (id) => {
    onClose()
    history.push(`/tag/detail/${id}`);
  }

  const onDelete = tag_id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'planner/tag/' + tag_id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  return (
    <div className='row'>
      <div className='col-md-12'>
        <DataTable
          ref={tableRef}
          options={{
            columnDefs: [{
              targets: [columnNo++], data: 'id', orderable: false,
            }, {
              targets: [columnNo++], data: 'tag', orderable: false,
            }, {
              targets: [columnNo++], data: 'title', orderable: false,
            }, {
              targets: [columnNo++], data: 'status', orderable: false,
              render: value => value == "Y" ? strings.planner.todo_done : strings.planner.todo_doing
            }, {
              targets: [columnNo++], data: 'status_date', orderable: false,
            }, {
              targets: [columnNo++], data: 'create_date', orderable: false,
            }, {
              targets: [columnNo++], data: 'id', orderable: false,
              createdCell: (td, value, item) => {
                $(td).html(`<button type='button' class='btn btn-outline-danger btn-sm m-2 text-nowrap'>${strings.common.db_delete}</button>`)
                  .find('button:eq(0)')
                  .on('click', () => onDelete(item.id));
              }
            }],
            order: [[1, 'DESC']],
            ajax: {
              url: 'planner/userTodo/list/' + user.id,
              data: (req) => {
                req.column = '';
                req.keyword = '';
              }
            },
          }}>
          <thead>
          <tr>
            <th>#</th>
            <th>{strings.planner.tag_id}</th>
            <th>{strings.planner.todo_title}</th>
            <th>{strings.planner.todo_status}</th>
            <th>{strings.planner.status_date}</th>
            <th>{strings.common.create_date}</th>
            <th>{strings.common.manage}</th>
          </tr>
          </thead>
          <tbody />
        </DataTable>
      </div>
    </div>
  );
};

export default withRouter(inject('rootStore')(MemberDetailPopupTodo));
