import { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import strings from '../../../lang/strings';
import Container from "../../../components/control/container";

const RoomMemberStaFragment = () => {

  const context = useContext(PageSettings);

  const [data, setData] = useState(null);
  useEffect(() => {
    context.get(
      'dashboard/room_member_sta',
      {},
      response => setData(response.room_member_sta)
    );
  }, []);

  return (
    <Container title={strings.dashboard.room_open_ratio}>
      <div>
        <div className="d-flex align-items-center">
          <div className="d-flex w-100">
            <div>{strings.dashboard.today} {strings.dashboard.room_open_ratio}</div>
            <div className="ml-auto">
              {data?.total_member_count > 0 ? `${(data.today / data.total_member_count).toFixed(2)}` : `-`}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex w-100">
            <div>{strings.dashboard.this_week} {strings.dashboard.room_open_ratio}</div>
            <div className="ml-auto">
              {data?.total_member_count > 0 ? `${(data.this_week / data.total_member_count).toFixed(2)}` : `-`}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex w-100">
            <div>{strings.dashboard.this_month} {strings.dashboard.room_open_ratio}</div>
            <div className="ml-auto">
              {data?.total_member_count > 0 ? `${(data.this_month / data.total_member_count).toFixed(2)}` : `-`}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex w-100">
            <div>{strings.dashboard.this_year} {strings.dashboard.room_open_ratio}</div>
            <div className="ml-auto">
              {data?.total_member_count > 0 ? `${(data.today / data.total_member_count).toFixed(2)}` : `-`}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default RoomMemberStaFragment;
