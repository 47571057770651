import { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import strings from '../../../lang/strings';

const GradeStaFragment = () => {

  const context = useContext(PageSettings);

  const [data, setData] = useState(null);
  useEffect(() => {
    context.get(
      'dashboard/grade_sta',
      {},
      response => setData(response.grade_sta)
    );
  }, []);

  return (
    <div>
      <div className="widget widget-stats bg-orange height-150" style={{ height: 120 }}>
        <div className="d-flex align-items-center text-white m-t-5">
          <div className="d-flex w-100">
            <div>{strings.dashboard.grade_ratio}</div>
            <div className="ml-auto" />
          </div>
        </div>
        <div className="d-flex align-items-center text-white m-t-5">
          <div className="d-flex w-100">
            <div>{strings.dashboard.grade1}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.grade1_count.currencyFormat()}명 (${data.total_count > 0 ? (data.grade1_count / data.total_count * 100).toFixed(0) : 0}%, 정상: ${data.grade1_normal_count.currencyFormat()}명, 휴면: ${data.grade1_sleep_count.currencyFormat()})`
                  : `-`
              }
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white m-t-5">
          <div className="d-flex w-100">
            <div>{strings.dashboard.grade2}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.grade2_count.currencyFormat()}명 (${data.total_count > 0 ? (data.grade2_count / data.total_count * 100).toFixed(0) : 0}%, 정상: ${data.grade2_normal_count.currencyFormat()}명, 휴면: ${data.grade2_sleep_count.currencyFormat()})`
                  : `-`
              }
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white m-t-5">
          <div className="d-flex w-100">
            <div>{strings.dashboard.grade3}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.grade3_count.currencyFormat()}명 (${data.total_count > 0 ? (data.grade3_count / data.total_count * 100).toFixed(0) : 0}%, 정상: ${data.grade3_normal_count.currencyFormat()}명, 휴면: ${data.grade3_sleep_count.currencyFormat()})`
                  : `-`
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GradeStaFragment;
