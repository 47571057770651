import { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import strings from '../../../lang/strings';

const AgeGroupStaFragment = () => {

  const context = useContext(PageSettings);

  const [data, setData] = useState(null);
  useEffect(() => {
    context.get(
      'dashboard/age_group_sta',
      {},
      response => setData(response.age_group_sta)
    );
  }, []);

  return (
    <div>
      <div className="widget widget-stats bg-red height-150">
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.dashboard.expert}{" " + strings.dashboard.age_group_ratio}</div>
            <div className="ml-auto" />
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.dashboard.ten_group}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.ten_count?.currencyFormat()}명 (${data.total_count > 0 ? (data.ten_count / data.total_count * 100).toFixed(0) : 0}%, 정상: ${data.ten_normal_count?.currencyFormat()}명, 휴면: ${data.ten_sleep_count?.currencyFormat()})`
                  : `-`
              }
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.dashboard.twenty_group}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.twenty_count?.currencyFormat()}명 (${data.total_count > 0 ? (data.twenty_count / data.total_count * 100).toFixed(0) : 0}%, 정상: ${data.twenty_normal_count?.currencyFormat()}명, 휴면: ${data.twenty_sleep_count?.currencyFormat()})`
                  : `-`
              }
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.dashboard.thirty_group}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.thirty_count?.currencyFormat()}명 (${data.total_count > 0 ? (data.thirty_count / data.total_count * 100).toFixed(0) : 0}%, 정상: ${data.thirty_normal_count?.currencyFormat()}명, 휴면: ${data.thirty_sleep_count?.currencyFormat()})`
                  : `-`
              }
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.dashboard.forty_group}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.forty_count?.currencyFormat()}명 (${data.total_count > 0 ? (data.forty_count / data.total_count * 100).toFixed(0) : 0}%, 정상: ${data.forty_normal_count?.currencyFormat()}명, 휴면: ${data.forty_sleep_count?.currencyFormat()})`
                  : `-`
              }
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.dashboard.fifty_group}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.fifty_count?.currencyFormat()}명 (${data.total_count > 0 ? (data.fifty_count / data.total_count * 100).toFixed(0) : 0}%, 정상: ${data.fifty_normal_count?.currencyFormat()}명, 휴면: ${data.fifty_sleep_count?.currencyFormat()})`
                  : `-`
              }
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-white">
          <div className="d-flex w-100">
            <div>{strings.dashboard.sixty_group}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.sixty_count?.currencyFormat()}명 (${data.total_count > 0 ? (data.sixty_count / data.total_count * 100).toFixed(0) : 0}%, 정상: ${data.sixty_normal_count?.currencyFormat()}명, 휴면: ${data.sixty_sleep_count?.currencyFormat()})`
                  : `-`
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgeGroupStaFragment;
