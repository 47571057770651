import { useContext, useEffect, useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

import { PageSettings } from "../../config/page-settings";
import { Panel, PanelBody, PanelFooter } from "../../components/panel/panel";
import classnames from "classnames";
import QuestionDetailInfo from "./fragment/question-detail-info";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import QuestionAnswerDetail from "./fragment/question-answer-detail";
import AnswerCreatePopup from "./popup/answer-create-popup";
import strings from "../../lang/strings";

const QuestionDetail = ({ question_id, history }) => {

  const context = useContext(PageSettings);

  const [activeTab, setActiveTab] = useState('1');

  const [answerList, setAnswerList] = useState([]);

  const [popup, setPopup] = useState({
    isOpen: false,
    question_id: 0,
  });

  const showPopup = () => setPopup(prev => {
    return { ...prev, isOpen: true, question_id };
  });

  const hidePopup = () => setPopup(prev => {
    return { ...prev, isOpen: false };
  });

  useEffect(() => {
    if (question_id > 0) {
      getAnswerList()
    }
  }, [question_id]);

  const getAnswerList = () => {
    context.get(
      '/support/answer/list/' + question_id,
      {},
      response => {
        setAnswerList(response.answer_list);
      }
    );
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <div className="row">
      <div className="col-xl-6">
        <QuestionDetailInfo question_id={question_id} onSave={() => {
          if (question_id < 1) {
            history.goBack();
          }
        }} />
      </div>
      {
        question_id > 0 &&
        <div className="col-xl-6">
          <Panel>
            <Nav tabs className="nav-tabs-inverse">
              {
                answerList.filter(it => it.content != "").length > 0 ?
                  answerList.filter(it => it.content != "").map((item, idx) =>
                    <NavItem key={idx}>
                      <NavLink className={classnames({ active: activeTab == idx + 1 })} onClick={() => {
                        toggle(idx + 1);
                      }}>
                        <span className="d-none d-xs-inline">답변{idx + 1}</span>
                      </NavLink>
                    </NavItem>
                  )
                  :
                  null
              }
            </Nav>
            <PanelBody>
              {
                answerList.filter(it => it.content != "")[Number(activeTab) - 1] &&
                <QuestionAnswerDetail answer_info={answerList.filter(it => it.content != "")[Number(activeTab) - 1]}
                                      getAnswerList={getAnswerList} />
              }
            </PanelBody>
            {
              answerList.length < 1 &&
              <PanelFooter className={"text-right"}>
                <button type='button' className='btn btn-yellow btn-sm m-2' onClick={() => {
                  showPopup()
                }}>{strings.support.add_answer}</button>
                <div className="d-flex w-100">
                </div>
              </PanelFooter>
            }

          </Panel>
        </div>
      }
      <AnswerCreatePopup {...popup} onClose={() => {
        hidePopup();
      }} callback={getAnswerList} />
    </div>
  );
};

export default withRouter(inject('rootStore')(QuestionDetail));
