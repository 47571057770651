import { useContext, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import $ from 'jquery'

import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import C from "../../../config/const";

const TagCreatePopup = ({ isOpen, planner_id, onClose, callback }) => {

  const context = useContext(PageSettings);
  const [color, setColor] = useState('');

  const toggleModal = result => {
    onClose?.(result);
  }

  const onSave = () => {
    if (!$('#tag_title').val() || !$('#tag_color').val()) {
      return
    }

    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'planner/tag/save',
        {
          tag: {
            planner: planner_id,
            title: $('#tag_title').val(),
            color: $('#tag_color').val()
          }
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          callback()
        }
      );
    });
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} size={'xs'}>
      <ModalHeader className="popup-title" toggle={toggleModal}>
        {strings.planner.detail}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-xs-12">
            <div className='row'>
              <label className='col-xl-4 col-form-label'>{strings.planner.tag_title}</label>
              <div className="col-xl-8">
                <input type='text' autoComplete='off' className='form-control' id="tag_title" />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.planner.color}</label>
              <div className="col-xl-8 form-inline">
                <select id='tag_color' className='form-control col-xl-6 m-r-10'
                        onChange={(e) => setColor(e.target.value)}>
                  <option value="" />
                  {
                    C.colorList.map((item, idx) =>
                      <option key={idx} value={item}>{item}</option>
                    )
                  }
                </select>
                <div
                  style={{
                    backgroundColor: color,
                    height: 30,
                    width: 70,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'center'
                  }}>{color}</div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
        <button className="btn btn-success" onClick={onSave}>{strings.modal.save}</button>
      </ModalFooter>
    </Modal>
  );
};

export default TagCreatePopup;
