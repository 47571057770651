import { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import C from "../../../config/const";

const SubsectionDetailPopup = ({ isOpen, item, onClose, callback }) => {

  const context = useContext(PageSettings);

  const [subsectionFile, setSubsectionFile] = useState(null);

  let fileName = "";
  let fileUrl = null;

  const [itemInfo, setItemInfo] = useState(item);

  useEffect(() => {
    setItemInfo(item)

    setSubsectionFile(null);
  }, [item]);

  const onItemChange = e => {
    setItemInfo(prev => {
      return {
        ...prev,
        [e.target.id]: e.target.value
      }
    })
  }

  const toggleModal = result => {
    onClose?.(result);
  }

  const uploadFile = () => {
    if (!item.id) {
      if (subsectionFile == null) {
        return;
      }
    }

    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      if (subsectionFile == null) {
        onSave()
      } else {
        let formData = new FormData();
        formData.append('file', subsectionFile, subsectionFile.name);

        context.post(
          'upload/aifile/' + item.code,
          formData,
          function (response) {
            fileUrl = response.file
            onSave()
          },
          true
        );
      }

    });
  }

  const onSave = () => {
    context.post(
      'ai/subsection/save',
      {
        ...itemInfo,
      },
      () => {
        context.addNotification(strings.modal.alert, strings.msg.saved);
        setSubsectionFile(null);
        fileName = "";
        callback()
      }
    );
  }

  const onFileChange = (e) => {
    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      setSubsectionFile(file);
    };
    reader.readAsDataURL(file);
  };

  const onFileDelete = () => {
    setSubsectionFile(null);
  }

  const onSubmit = e => {
    e.preventDefault();

    uploadFile()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} size={'xl'}>
      <form onSubmit={onSubmit}>
        <ModalHeader className="popup-title" toggle={toggleModal}>
          {item ? strings.ai.subsection_detail : strings.ai.add_subsection}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className='row m-t-5'>
                <label className='col-md-2 col-form-label'>{strings.ai.code}</label>
                <div className="col-md-10 form-inline">
                  <input type="text" className='form-control col-md-10' id="code" value={itemInfo?.code || ''}
                    required
                    autoComplete='off' onChange={onItemChange} />
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-md-2 col-form-label'>{strings.ai.name}</label>
                <div className="col-md-10 form-inline">
                  <input type="text" className='form-control col-md-10' id="name" value={itemInfo?.name || ''}
                    required
                    autoComplete='off' onChange={onItemChange} />
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-md-2 col-form-label'>{strings.ai.link}</label>
                <div className="col-md-10 form-inline">
                  <input type="text" className='form-control col-md-10' id="url" value={itemInfo?.url || ''}
                    required
                    autoComplete='off' onChange={onItemChange} />
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-md-2 col-form-label'>{strings.ai.file}</label>
                <div className="col-md-10">
                  <div className='row'>
                    <input type={"file"} className='hidden' id={"file"} accept={"application/pdf"} onChange={onFileChange} />
                  </div>
                </div>
              </div>
              {
                item.id &&
                <div className='row m-t-5'>
                  <label className='col-md-2 col-form-label'>{strings.common.create_date}</label>
                  <label className="col-md-10 col-form-label">
                    {item.create_date || ""}
                  </label>
                </div>
              }
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-default" type='button' onClick={toggleModal}>{strings.modal.close}</button>
          <button className="btn btn-success" type='submit'>{strings.modal.save}</button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default SubsectionDetailPopup;
