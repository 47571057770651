import { useContext, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";

const TodoDetailPopup = ({ isOpen, id, onClose, callback }) => {

  const context = useContext(PageSettings);

  const [todo, setTodo] = useState({});             // 사용자정보(승인된 정보)

  const getTodoInfo = () => {
    context.get(
      '/planner/todo/detail/' + id,
      {},
      response => {
        setTodo(response.todo);
      }
    );
  };

  const toggleModal = result => {
    onClose?.(result);
  }

  const onSave = () => {
    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'planner/todo/save',
        {
          ...todo
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          callback?.();
        }
      );
    });
  }

  const onTodoChange = e => {
    setTodo(prev => {
      return {
        ...prev,
        [e.target.id]: e.target.value
      }
    });
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} onOpened={() => getTodoInfo()} size={'xl'}>
      <ModalHeader className="popup-title" toggle={toggleModal}>
        {strings.planner.todo_detail} ({todo?.title})
      </ModalHeader>
      <ModalBody>
        <div className="row" style={{ maxHeight: 'calc(100vh - 220px)', overflowX: 'hidden', overflowY: 'auto' }}>
          <div className='row'>
            <label className='col-xs-4 col-form-label'>UID</label>
            <label className='col-xs-8 col-form-label'>{todo?.id}</label>
          </div>
          <div className='row'>
            <label className='col-xs-4 col-form-label'>{strings.planner.todo_title}</label>
            <div className="col-xs-8">
              <input type='text' autoComplete='off' className='form-control' id="title"
                     onChange={onTodoChange}
                     value={todo?.title || ''} />
            </div>
          </div>
          <div className='row m-t-5'>
            <label className='col-xl-4 col-form-label'>{strings.planner.todo_status}</label>
            <div className="col-xl-8">
              <button className="btn btn-sm btn-primary m-r-5 col-xl-2" onClick={() => {
                onTodoChange({ target: { id: "status", value: todo.status == "Y" ? "N" : "Y" } })
              }}>
                {todo.status == "Y" ? strings.planner.todo_done : strings.planner.todo_doing}
              </button>
            </div>
          </div>
          <div className='row'>
            <label className='col-xs-4 col-form-label'>{strings.planner.status_date}</label>
            <label className='col-xs-8 col-form-label'>{todo?.status_date || ''}</label>
          </div>
          <div className='row'>
            <label className='col-xs-4 col-form-label'>{strings.common.create_date}</label>
            <label className='col-xs-8 col-form-label'>{todo?.create_date || ''}</label>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
        <button className="btn btn-success" onClick={onSave}>{strings.modal.apply}</button>
      </ModalFooter>
    </Modal>
  );
};

export default TodoDetailPopup;
