import { useContext, useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import C from "../../../config/const";
import ReactPlayer from "react-player";

const ItemDetailPopup = ({ isOpen, item, onClose, callback }) => {

  const context = useContext(PageSettings);
  const fileRef = useRef();
  const [files, setFiles] = useState([]);
  let fileName = "";
  let url = item.url || "";

  const [itemInfo, setItemInfo] = useState(item);

  useEffect(() => {
    setItemInfo(item)
    url = item.url
  }, [item]);

  const onItemChange = e => {
    setItemInfo(prev => {
      return {
        ...prev,
        [e.target.id]: e.target.value
      }
    })
  }

  const toggleModal = result => {
    onClose?.(result);
  }

  const uploadFile = () => {
    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      if (files.length == 0) {
        onSave()
        return;
      }

      let formData = new FormData();

      files.forEach((entry) => {
        formData.append("file", entry, entry.name)
      })

      context.post(
        'upload/video/item',
        formData,
        function (response) {
          url = response.video
          onSave();
        },
        true
      );
    });
  }

  const onSave = () => {
    context.post(
      'support/item/save',
      {
        ...itemInfo,
        url
      },
      () => {
        context.addNotification(strings.modal.alert, strings.msg.saved);
        setFiles([]);
        fileName = "";
        callback()
      }
    );
  }

  const onFileChange = (e) => {
    if (e.target.files.length < 1) {
      return;
    }

    let fileList = []
    for (let entry in e.target.files) {
      if (typeof e.target.files[entry] == "object") {
        fileList.push(e.target.files[entry])
      }
    }
    setFiles(fileList)
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} size={'xl'}>
      <ModalHeader className="popup-title" toggle={toggleModal}>
        {item ? strings.support.item_detail : strings.support.add_item}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-md-12">
            <div className='row m-t-5'>
              <label className='col-md-2 col-form-label'>{strings.support.file}</label>
              <div className="col-md-10 form-inline">
                <input type={"file"} className='hidden' ref={fileRef} id={"file"}
                       accept={"video/*"} onChange={onFileChange} />
              </div>
            </div>
            {
              item.id &&
              <div className='row m-t-5'>
                <label className='col-md-2 col-form-label'>{strings.support.video}</label>
                <div className="col-md-10 form-inline">
                  <ReactPlayer url={String(itemInfo.url).getFullUrl()}
                               controls
                               width='auto'
                               height='250px' />
                </div>
              </div>
            }
            <div className='row m-t-5'>
              <label className='col-md-2 col-form-label'>{strings.support.file_name}</label>
              <div className="col-md-10 form-inline">
                <input type="text" className='form-control col-md-10' id="title" value={itemInfo?.title || ''} required
                       autoComplete='off' onChange={onItemChange} />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-md-2 col-form-label'>{strings.support.company}</label>
              <div className="col-md-10 form-inline">
                <input type="text" className='form-control col-md-10' id="company" value={itemInfo?.company || ''}
                       required
                       autoComplete='off' onChange={onItemChange} />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-md-2 col-form-label'>{strings.support.code}</label>
              <div className="col-md-10 form-inline">
                <input type="text" className='form-control col-md-10' id="code" value={itemInfo?.code || ''} required
                       autoComplete='off' onChange={onItemChange} />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-md-2 col-form-label'>{strings.support.item_subject}</label>
              <div className="col-md-10 form-inline">
                <input type="text" className='form-control col-md-10' id="subject" value={itemInfo?.subject || ''}
                       required
                       autoComplete='off' onChange={onItemChange} />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-md-2 col-form-label'>{strings.support.item_speaker}</label>
              <div className="col-md-10 form-inline">
                <input type="text" className='form-control col-md-10' id="speaker" value={itemInfo?.speaker || ''}
                       required
                       autoComplete='off' onChange={onItemChange} />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-md-2 col-form-label'>{strings.support.item_grade}</label>
              <div className="col-md-10 form-inline">
                <select id='grade' className='form-control col-xl-8' value={itemInfo?.grade || ""}
                        onChange={onItemChange}>
                  <option value={""} />
                  {
                    C.GRADE.map((item, idx) =>
                      <option key={idx} value={item.code}>{item.name}</option>
                    )
                  }
                </select>
              </div>
            </div>
            {
              item.id &&
              <div className='row m-t-5'>
                <label className='col-md-2 col-form-label'>{strings.common.create_date}</label>
                <label className="col-md-10 col-form-label">
                  {item.create_date || ""}
                </label>
              </div>
            }
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
        <button className="btn btn-success" onClick={uploadFile}>{strings.modal.save}</button>
      </ModalFooter>
    </Modal>
  );
};

export default ItemDetailPopup;
