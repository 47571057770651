import strings from "../../../lang/strings";
import C, { GENDER, PERMISSION } from "../../../config/const";
import { useContext, useEffect, useState } from "react";
import { PageSettings } from "../../../config/page-settings";

const MemberDetailPopupMain = ({ user, onChange, onChangeProfile, onChangePermission }) => {
  const context = useContext(PageSettings);
  const [profile, setProfile] = useState({
    url: '',
    origin: '',
    file: null
  });
  const [permission, setPermission] = useState('');

  useEffect(() => {
    setProfile({
      url: user.profile?.getFullUrl().getThumbnailUrl(),
      origin: user.profile?.getFullUrl().getThumbnailUrl(),
      file: null
    });

    setPermission(user?.perm ?? '');
  }, [user]);

  const onImageChange = (e) => {
    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      setProfile(prev => {
        return {
          ...prev,
          url: reader.result,
          file: file
        }
      });
    };
    reader.readAsDataURL(file);
  };

  const onImageDelete = () => {
    setProfile(prev => {
      return {
        ...prev,
        url: prev.file != null ? prev.origin : '',
        origin: prev.file != null ? prev.origin : '',
        file: null,
      };
    });
  }

  return (
    <div className="row">
      <div className="col-xs-6">
        <div className='row'>
          <label className='col-xl-4 col-form-label'>UID</label>
          <label className='col-xl-8 col-form-label'>{user?.id}</label>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.id}</label>
          <div className="col-xl-8">
            <input type='text' autoComplete='off' className='form-control' id="id"
                   value={user?.email || ''}
                   onChange={onChange} readOnly />
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.name}</label>
          <div className="col-xl-8">
            <input type='text' autoComplete='off' className='form-control' id="name"
                   defaultValue={user?.name || ''} readOnly />
          </div>
        </div>
        <div className='row m-t-5'>
          <label className='col-xl-4 col-form-label'>{strings.member.profile}</label>
          <div className="col-xl-8">
            <a onClick={() => {
              context.showGallery([user.profile?.getFullUrl()])
            }}>
              <img src={user.profile?.getFullUrl()} style={{ height: '50px', width: 'auto', margin: 2 }} />
            </a>
          </div>
        </div>
        <div className='row m-t-5'>
          <label className='col-xl-4 col-form-label'>{strings.member.birthday}</label>
          <div className="col-xl-8">
            <input type='text' autoComplete='off' className='form-control' id="birthday"
                   defaultValue={user?.birthday || ''} readOnly />
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.common.gender}</label>
          <div className="col-xl-8">
            <select id='gender' className='form-control'
                    onChange={onChange}
                    value={user?.gender || GENDER.MALE}>
              <option value={GENDER.MALE}>{strings.common.male}</option>
              <option value={GENDER.FEMALE}>{strings.common.female}</option>
            </select>
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.user_type}</label>
          <div className='col-xl-8 form-inline'>
            <label className='control-label m-r-10'
                   onClick={() => onChange({ target: { id: "user_type", value: "STUDENT" } })}>
              <input className='' type='radio' name='student'
                     checked={user?.user_type == "STUDENT"}
                     readOnly
                     value={user?.user_type} />&nbsp;{strings.member.student}
            </label>
            <label className='control-label'
                   onClick={() => onChange({ target: { id: "user_type", value: "EXPERT" } })}>
              <input className='' type='radio' name='expert'
                     checked={user?.user_type == "EXPERT"}
                     readOnly
                     value={user?.user_type} />&nbsp;{strings.member.expert}
            </label>
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.school}</label>
          <div className="col-xl-8">
            <input type='text' autoComplete='off' className='form-control' id="school"
                   value={user?.school || ''} required
                   onChange={onChange} />
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.hope_school}</label>
          <div className="col-xl-8">
            <input type='text' autoComplete='off' className='form-control' id="hope_school"
                   value={user?.hope_school || ''} required
                   onChange={onChange} />
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.hope_department}</label>
          <div className="col-xl-8">
            <input type='text' autoComplete='off' className='form-control' id="hope_department"
                   value={user?.hope_department || ''} required
                   onChange={onChange} />
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.hope_career}</label>
          <div className="col-xl-8">
            <input type='text' autoComplete='off' className='form-control' id="hope_career"
                   value={user?.hope_career || ''} required
                   onChange={onChange} />
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.goal}</label>
          <div className="col-xl-8">
            <input type='text' autoComplete='off' className='form-control' id="goal"
                   value={user?.goal || ''} required
                   onChange={onChange} />
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.point}</label>
          <div className="col-xl-8">
            <input type='text' autoComplete='off' className='form-control' id="point"
                   value={user?.point || ''} required
                   onChange={onChange} />
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.score}</label>
          <div className="col-xl-8">
            <input type='text' autoComplete='off' className='form-control' id="point"
                   value={user?.score || ''} required
                   onChange={onChange} />
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.video}</label>
          <div className='col-xl-8 form-inline'>
            <label className='control-label m-r-10'
                   onClick={() => onChange({ target: { id: "video", value: "ON" } })}>
              <input className='' type='radio' name='status'
                     checked={user.video == "ON"}
                     readOnly
                     value={user.video} />&nbsp;{strings.common.on}
            </label>
            <label className='control-label'
                   onClick={() => onChange({ target: { id: "video", value: "OFF" } })}>
              <input className='' type='radio' name='video'
                     checked={user.video == "OFF"}
                     readOnly
                     value={user.video} />&nbsp;{strings.common.off}
            </label>
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.speaker}</label>
          <div className='col-xl-8 form-inline'>
            <label className='control-label m-r-10'
                   onClick={() => onChange({ target: { id: "speaker", value: "ON" } })}>
              <input className='' type='radio' name='speaker'
                     checked={user.speaker == "ON"}
                     readOnly
                     value={user.speaker} />&nbsp;{strings.common.on}
            </label>
            <label className='control-label'
                   onClick={() => onChange({ target: { id: "speaker", value: "OFF" } })}>
              <input className='' type='radio' name='status'
                     checked={user.speaker == "OFF"}
                     readOnly
                     value={user.speaker} />&nbsp;{strings.common.off}
            </label>
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.mirror}</label>
          <div className='col-xl-8 form-inline'>
            <label className='control-label m-r-10'
                   onClick={() => onChange({ target: { id: "mirror", value: "ON" } })}>
              <input className='' type='radio' name='mirror'
                     checked={user.mirror == "ON"}
                     readOnly
                     value={user.mirror} />&nbsp;{strings.common.on}
            </label>
            <label className='control-label'
                   onClick={() => onChange({ target: { id: "mirror", value: "OFF" } })}>
              <input className='' type='radio' name='status'
                     checked={user.mirror == "OFF"}
                     readOnly
                     value={user.mirror} />&nbsp;{strings.common.off}
            </label>
          </div>
        </div>
      </div>
      <div className="col-xs-6">
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.status}</label>
          <div className="col-xl-8">
            <select id='status' className='form-control'
                    onChange={onChange}
                    value={user?.status || C.USER_STATUS.NORMAL}>
              <option value={C.USER_STATUS.NORMAL}>{strings.member.normal}</option>
              <option value={C.USER_STATUS.BLOCK}>{strings.member.block}</option>
            </select>
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.status_date}</label>
          <label className='col-xl-8 col-form-label'>{user?.status_date || ''}</label>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.title}</label>
          <div className="col-xl-8">
            <input type='text' autoComplete='off' className='form-control' id="title"
                   value={user?.title || ''}
                   onChange={onChange} />
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.department}</label>
          <div className="col-xl-8">
            <input type='text' autoComplete='off' className='form-control' id="department"
                   value={user?.department || ''}
                   onChange={onChange} />
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.tendency}</label>
          <div className="col-xl-8">
            <input type='text' autoComplete='off' className='form-control' id="tendency"
                   value={user?.tendency || ''}
                   onChange={onChange} />
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.introduction}</label>
          <div className="col-xl-8">
            <input type='text' autoComplete='off' className='form-control' id="intro"
                   value={user?.intro || ''}
                   onChange={onChange} />
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.time}</label>
          <div className="col-xl-8">
            <input type='text' autoComplete='off' className='form-control' id="time"
                   value={user?.time || ''}
                   onChange={onChange} />
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.expert_english_name}</label>
          <div className="col-xl-8">
            <input type='text' autoComplete='off' className='form-control' id="english_name"
                   value={user?.english_name || ''}
                   onChange={onChange} />
          </div>
        </div>
        <div className='row m-t-5'>
          <label className='col-xl-4 col-form-label'>{strings.member.expert_resume}</label>
          <div className="col-xl-8">
            <textarea autoComplete='off' className='form-control' id="resume"
                      value={user?.resume || ''} rows={3}
                      onChange={onChange} />
          </div>
        </div>
        <div className='row m-t-5'>
          <label className='col-xl-4 col-form-label'>{strings.member.expert_career}</label>
          <div className="col-xl-8">
            <textarea autoComplete='off' className='form-control' id="career"
                      value={user?.career || ''} rows={3}
                      onChange={onChange} />
          </div>
        </div>
        <div className='row m-t-5'>
          <label className='col-xl-4 col-form-label'>{strings.member.expert_curriculum}</label>
          <div className="col-xl-8">
            <textarea autoComplete='off' className='form-control' id="curriculum"
                      value={user?.curriculum || ''} rows={3}
                      onChange={onChange} />
          </div>
        </div>
        <div className='row m-t-5'>
          <label className='col-xl-4 col-form-label'>{strings.member.expert_refund_policy}</label>
          <div className="col-xl-8">
            <textarea autoComplete='off' className='form-control' id="refund_policy"
                      value={user?.refund_policy || ''} rows={3}
                      onChange={onChange} />
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{'권한설정'}</label>
          <div className="col-xl-8 form-inline">
            <label className='control-label m-r-10'
                   onClick={() => onChangePermission(PERMISSION.STUDY)}>
              <input className='' type='checkbox' name='perm'
                     checked={permission.split(',').indexOf(PERMISSION.STUDY) > -1}
                     readOnly
                     value={'study'} />&nbsp;{'스터디카페'}
            </label>
            <label className='control-label m-r-10'
                   onClick={() => onChangePermission(PERMISSION.TUTOR)}>
              <input className='' type='checkbox' name='perm'
                     checked={permission.split(',').indexOf(PERMISSION.TUTOR) > -1}
                     readOnly
                     value={'aitutor'} />&nbsp;{'AI 튜터'}
            </label>
            {
              user?.user_type == "STUDENT" &&
              <label className='control-label m-r-10'
                   onClick={() => onChangePermission(PERMISSION.PLANNER)}>
                <input className='' type='checkbox' name='perm'
                      checked={permission.split(',').indexOf(PERMISSION.PLANNER) > -1}
                      readOnly
                      value={'planner'} />&nbsp;{'플래너'}
              </label>
            }
            {
              user?.user_type == "EXPERT" &&
              <label className='control-label m-r-10'
                   onClick={() => onChangePermission(PERMISSION.PAYMENT)}>
                <input className='' type='checkbox' name='perm'
                      checked={permission.split(',').indexOf(PERMISSION.PAYMENT) > -1}
                      readOnly
                      value={'payment'} />&nbsp;{'결제'}
              </label>
            }
            <label className='control-label m-r-10'
                   onClick={() => onChangePermission(PERMISSION.DATALAB)}>
              <input className='' type='checkbox' name='perm'
                     checked={permission.split(',').indexOf(PERMISSION.DATALAB) > -1}
                     readOnly
                     value={'datalab'} />&nbsp;{'데이터랩'}
            </label>
            <label className='control-label'
                   onClick={() => onChangePermission(PERMISSION.CLOUD)}>
              <input className='' type='checkbox' name='perm'
                     checked={permission.split(',').indexOf(PERMISSION.CLOUD) > -1}
                     readOnly
                     value={'cloud'} />&nbsp;{'클라우드'}
            </label>
          </div>
        </div>
        <div className='row'>
          <label className='col-xl-4 col-form-label'>{strings.member.signup_date}</label>
          <label className='col-xl-8 col-form-label'>{user?.signup_date || ''}</label>
        </div>
      </div>
    </div>
  );
};

export default MemberDetailPopupMain;
