import { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import C from "../../../config/const";
import ImageSelect from "../../../components/control/image-select";

const ProductDetailPopup = ({ isOpen, item, onClose, callback }) => {

  const context = useContext(PageSettings);

  const [image, setImage] = useState([]);
  const [brandImage, setBrandImage] = useState([]);

  let fileName = "";
  let imageUrl = null;
  let brandImageUrl = null;

  const [itemInfo, setItemInfo] = useState(item);

  useEffect(() => {
    setItemInfo(item)

    setImage([
      {
        url: item.image?.getFullUrl(),
        origin: item.image?.getFullUrl(),
        file: null
      }
    ]);
    setBrandImage([
      {
        url: item.brand_image?.getFullUrl().getThumbnailUrl(),
        origin: item.brand_image?.getFullUrl().getThumbnailUrl(),
        file: null
      }
    ]);
  }, [item]);

  const onItemChange = e => {
    setItemInfo(prev => {
      return {
        ...prev,
        [e.target.id]: e.target.value
      }
    })
  }

  const toggleModal = result => {
    onClose?.(result);
  }

  const uploadFile = () => {
    if (!item.id) {
      if (!image.some(it => it.file != null)) {
        return;
      }
      if (!brandImage.some(it => it.file != null)) {
        return;
      }
    }

    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      let formData = new FormData();

      image.filter(it => it.file != null).map(it => it.file).forEach(file => {
        formData.append('file', file, file.name);
      });

      let formData2 = new FormData();

      brandImage.filter(it => it.file != null).map(it => it.file).forEach(file => {
        formData2.append('file', file, file.name);
      });

      if (image.some(it => it.file != null)) {
        context.post(
          'upload/images/product',
          formData,
          function (response) {
            imageUrl = response.image

            if (!brandImage.some(it => it.file != null)) {
              onSave()
            } else {
              context.post(
                'upload/images/product',
                formData2,
                function (response) {
                  brandImageUrl = response.image
                  onSave()
                },
                true
              );
            }
          },
          true
        );
      } else {
        if (!brandImage.some(it => it.file != null)) {
          onSave()
        } else {
          context.post(
            'upload/images/product',
            formData2,
            function (response) {
              brandImageUrl = response.image
              onSave()
            },
            true
          );
        }
      }

    });
  }

  const onSave = () => {
    context.post(
      'shop/product/save',
      {
        ...itemInfo,
        image: imageUrl || item.image,
        brand_image: brandImageUrl || item.brand_image,
      },
      () => {
        context.addNotification(strings.modal.alert, strings.msg.saved);
        setImage([]);
        setBrandImage([]);
        fileName = "";
        callback()
      }
    );
  }

  const onImageChange = (e, idx, type) => {
    const files = e.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      if (type == 1) {
        setImage(prev => {
          return prev.map((it, index) => {
            if (index == idx) {
              it.url = reader.result;
              it.file = file;
            }
            return it;
          })
        });
      } else {
        setBrandImage(prev => {
          return prev.map((it, index) => {
            if (index == idx) {
              it.url = reader.result;
              it.file = file;
            }
            return it;
          })
        });
      }
    };
    reader.readAsDataURL(file);
  };

  const onImageDelete = (index, type) => {
    if (type == 1) {
      setImage(prev => {
        return prev.map((it, idx) => {
          if (idx == index) {
            if (it.file != null) {
              it.url = it.origin;
              it.file = null;
            } else {
              it.url = '';
              it.origin = '';
            }
          }
          return it;
        });
      });
    } else {
      setBrandImage(prev => {
        return prev.map((it, idx) => {
          if (idx == index) {
            if (it.file != null) {
              it.url = it.origin;
              it.file = null;
            } else {
              it.url = '';
              it.origin = '';
            }
          }
          return it;
        });
      });
    }

  }

  const onSubmit = e => {
    e.preventDefault();

    uploadFile()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} size={'xl'}>
      <form onSubmit={onSubmit}>
        <ModalHeader className="popup-title" toggle={toggleModal}>
          {item ? strings.shop.product_detail : strings.shop.add_product}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className='row m-t-5'>
                <label className='col-md-2 col-form-label'>{strings.shop.title}</label>
                <div className="col-md-10 form-inline">
                  <input type="text" className='form-control col-md-10' id="title" value={itemInfo?.title || ''}
                         required
                         autoComplete='off' onChange={onItemChange} />
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-md-2 col-form-label'>{strings.shop.category}</label>
                <div className="col-md-10 form-inline">
                  <select id='category' className='form-control col-xl-8' value={itemInfo?.category || ""} required
                          onChange={onItemChange}>
                    <option value={''} />
                    {
                      C.PRODUCT_TYPE.map((item, idx) =>
                        <option key={idx} value={item.code}>{item.name}</option>
                      )
                    }
                  </select>
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-md-2 col-form-label'>{strings.shop.brand}</label>
                <div className="col-md-10 form-inline">
                  <input type="text" className='form-control col-md-10' id="brand" value={itemInfo?.brand || ''}
                         required
                         autoComplete='off' onChange={onItemChange} />
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-md-2 col-form-label'>{strings.shop.image}</label>
                <div className="col-md-10">
                  <div className='row'>
                    {image.map((it, index) => (
                      <div className='col-xs-2 col-sm-2 col-md-2 col-xl-2 col-xxl-2' key={index}>
                        <ImageSelect
                          height='80%'
                          image_url={it.url}
                          onSelect={e => onImageChange(e, index, 1)}
                          onDelete={() => onImageDelete(index, 1)} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-md-2 col-form-label'>{strings.shop.brand_image}</label>
                <div className="col-md-10">
                  <div className='row'>
                    {brandImage.map((it, index) => (
                      <div className='col-xs-2 col-sm-2 col-md-2 col-xl-2 col-xxl-2' key={index}>
                        <ImageSelect
                          height='80%'
                          image_url={it.url}
                          onSelect={e => onImageChange(e, index, 2)}
                          onDelete={() => onImageDelete(index, 2)} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-md-2 col-form-label'>{strings.shop.point}</label>
                <div className="col-md-10 form-inline">
                  <input type="text" className='form-control col-md-10' id="point" value={itemInfo?.point || ''}
                         required
                         autoComplete='off' onChange={onItemChange} />
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-md-2 col-form-label'>{strings.shop.content}</label>
                <div className="col-md-10 form-inline">
                <textarea className='form-control col-md-10' id="content" value={itemInfo?.content || ''}
                          required autoComplete='off' rows={8} onChange={onItemChange} />
                </div>
              </div>
              <div className='row m-t-5'>
                <label className='col-md-2 col-form-label'>{strings.shop.status}</label>
                <div className="col-md-10 form-inline">
                  <select id='status' className='form-control col-xl-8' value={itemInfo?.status || 0} required
                          onChange={onItemChange}>
                    <option value={''} />
                    <option value={0}>{strings.shop.not_sell}</option>
                    <option value={1}>{strings.shop.sell}</option>
                  </select>
                </div>
              </div>
              {
                item.id &&
                <div className='row m-t-5'>
                  <label className='col-md-2 col-form-label'>{strings.common.create_date}</label>
                  <label className="col-md-10 col-form-label">
                    {item.create_date || ""}
                  </label>
                </div>
              }
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-default" type='button' onClick={toggleModal}>{strings.modal.close}</button>
          <button className="btn btn-success" type='submit'>{strings.modal.save}</button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ProductDetailPopup;
