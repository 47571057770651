import { useContext, useRef } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import $ from 'jquery'

import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import C from "../../../config/const";

const AdCreatePopup = ({ isOpen, onClose, callback }) => {

  const context = useContext(PageSettings);
  const fileRef = useRef();
  let files = [];
  let url = "";
  let fileName = "";

  const toggleModal = result => {
    onClose?.(result);
  }

  const uploadFile = () => {
    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      if (files.length == 0) {
        return;
      }

      let formData = new FormData();

      files.forEach((entry) => {
        formData.append("file", entry, entry.name)
      })

      fileName = files.map(item => item.name).join("#")
      context.post(
        files.length > 1 ? 'upload/files/ad' : 'upload/file/ad',
        formData,
        function (response) {
          url = files.length > 1 ? response.file.join("#") : response.file
          onSave();
        },
        true
      );
    });
  }

  const onSave = () => {
    context.post(
      'shop/ad/save',
      {
        url
      },
      () => {
        context.addNotification(strings.modal.alert, strings.msg.saved);
        files = [];
        url = "";
        callback()
      }
    );

  }

  const onFileChange = (e) => {

    if (e.target.files.length < 1) {
      return;
    }

    for (let entry in e.target.files) {
      if (typeof e.target.files[entry] == "object") {
        files.push(e.target.files[entry])
      }
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} size={'xs'}>
      <ModalHeader className="popup-title" toggle={toggleModal}>
        {strings.support.add_material}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-xs-12">
            <div className='row m-t-15 m-b-15'>
              <label className='col-xl-2 col-form-label'>{strings.shop.image}</label>
              <div className="col-xl-8 form-inline">
                <input type={"file"} className='hidden' ref={fileRef} id={"file"}
                       multiple accept={"image/*"} onChange={onFileChange} />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
        <button className="btn btn-success" onClick={uploadFile}>{strings.modal.save}</button>
      </ModalFooter>
    </Modal>
  );
};

export default AdCreatePopup;
