import { useContext, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import classnames from "classnames";

import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import MemberDetailPopupMain from "./detail-popup-main";
import MemberDetailPopupMission from "./detail-popup-mission";
import MemberDetailPopupPlanner from "./detail-popup-planner";
import MemberDetailPopupTag from "./detail-popup-tag";
import MemberDetailPopupTodo from "./detail-popup-todo";
import MemberDetailPopupPurchase from "./detail-popup-purchase";

const MemberDetailPopup = ({ isOpen, id, onClose, callback }) => {

  const context = useContext(PageSettings);

  const [activeTab, setActiveTab] = useState(0);
  const [user, setUser] = useState({});             // 사용자정보(승인된 정보)

  const getUserInfo = () => {
    context.get(
      '/member/detail/' + id,
      {},
      response => {
        setUser(response.user);   // 회원정보(t_member 테이블의 정보)
        setActiveTab(0);
      }
    );
  };

  const toggleModal = result => {
    onClose?.(result);
  }

  const toggleTab = tab => {
    setActiveTab(tab);
  }

  const onSave = () => {
    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'member/save',
        {
          user
        },
        () => {
          context.addNotification(strings.modal.alert, strings.msg.saved);
          callback?.();
        }
      );
    });
  }

  const onUserChange = e => {
    setUser(prev => {
      return {
        ...prev,
        [e.target.id]: e.target.value
      }
    });
  }

  const setProfile = profile => {
    setUser(prev => {
      return {
        ...prev,
        profile: profile
      }
    });
  }

  const onChangePermission = perm => {
    const userPerm = user.perm ?? '';
    let newPerm = '';
    if (userPerm.split(',').indexOf(perm) > -1) {
      const filteredPerms = userPerm.split(',').filter(item => item !== perm);
      newPerm = filteredPerms.join(',');
    } else {
      if (userPerm == '') {
        newPerm = perm;
      } else {
        const newPerms = userPerm.split(',');
        newPerms.push(perm);
        newPerm = newPerms.join(',');
      }
    }

    setUser(prev => {
      return {
        ...prev,
        perm: newPerm
      }
    });
  }

  const tabs = [{
    title: strings.member.main_info,
    component: () => <MemberDetailPopupMain user={user} onChange={onUserChange} onChangeProfile={setProfile} onChangePermission={onChangePermission} />
  }, {
    title: strings.member.mission_info,
    component: () => <MemberDetailPopupMission user={user} />
  }, {
    title: strings.member.planner_info,
    component: () => <MemberDetailPopupPlanner user={user} onClose={toggleModal} />
  }, {
    title: strings.member.tag_info,
    component: () => <MemberDetailPopupTag user={user} onClose={toggleModal} />
  }, {
    title: strings.member.todo_info,
    component: () => <MemberDetailPopupTodo user={user} onClose={toggleModal} />
  }, {
    title: strings.member.purchase_log,
    component: () => <MemberDetailPopupPurchase user={user} onClose={toggleModal} />
  }
  ];

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} onOpened={() => getUserInfo()} size={'xl'}>
      <ModalHeader className="popup-title" toggle={toggleModal}>
        {strings.member.detail} ({user?.name}, {user?.gender?.genderStr()}, {user?.birthday}, {user?.school})
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-xl-2">
            {tabs.map((menu, idx) => (
              <button
                key={`menu-${idx}`} type="button" onClick={() => toggleTab(idx)}
                className={classnames(
                  'width-full', 'text-left', 'btn', 'btn-white', 'btn-xs', 'mt-1',
                  { active: activeTab == idx }
                )}>
                {menu.title}
              </button>
            ))}
          </div>
          <div className="col-xl-10">
            {tabs.map((menu, idx) => (
              activeTab == idx &&
              <div key={`frg-${idx}`}
                   style={{ maxHeight: 'calc(100vh - 220px)', overflowX: 'hidden', overflowY: 'auto' }}>
                {menu.component()}
              </div>
            ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
        <button className="btn btn-success" onClick={onSave}>{strings.modal.apply}</button>
      </ModalFooter>
    </Modal>
  );
};

export default MemberDetailPopup;
