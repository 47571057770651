module.exports = {
  isLocalhost: true,
  API_URL: 'https://api.chewingplus.com', // this.isLocalhost ? 'http://192.168.0.54:3005' : 'https://api.chewingplus.com',

  HEADER: {
    AUTH_TOKEN: 'x-access-token',
    CONTENT_TYPE: {
      NAME: 'Content-Type',
      JSON: 'application/json',
      FILE: 'multipart/form-data',
      FORM: 'application/x-www-form-urlencoded'
    },
    LANG: 'Accept-Language',
  },

  colorList: [
    "#C92121",
    "#DF4949",
    "#F97E70",
    "#FFAF64",
    "#F0CF58",
    "#EDC63A",
    "#5B9559",
    "#93BC40",
    "#603D22",
    "#8A6D4E",
    "#C79127",
    "#CE612D",
    "#E68621",
    "#E39280",
    "#494556",
    "#696479",
    "#7B8891",
    "#9A918C",
    "#C1A886",
    "#4D2035",
    "#9F5C60",
    "#AA2E41",
    "#D54855",
    "#CF6B75",
    "#B61370",
    "#DD3093",
    "#E165B9",
    "#E06390",
    "#E58AAC",
    "#E9AAC4",
    "#3A21FA",
    "#5037FB",
    "#5A139F",
    "#6653A9",
    "#9F5EF0",
    "#7075F2",
    "#628046",
    "#264C53",
    "#406369",
    "#4D878D",
    "#4F9E9E",
    "#7ABBC1",
    "#262A4E",
    "#202C8B",
    "#314E9E",
    "#366DFC",
    "#519EE6",
    "#79BBF9"
  ],

  //. 각종 시간/일자 포맷
  FORMAT: {
    DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
    DATE: 'YYYY-MM-DD',
    TIME: 'HH:mm:ss',
    MONTH: 'YYYY-MM',
    YEAR: 'YYYY'
  },

  //. 응답코드
  RESULT_CODE: {
    SUCCESS: 0,                     //. 정상
    EMPTY_TOKEN: -1,                //. 토큰정보 없음.
    TOKEN_EXPIRED: -2,              //. 토큰 기한 만료
    EMPTY_PARAM: -3,                //. 요청파라미터 누락
    ERROR: -10,                     //. api 내부 오류
  },

  //. 사용자 상태코드
  USER_STATUS: {
    LEAVE: -2,                      //. 탈퇴
    BLOCK: -1,                      //. 영구정지
    NORMAL: 1,                      //. 정상
  },

  //. 방 종류
  ROOM_TYPE: {
    STUDY: 1,                           //. 자체학습방
    CHALLENGE: 2,                       //. 도전방
  },

  //. 성별
  GENDER: {
    MALE: 'M',
    FEMALE: 'F'
  },

  //. 학습방 카테고리
  ROOM_CATEGORY_LIST: [
    { code: 1, name: "수능" },
    { code: 2, name: "공무원" },
    { code: 3, name: "임용" },
    { code: 4, name: "자격증" },
    { code: 5, name: "어학" },
    { code: 6, name: "취업" },
    { code: 7, name: "학교" },
    { code: 8, name: "기타" }
  ],

  //. MATERIAL 카테고리
  MATERIAL_TYPE: [
    { code: 1, name: "문서" },
    { code: 2, name: "저장된 영상" },
    { code: 3, name: "틀린 문항" },
    { code: 4, name: "오답노트" },
  ],

  //. Product 카테고리
  PRODUCT_TYPE: [
    { code: "GIFTICON", name: "기프티콘" },
    { code: "GIFTCARD", name: "상품권" },
    { code: "MUSIC", name: "뮤직" },
    { code: "OTT", name: "OTT 멤버쉽" },
  ],

  //. cloud 카테고리
  CLOUD_TYPE: [
    { code: 'bmp', name: "bmp" },
    { code: 'doc', name: "doc" },
    { code: 'docx', name: "docx" },
    { code: 'htm', name: "htm" },
    { code: 'html', name: "html" },
    { code: 'jpg', name: "jpg" },
    { code: 'jpeg', name: "jpeg" },
    { code: 'pdf', name: "pdf" },
    { code: 'png', name: "png" },
    { code: 'ppt', name: "ppt" },
    { code: 'pptx', name: "pptx" },
    { code: 'tiff', name: "tiff" },
    { code: 'txt', name: "txt" },
    { code: 'xls', name: "xls" },
    { code: 'xlsx', name: "xlsx" },
  ],

  //. grade
  GRADE: [
    { code: 'g1', name: "고1" },
    { code: 'g2', name: "고2" },
    { code: 'g3 / ns', name: "고3 / N수" },
  ],

  //. 상태코드
  STATUS: {
    PENDING: 0,         //. 대기
    NORMAL: 1,          //. 정상
    REJECT: 2,          //. 반려
    DELETED: -1,        //. 삭제
    FINISH: 2,          //. 완료
    CANCEL: 2,          //. 취소
    REAPPLY: 3,         //. 재심요청
    YES: 'Y',
    NO: 'N'
  },

  PERMISSION: {
    STUDY: 'STUDY',      // 스터디카페
    TUTOR: 'TUTOR',      // AI 튜터
    PLANNER: 'PLANNER',  // 플래너
    PAYMENT: 'PAYMENT',  // 결제
    DATALAB: 'DATALAB',  // 데이터랩
    CLOUD: 'CLOUD',      // 클라우드
  },
}
