import { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import strings from '../../../lang/strings';
import Container from "../../../components/control/container";

const PlannerStaFragment = () => {

  const context = useContext(PageSettings);

  const [data, setData] = useState(null);
  useEffect(() => {
    context.get(
      'dashboard/planner_sta',
      {},
      response => setData(response.planner_sta)
    );
  }, []);

  return (
    <Container title={strings.dashboard.planner_success_ratio}>
      <div>
        <div className="d-flex align-items-center">
          <div className="d-flex w-100">
            <div>{strings.dashboard.today} {strings.dashboard.planner_success_ratio}</div>
            <div className="ml-auto">
              {data?.today_count > 0 ? `${(data?.today_complete_count / data?.today_count).toFixed(2)}` : `-`}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex w-100">
            <div>{strings.dashboard.this_week} {strings.dashboard.planner_success_ratio}</div>
            <div className="ml-auto">
              {data?.this_week_count > 0 ? `${(data?.this_week_complete_count / data?.this_week_count).toFixed(2)}` : `-`}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex w-100">
            <div>{strings.dashboard.this_month} {strings.dashboard.planner_success_ratio}</div>
            <div className="ml-auto">
              {data?.this_month_count > 0 ? `${(data?.this_month_complete_count / data?.this_month_count).toFixed(2)}` : `-`}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex w-100">
            <div>{strings.dashboard.this_year} {strings.dashboard.planner_success_ratio}</div>
            <div className="ml-auto">
              {data?.this_year_count > 0 ? `${(data?.this_year_complete_count_ / data?.this_week_count).toFixed(2)}` : `-`}
            </div>
          </div>
        </div>
      </div>
    </Container>    
  );
}

export default PlannerStaFragment;
