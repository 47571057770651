import { useContext, useEffect, useState } from 'react';

import { PageSettings } from '../../../config/page-settings';
import strings from '../../../lang/strings';
import Container from "../../../components/control/container";

const ClinicStaFragment = () => {

  const context = useContext(PageSettings);

  const [data, setData] = useState(null);
  useEffect(() => {
    context.get(
      'dashboard/clinic_sta',
      {},
      response => setData(response.clinic_sta)
    );
  }, []);

  return (
    <Container title={strings.dashboard.expert_student_matching_ratio}>
      <div>
        <div className="d-flex align-items-center ">
          <div className="d-flex w-100">
            <div>{strings.dashboard.ten_group}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.ten_count?.currencyFormat()}명`
                  : `-`
              }
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center ">
          <div className="d-flex w-100">
            <div>{strings.dashboard.twenty_group}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.twenty_count?.currencyFormat()}명`
                  : `-`
              }
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center ">
          <div className="d-flex w-100">
            <div>{strings.dashboard.thirty_group}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.thirty_count?.currencyFormat()}명`
                  : `-`
              }
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center ">
          <div className="d-flex w-100">
            <div>{strings.dashboard.forty_group}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.forty_count?.currencyFormat()}명`
                  : `-`
              }
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center ">
          <div className="d-flex w-100">
            <div>{strings.dashboard.fifty_group}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.fifty_count?.currencyFormat()}명`
                  : `-`
              }
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center ">
          <div className="d-flex w-100">
            <div>{strings.dashboard.sixty_group}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.sixty_count?.currencyFormat()}명`
                  : `-`
              }
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center ">
          <div className="d-flex w-100">
            <div>{strings.dashboard.seventy_group}</div>
            <div className="ml-auto">
              {
                data ?
                  `${data.seventy_count?.currencyFormat()}명`
                  : `-`
              }
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default ClinicStaFragment;
