import { useContext, useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { PageSettings } from "../../../config/page-settings";
import strings from "../../../lang/strings";
import C from "../../../config/const";

const MaterialDetailPopup = ({ isOpen, material, onClose, callback }) => {

  const context = useContext(PageSettings);
  const fileRef = useRef();
  const [files, setFiles] = useState([]);
  let fileName = "";
  let url = material.url;

  const [materialInfo, setMaterialInfo] = useState(material);

  useEffect(() => {
    setMaterialInfo(material)
    url = material.url
  }, [material]);

  const onMaterialChange = e => {
    setMaterialInfo(prev => {
      return {
        ...prev,
        [e.target.id]: e.target.value
      }
    })
  }

  const toggleModal = result => {
    onClose?.(result);
  }

  const uploadFile = () => {
    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      if (files.length == 0) {
        onSave()
        return;
      }

      let formData = new FormData();

      files.forEach((entry) => {
        formData.append("file", entry, entry.name)
      })

      context.post(
        'upload/file/material',
        formData,
        function (response) {
          url = response.file
          onSave();
        },
        true
      );
    });
  }

  const onSave = () => {
    context.post(
      'support/material/save',
      {
        ...materialInfo,
        url
      },
      () => {
        context.addNotification(strings.modal.alert, strings.msg.saved);
        setFiles([]);
        fileName = "";
        callback()
      }
    );
  }

  const onFileChange = (e) => {
    if (e.target.files.length < 1) {
      return;
    }

    let fileList = []
    for (let entry in e.target.files) {
      if (typeof e.target.files[entry] == "object") {
        fileList.push(e.target.files[entry])
      }
    }

    setFiles(fileList)
    fileName = fileList.map(item => item.name).join("#")
    onMaterialChange({ target: { id: "name", value: fileName } })
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} size={'xs'}>
      <ModalHeader className="popup-title" toggle={toggleModal}>
        {strings.support.material_detail}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-xs-12">
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.support.file}</label>
              <div className="col-xl-8 form-inline">
                <input type={"file"} className='hidden' ref={fileRef} id={"file"}
                       accept={"*/*"} onChange={onFileChange} />
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.support.file_name}</label>
              <div className="col-xl-8 form-inline">
                <input type="text" className='form-control' id="name" value={materialInfo?.name || ''} required
                       autoComplete='off' onChange={onMaterialChange} />
                <a href={String(materialInfo?.url).getFullUrl()}>
                  <button type='button' className='btn btn-green btn-sm m-2'>{strings.common.view}</button>
                </a>
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.support.material_type}</label>
              <div className="col-xl-8 form-inline">
                <select id='type' className='form-control col-xl-8' value={materialInfo?.type || ""}
                        onChange={onMaterialChange}>
                  {
                    C.MATERIAL_TYPE.map((item, idx) =>
                      <option key={idx} value={item.code}>{item.name}</option>
                    )
                  }
                </select>
              </div>
            </div>
            <div className='row m-t-5'>
              <label className='col-xl-4 col-form-label'>{strings.common.create_date}</label>
              <label className="col-xl-8 col-form-label">
                {material.create_date || ""}
              </label>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-default" onClick={toggleModal}>{strings.modal.close}</button>
        <button className="btn btn-success" onClick={uploadFile}>{strings.modal.save}</button>
      </ModalFooter>
    </Modal>
  );
};

export default MaterialDetailPopup;
