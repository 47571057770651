import { useContext, useEffect, useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import { Panel, PanelBody, PanelFooter } from "../../components/panel/panel";
import classnames from "classnames";
import PlannerDetailInfo from "./fragment/planner-detail-info";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import PlannerTagDetail from "./fragment/planner-tag-detail";
import TagCreatePopup from "./popup/tag-create-popup";

const PlannerDetail = ({ planner_id, history }) => {

  const context = useContext(PageSettings);

  const [activeTab, setActiveTab] = useState('1');

  const [tagList, setTagList] = useState([]);

  const [popup, setPopup] = useState({
    isOpen: false,
    planner_id: 0,
  });

  const showPopup = () => setPopup(prev => {
    return { ...prev, isOpen: true, planner_id };
  });

  const hidePopup = () => setPopup(prev => {
    return { ...prev, isOpen: false };
  });

  useEffect(() => {
    if (planner_id > 0) {
      getTagList()
    }
  }, [planner_id]);

  const getTagList = () => {
    context.get(
      '/planner/tag/list/' + planner_id,
      {},
      response => {
        setTagList(response.list);
      }
    );
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const onTagChange = e => {
    const { id, value, todo, todo_id } = e.target;
    if (todo == "Y") {
      let todo_list = tagList[Number(activeTab) - 1].todo_list
      let changingTodo = {}
      let changingTodoIndex = -1

      todo_list.forEach((it, idx) => {
        if (it.id == todo_id) {
          changingTodo = it
          changingTodoIndex = idx
        }
      })

      setTagList(prev => prev.map((it, idx) => {
        if (idx == Number(activeTab) - 1) {
          it.todo_list[changingTodoIndex][id] = value;
        }
        return it;
      }))

    } else {
      setTagList(prev => prev.map((it, idx) => {
        if (idx == Number(activeTab) - 1) {
          it[id] = value;
        }
        return it;
      }))
    }
  }

  const onSave = () => {
    context.showConfirm(strings.modal.save_confirm, res => {
      if (res !== true) {
        return;
      }

      context.post(
        'planner/tag/save',
        {
          tag: tagList[Number(activeTab) - 1]
        },
        () => {
          getTagList()
          context.addNotification(strings.modal.alert, strings.msg.saved);
        }
      );
    });
  }

  return (
    <div className="row">
      <div className="col-xl-6">
        <PlannerDetailInfo planner_id={planner_id} onSave={() => {
          if (planner_id < 1) {
            history.goBack();
          }
        }} />
      </div>
      {
        planner_id > 0 &&
        <div className="col-xl-6">
          <Panel>
            <Nav tabs className="nav-tabs-inverse">
              {
                tagList.filter(it => it.title != "").length > 0 ? tagList.filter(it => it.title != "").map((item, idx) =>
                    <NavItem key={idx}>
                      <NavLink className={classnames({ active: activeTab == idx + 1 })} onClick={() => {
                        toggle(idx + 1);
                      }}>
                        <span className="d-none d-xs-inline">{item.title}</span>
                      </NavLink>
                    </NavItem>
                  )
                  :
                  null
              }
            </Nav>
            <PanelBody>
              {
                tagList[Number(activeTab) - 1] &&
                <PlannerTagDetail tag_info={tagList[Number(activeTab) - 1]} onChange={onTagChange}
                                  getTagList={getTagList} />
              }
            </PanelBody>
            <PanelFooter className={"text-right"}>
              <div className="d-flex w-100">
                <button type='button' className='btn btn-yellow btn-sm m-2' onClick={() => {
                  showPopup()
                }}>{strings.planner.add_tag}</button>
                <div className="ml-auto">
                  {
                    tagList[Number(activeTab) - 1] &&
                    <button type='button' className='btn btn-success btn-sm m-2' onClick={() => {
                      onSave()
                    }}>{strings.common.save}</button>
                  }
                </div>
              </div>
            </PanelFooter>
          </Panel>
        </div>
      }
      <TagCreatePopup {...popup} onClose={() => {
        hidePopup();
      }} callback={() => {
        getTagList()
        hidePopup();
      }} />
    </div>
  );
};

export default withRouter(inject('rootStore')(PlannerDetail));
