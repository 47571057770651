import React, { useContext, useRef, useState } from "react";
import $ from "jquery";

import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import DataTable from "../../components/control/data-table";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import Container from "../../components/control/container";
import SearchSelect from "../../components/control/search-select";
import SearchInput from "../../components/control/search-input";
import { PRODUCT_TYPE } from "../../config/const";
import ProductDetailPopup from "./popup/product-detail-popup";
import AdCreatePopup from "./popup/ad-create-popup";

const ProductAdList = ({ history }) => {

  const context = useContext(PageSettings);
  const searchOptions = strings.search_option.product_ad;

  const [popup, setPopup] = useState({
    isOpen: false,
    item: {},
  });

  const showPopup = (item) => setPopup(prev => {
    return { ...prev, isOpen: true, item };
  });

  const hidePopup = () => setPopup(prev => {
    return { ...prev, isOpen: false };
  });

  let columnNo = 0;
  const tableRef = useRef();
  const refreshTable = refresh => {
    tableRef.current?.draw(refresh);
  }

  const onDelete = ad_id => {
    context.showConfirm(strings.modal.delete_confirm, res => {
      if (res !== true) {
        return;
      }

      context.delete(
        'shop/productAd/' + ad_id,
        {},
        () => {
          context.addNotification(strings.modal.alert, strings.msg.deleted);
          refreshTable(false);
        }
      );
    });
  };

  const onChange = (e, ad_id) => {
    context.post(
      'shop/adChange',
      {
        id: ad_id,
        status: e.target.value
      },
      () => {
        context.addNotification(strings.modal.alert, strings.msg.saved);
        refreshTable(false);
      }
    );
  };

  return (
    <Container title={strings.menu.product_ad_list}>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-horizontal form-inline'>
            <SearchSelect
              id='searchColumn'
              value={searchOptions[0].value}
              data={searchOptions}
              onChange={() => refreshTable(true)} />
            <SearchInput
              id='searchInput'
              action={() => refreshTable(true)} />
            <button type='button' className='btn btn-success btn-sm ml-md-2'
                    onClick={() => refreshTable(true)}>{strings.common.search}</button>
            <button type='button' className='btn btn-yellow btn-sm ml-md-2'
                    onClick={() => showPopup({})}>{strings.shop.add_product_ad}</button>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <DataTable
            ref={tableRef}
            options={{
              columnDefs: [
                {
                  targets: [columnNo++], data: 'id', orderable: false,
                },
                {
                  targets: [columnNo++], data: 'image_url', orderable: false,
                  createdCell: (td, value) => {
                    $(td).image_lightbox(value, () => {
                      context.showGallery(value.split('#').map(it => it.getFullUrl()));
                    });
                  }
                },
                {
                  targets: [columnNo++], data: 'status', orderable: false,
                  createdCell: function (td, value, item) {
                    $(td).html('');
                    $(td).append(`
                        <select class="form-control">
                          <option value='0'>${strings.shop.show}</option>  
                          <option value='-1'>${strings.shop.hide}</option>  
                        </select`)
                      .find('select')
                      .val(item.status)
                      .on('change', function (e) {
                        onChange(e, item.id)
                      });
                  }
                },
                {
                  targets: [columnNo++], data: 'create_date', orderable: false,
                },
                {
                  targets: [columnNo++], data: 'id', orderable: false,
                  createdCell: function (td, value, item) {
                    $(td).html('');
                    $(td).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.common.delete}</button`)
                      .find('button:last')
                      .on('click', function () {
                        onDelete(item.id)
                      });
                  }
                }
              ],
              order: [[1, 'DESC']],
              ajax: {
                url: 'shop/productAdList',
                data: function (req) {
                  req.column = $('#searchColumn').val();
                  req.keyword = $('#searchInput').val();
                }
              },
              drawCallback: function () {
              },
              fnCreatedRow: function (row, data, dataInd) {
              }
            }}>
            <thead>
            <tr>
              <th>#</th>
              <th>{strings.shop.image_url}</th>
              <th>{strings.shop.status}</th>
              <th>{strings.common.create_date}</th>
              <th>{strings.common.manage}</th>
            </tr>
            </thead>
            <tbody />
          </DataTable>
        </div>
      </div>
      <AdCreatePopup {...popup} onClose={() => {
        hidePopup();
      }} callback={() => {
        refreshTable(true)
        hidePopup();
      }} />
    </Container>
  );

}

export default withRouter(inject('rootStore')(ProductAdList));
